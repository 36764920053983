<template>
  <!-- https://bigpic.atlassian.net/jira/software/projects/COG/boards/47?selectedIssue=COG-1173 -->
  <component :is="bannerComponent" v-bind="bannerProps" @click="notifyGtag('open')" top-banner :style="{ backgroundColor: topBanner.backgroundColor }" v-if="!topBannerClosed" :class="{'hide-by-scroll': hide}" :data-banner-id="topBanner.bannerId || 'none'">
    <div class="top-banner-bg-mobile" :style="{backgroundImage: bgMobileUrl}"></div>
    <q class="top-banner-container" :style="{backgroundImage: bgUrl}">
      <p class="top-banner-text">
        <b class="title-head" :style="{ color: titleHeadColor }">{{ titleHead }}</b>
        <span class="title-sub" :style="{ color: titleSubColor}">{{ titleSub }}</span>
      </p>
      <button @click.prevent.stop="onClose" class="btn-top-banner-close" :style="{ textUnderlineColor: titleHeadColor }">
        <span :style="{ color: titleHeadColor }">오늘 하루 보지 않기</span>
        <!-- 기존에 등록된 svg-x-alt viewBox 좌표가 잘못되어 새로 등록함. 나중에 svg-x 함께 수정할 것 -->
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" :stroke="titleHeadColor">
          <path d="M11 1L1 11" />
          <path d="M1 1L11 11" />
        </svg>
      </button>
    </q>
  </component>
</template>

<script>
// cdnimg 참조
import { cdnSrc } from '@shared/utils/cdnUtils';
import { state } from '@shared/utils/storeUtils';
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  data: () => ({
    hide: false,
  }),
  components: { BasicButton },
  props: {
    topBanners: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    topBannerClosed: state('info', 'topBannerClosed'),
    topBanner() {
      // 배너 검증
      // type conversion을 위해 괄호로 감쌌음
      const now = new Date().getTime();
      const filteredBanners = (/** @type{CoachTopBanner[]} */ this.topBanners).filter(b => {
        if (b.period?.startDatetime && b.period?.startDatetime > now) return false;
        if (b.period?.endDatetime && b.period?.endDatetime < now) return false;
        if (!b.isActive) return false;
        return true;
      });
      return /** @type{CoachTopBanner} */ filteredBanners?.[0] ?? {};
    },
    titleHead() {
      return this.topBanner?.title?.head ?? '';
    },
    titleHeadColor() {
      return this.topBanner?.title?.headColor ?? '#191919';
    },
    titleSub() {
      return this.topBanner?.title?.sub ?? '';
    },
    titleSubColor() {
      return this.topBanner?.title?.subColor ?? '#191919';
    },
    bgColor() {
      return this.topBanner?.backgroundColor ?? '#fff';
    },
    imageUrl() {
      return this.topBanner?.image?.url ?? '';
    },
    bgUrl() {
      return this.imageUrl !== '' ? `url(${cdnSrc(this.imageUrl)})` : 'none';
    },
    imageMobileUrl() {
      return this.topBanner?.image?.mobileUrl ?? '';
    },
    bgMobileUrl() {
      return this.imageMobileUrl !== '' ? `url(${cdnSrc(this.imageMobileUrl)})` : 'none';
    },
    bannerComponent() {
      return this.topBanner?.link?.useLink ? 'a' : 'div';
    },
    bannerProps() {
      return this.topBanner?.link?.useLink ? { href: this.topBanner?.link?.url ?? '#', target: this.topBanner?.link?.openType === 'NEW_PAGE' ? '_blank' : undefined } : {};
    },
  },
  methods: {
    onClose() {
      this.notifyGtag('close');
      this.$services.info.closeTopBanner();
    },
    checkedScroll() {
      if (window.scrollY > 100 && window.scrollY > 0) {
        this.hide = this.lastScrollY - window.scrollY < 0;
        this.lastScrollY = window.scrollY;
      } else {
        this.hide = false;
      }
    },
    notifyGtag(type) {
      if (type === 'open') {
        this.$gtag.event('click_top_banner_to_open', {
          'event_category': 'top_banner',
          'event_label': 'click_top_banner_to_open',
          'value': `title: ${this.titleHead}, subtitle: ${this.titleSub}`,
        });
      } else if (type === 'close') {
        this.$gtag.event('click_top_banner_to_close', {
          'event_category': 'top_banner',
          'event_label': 'click_top_banner_to_close',
          'value': `title: ${this.titleHead}, subtitle: ${this.titleSub}`,
        });
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.checkedScroll.bind(this));
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkedScroll.bind(this));
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
// https://bigpic.atlassian.net/browse/COG-1014
[top-banner] {position: fixed;.t(0);.h(@banner-height-pc);.w(100%);.z(@z-top-banner);.noto;.tr-t(.3s);
  .bgc(#fff);
  .top-banner-bg-mobile {.hide;.abs;bottom: 0;top: 0;.responsive-w(375);left: 50%;transform: translateX(-50%);background-size: cover;background-position: center;background-repeat: no-repeat;}

  .top-banner-container { .responsive-w(1280);.h(100%);.flex;.flex-ai(center);.flex-jc(center);.m(auto);background-repeat: no-repeat;background-size: cover;background-position: center;.rel;}
  .title-head, .title-sub {text-align: center;.block}
  .title-head {.fs(20);font-weight: 700;}
  .title-sub {.fs(14);font-weight: 400;}
  .title-head + .title-sub {.mt(8);}

  .top-banner-text {.responsive-w(1024);.h(100%);.flex;.flex-jc(center);.flex-ai(center);flex-direction: column;}
  .btn-top-banner-close {.flex;.flex-ai(center);.flex-jc(center);.abs;.rt(0, 0);.h(24);.p(0, 7);.mt(12);
    span + svg {.ml(7)}
    svg { .wh(10)}
    span {.lh(17);.fs(12);}
    &:hover span {text-decoration: underline;text-decoration-thickness: 1px; text-underline-offset: 2px;}
  }

  @media (@ds-down) {
    .top-banner-container {.responsive-w(1024)}
    .top-banner-text {.w(908)}
  }
  @media (@ml-down) {
    // 모바일에서는
    // GNB 와 동일하게 스크롤시 숨겨지도록 동작
    &.hide-by-scroll {.t-y(-104%);}
    .top-banner-bg-mobile {.block;}
    .top-banner-container {background-image: none !important;}
    .h(@banner-height-mobile);
    .btn-top-banner-close {
      .t(50%);transform: translateY(-50%);.r(22);.mt(0); // PC 의 margin-top이 absolute vertical centering과 충돌하여 초기화
      .p(0);
      span + svg {.ml(0)}
      > span {.hide}
    }
    .title-head {.fs(14)}
    .title-sub {.fs(12)}
    .title-head + .title-sub {.mt(4)}
    .top-banner-container {.responsive-w(375)}
    .top-banner-text {.w(335)}
  }
}
</style>
