import Logger from '@shared/modules/Logger';
import { addDuration } from "shared/utils/timeUtils";
import timeConsole from '@shared/ssr/timeConsole';

export default class InfoService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;
  #log;

  /**
   * @param {ServiceManager} services
   */
  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
    this.#log = Logger('InfoService');
  }

  /**
   * 서버로부터 기본 정보를 가져옴
   * 국가목록 아직 사용하는 내용이 없어 일단 주석처리
   */
  async init() {
    try {
      const [
        games,
        topBanners,
      ] = await Promise.all([
        this.#api.get('/games'),
        this.#services.coaching.getTopBanner(),
      ]);
      this.#services.store.commit('info/setGames', games);

      const closedTime = this.#services.cookie.getTopBannerCloseTime();
      const closeTimeExpire = addDuration(closedTime, { hours: 24 });
      this.#services.store.commit('info/setTopBannerClosed', new Date().getTime() < closeTimeExpire);

      this.#services.store.commit('info/setTopBanners', topBanners);
    } catch (e) {
      timeConsole.log(e);
    }
  }

  closeTopBanner() {
    const now = new Date().getTime();
    this.#services.cookie.setTopBannerCloseTime(now);
    this.#services.store.commit('info/setTopBannerClosed', true);
  }
}
