// import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { loadTossPayments } from '@tosspayments/sdk';
import { PAYMENT_METHOD_CULTURE_GIFT_CARD, PAYMENT_METHOD_GAME_GIFT_CARD, PAYMENT_METHOD_BOOK_GIFT_CARD, PAYMENT_METHOD_TRANSFER } from '@/constants/payment';
import { CustomError } from '@shared/utils/commonUtils';

/** constants */
const TOSS_PAYMENTS_RESULT = 'tossPaymentsResult';
const TOSS_PAYMENTS_TRANSFER = '계좌이체';
const TOSS_PAYMENTS_CULTURE_GIFT_CARD = '문화상품권';
const TOSS_PAYMENTS_GAME_GIFT_CARD = '게임문화상품권';
const TOSS_PAYMENTS_BOOK_GIFT_CARD = '도서문화상품권';
/**
 * @typedef {import('vue-router').Router} VueRouter
 * @param {string} billingId
 * @param {number | null}price
 * @param {string} productName
 * @param {string} paymentMethod
 * @param {string} gameId
 * @param {VueRouter} router
 * @returns {Promise<void>}
 */
export async function tossPayments({ billingId = '', price = null, productName = '', paymentMethod = '', gameId = 'LOL' }) {
  if (!billingId || !price || !productName || !paymentMethod) throw Error('tossPayments param');
  const host = process.env.VUE_APP_HOME_URL;

  /** 추후 코칭 외에 다른 서비스에서도 toss payments 사용할 경우, redirection navigation guard 설정 후, 결제 경로 받도록 수정 필요 */
  const successQuery = { [TOSS_PAYMENTS_RESULT]: 'success', billingId, gameId };
  const failQuery = { [TOSS_PAYMENTS_RESULT]: 'fail' };

  const r = /** @type{VueRouter} */ this.app.$router;
  const successPath = r.resolve({ name: 'CoachHome', query: successQuery })?.href;
  const failPath = r.resolve({ name: 'CoachHome', query: failQuery })?.href;

  const successUrl = [host, successPath].join('');
  const failUrl = [host, failPath].join('');
  let tossPaymentMethod = '';

  switch (paymentMethod) {
    case PAYMENT_METHOD_CULTURE_GIFT_CARD:
      tossPaymentMethod = TOSS_PAYMENTS_CULTURE_GIFT_CARD;
      break;
    case PAYMENT_METHOD_GAME_GIFT_CARD:
      tossPaymentMethod = TOSS_PAYMENTS_GAME_GIFT_CARD;
      break;
    case PAYMENT_METHOD_BOOK_GIFT_CARD:
      tossPaymentMethod = TOSS_PAYMENTS_BOOK_GIFT_CARD;
      break;
    case PAYMENT_METHOD_TRANSFER:
      tossPaymentMethod = TOSS_PAYMENTS_TRANSFER;
      break;
    default:
      throw Error('tossPayments method');
  }

  let tossPaymentsAuthKey = '';
  if (process.env.NODE_ENV === 'development')
    tossPaymentsAuthKey = process.env.VUE_APP_TOSS_PAYMENTS_TEST_KEY;
  else
    tossPaymentsAuthKey = await this.app?.$services?.coaching.getTossPaymentsAuthKey();

  const _tossPayments = await loadTossPayments(tossPaymentsAuthKey);
  if (!_tossPayments) throw Error('tossPayments init');

  _tossPayments.requestPayment(tossPaymentMethod, { amount: price, orderId: billingId, orderName: productName, customerName: '', successUrl, failUrl });
}

/**
 *
 * @param to {VueRouter}
 * @returns {{ billingId: string, orderId: string, amount: string, paymentKey: string}}
 */
export function checkTossPaymentsResultRedirection(to) {
  const resultStatus = to?.query?.[TOSS_PAYMENTS_RESULT];
  // 토스 페이먼트와 무관한 경우
  if (!resultStatus) throw new CustomError('NO_TOSS', 'not a toss payment',);
  const targetKeys = ['billingId', 'orderId', 'amount', 'paymentKey'];
  if (resultStatus !== 'success') throw new Error(`toss payment result status: ${resultStatus}`);
  const prerequisite = targetKeys.every(queryKey => to?.query[queryKey]);
  if (!prerequisite) {
    throw new Error(`one of these queries are missing from Toss Redirection: ${targetKeys.toString()}`);
  }
  return _pick(to?.query, targetKeys);
}
