import Vue from 'vue';
import 'swiper/swiper-bundle.css';
import modal from '@shared/plugins/modal';
import toast from '@shared/plugins/toast';
import scrollAgency from '@shared/plugins/scrollAgency';
import pusher from '@shared/plugins/pusher';
import popover from '@shared/plugins/popover';
import browser from '@shared/modules/Browser';
import { sha256 } from '@shared/utils/hashUtils';
import { accessControl } from '@shared/utils/routerUtils';
import { payment } from '@/modules/Payment';
import gtag from '@/plugins/gtag';
import app from '@/app';
import ErrorHandler, { NoToast } from '@/modules/ErrorHandler';
// Polyfill for IntersectionObserver
import 'intersection-observer/intersection-observer';
import sentry from '@/plugins/sentry';
import accessContext from '@/router/accessContext';

export default app.getEntryClient({
  beforeCreate: async (Vue, store, router, services) => {
    Vue.config.productionTip = false;
    Vue.use(modal, 'coaching');
    Vue.use(toast);
    Vue.use(scrollAgency, { store });
    Vue.use(pusher);
    Vue.use(popover);
    Vue.use(sentry, { router });
    await store.dispatch('timeInit');
    await services.auth.init(window.location.href);
    const user_id = await sha256(store?.state?.auth?.userId);
    Vue.use(gtag, { router, user_id });
    await services.chat.init();
    await services.notification.init();
    router.beforeEach(accessControl(accessContext, store));
  },
  makeRouteErrorHandler: (services, router) => async e => {
    const { code } = e;
    if (code === 301 || code === 302) {
      if (/^https?:\/\//.test(e.to)) window.location.href = e.to;
      else await router.replace(e.to);
    }
    if (code === 404) await router.push('/404');
    if (code === 401 || code === 403) {
      services.toast.toast(['httpErrors', code], { type: 'fail' });
      if (code === 401) await services.auth.oAuthLogin(true, e.to);
      throw NoToast;
    }
    if (code === 307) {
      services.toast.toast(['httpErrors', code], { type: 'fail' });
      await router.replace('/');
    }
    if (code === 307) {
      services.toast.toast(['httpErrors', code], { type: 'fail' });
      await router.replace('/');
    }
  },
  afterCreate: (app, router, store) => {
    ErrorHandler(Vue, app, store);
    payment.setApp(app);
    browser.useScrollHistory({ router, store, scrollHandler: app.$scroll });
  },
  afterMount: (app, route, store) => {
    browser.sync(store);
  },
});
