<template>
  <span point-icon :class="{clickable, [shape]: true}" @click="$emit('click')">
    <img :src="iconPath" alt="lvup point icon" />
    <strong class="amount"> {{ num }} </strong> <small class="currency" v-show="showCurrency">UP</small>
  </span>
</template>

<script>
import { commaDecimal } from '@shared/utils/numberUtils';

export default {
  props: {
    shape: {
      type: String,
      default: () => 'normal',
      validator(shape) {
        return [
          'normal',
          'normal-small',
          'white',
          'white-no-left-padding',
          'purple-large',
          'purple-small',
          'purple-normal',
          'transparent',
          'transparent-badge',
          'gray',
          'transparent-alt',
          'transparent-alt-big',
          'purple-transparent-small'].includes(shape);
      },
    },
    showCurrency: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconPath() {
      return '/img/icon/coaching/ico_point_diamond.png';
    },
    num() {
      return commaDecimal(Math.floor(this.value));
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[point-icon] {
  &.clickable {
    cursor: pointer;
  }

  .inline-flex;.flex-jc(center);.flex-ai(center);

  &.normal {
    .bgc();

    .amount { .fs(16);.chakra;.bold }

    .currency { .fs(14);.chakra }

    img { .wh(20, 20);.mr(4) }
  }

  &.normal-small {
    .bgc();

    .amount { .fs(14);.chakra;.bold }

    .currency { .fs(14);.chakra }

    img { .wh(16, 16);.mr(4) }
  }

  &.purple-transparent-small {
    .bgc();.c(#8300ff);

    .amount { .fs(14);.chakra;.bold }

    .currency { .fs(14);.chakra }

    img { .wh(16, 16);.mr(4) }
  }

  &.white, &.white-no-left-padding {
    .min-w(76);.bgc(#fff);.br(20);.p(4, 11, 4, 9);.c(#8300ff);

    .amount { .fs(20);.mr(2);.chakra }

    .currency {.mt(4);.fs(14);.chakra }

    img {.wh(32, 32);.mr(2);}
  }

  &.white-no-left-padding {
    .pl(0);
    img {.ml(-2);}
  }

  &.gray {
    .min-w(76);.bgc(#ebebf0);.br(20);.p(4, 11, 4, 9);.c(#8300ff);

    .amount { .fs(20);.mr(2);.chakra }

    .currency {.mt(4);.fs(14);.chakra }

    img {.wh(32, 32);.mr(2);}
  }

  &.purple-large {
    .min-w(80);.bgc(#fff);background-image: linear-gradient(to bottom, #b900ff, #8300ff);.p(4, 20);.br(20);.c(#fff);

    .amount { .fs(20);.mr(2); }

    .currency { .mt(4);.fs(14); }

    img { .wh(32, 32);.mr(2); }
  }

  &.purple-normal {
    .min-w(36);.bgc(#8300ff);.p(2, 10, 2, 11);.br(12);.c(#fff);

    .amount {.fs(14);.mr(2);}

    .currency {.hide}

    img {.wh(16, 16);.mr(2);}
  }

  &.purple-small {
    .min-w(37px);.bgc(#8300ff);.p(10px, 3px, 10px, 0px);.br(12px);.c(#fff);

    .amount {.fs(14px)}

    .currency {.hide()}

    img {.wh(20px, 20px)}
  }

  &.transparent {
    .bgc();.p(0);.c(#8300ff);

    .amount {.fs(20px)}

    .currency { .hide()}

    img {.wh(38px, 38px)}
  }

  &.transparent-badge {
    .bgc();.p(0);.c(#fff);

    .amount { .rel();.t-xy(-8px, -8px);.min-w(16px);.z(5);.fs(10px);.p(3px, 2px, 3px, 3px);.br(8px);.bgc(#8300ff);.tc() }

    .currency { .hide()}

    img { .rel();.z(10);.wh(38px, 38px)}
  }

  &.transparent-alt {
    .min-w(76);.bgc();.p(4, 11, 4, 0);.c(#8300ff);

    .amount { .fs(20);.mr(2);.chakra }

    .currency {.mt(4);.fs(14);.chakra }

    img {.wh(32, 32);.mr(2);}
  }

  &.transparent-alt-big {
    .min-w(76);.bgc();.p(4, 11, 4, 0);.c(#8300ff);

    .amount { .fs(28);.mr(2);.chakra }

    .currency {.mt(4);.fs(18);.chakra;.bold; }

    img {.wh(24, 24);.mr(2);}
  }

  @media (min-width: @SCREEN_MM) {
    .mb(0);.ib;.w(100%);.max-w(50%-8px);
    & + [mission-card] { .ml(16); }
  }

}
</style>
