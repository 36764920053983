<template>
  <span text-box :class="[{'focus':focusOn},theme]">
    <textarea class="field" ref="textBox" @input="change" :placeholder="placeholder" :value="t0"
      @keydown.enter="$emit('enter')" @blur="blur" @focus="focus"></textarea>
  </span>
</template>
<script>
export default {
  name: 'TextBox',
  props: {
    value: { default: '' },
    placeholder: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    maxLength: { type: String, default: null },
    theme: { type: String, default: 'white' },
  },
  data() {
    return {
      t0: '',
      focusOn: false,
    };
  },
  watch: {
    value: 'update',
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      this.t0 = this.value;
    },
    change(e) {
      const { value } = e.target;
      this.t0 = (() => {
        switch (true) {
          case +this.maxLength > 0:
            return value.slice(0, +this.maxLength);
          case this.digit:
            return value.replace(/[^\d]/gi, '');
          default:
            return value;
        }
      })();
      this.$emit('input', this.t0);
    },
    blur() {
      this.focusOn = false;
      this.$emit('blur', this.t0);
    },
    focus() {
      this.focusOn = true;
      this.$emit('focus', this.t0);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[text-box] {.block; .-box; .c(@c-placeholder); .br(12); .medium; .crop; .rel;
  &:after {.cnt; .abs; .wh(100%, 1); .bgc(rgba(0, 0, 0, 0.1)); .t-s(0); .lb(0, 0); transition: transform .3s;}
  &.focus:after {.bgc(rgba(0, 0, 0, 0.9)); .t-s(1);}
  .field {.fs(16, 20px); .wh(100%); .min-h(72); .-a; .bgc(@c-base-gray); .p(12); .-box; .c(@c-title-black); .block;
    &::placeholder {.c(@c-placeholder); .medium;}
  }
  &.dark { .br(6);
    &.focus:after {.bgc(#fff);}
    .field { .fs(14, 20); .bgc(rgba(255, 255, 255, 0.04)); .-b(rgba(255, 255, 255, 0.1)); .c(#fff);
      &::placeholder { .fs(13); }
    }
  }

}
.error [text-box], [text-box].error {
  &:after { .bgc(#f90); .t-s(1);}
}
</style>
