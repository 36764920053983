<template>
  <div class="b-locale-list">
    <a href="#" class="b-locale-list-item active">
      <img :src="SvgIcoCheck" class="b-locale-list-icon">
      <img :src="SvgIcoCheckActive" class="b-locale-list-icon active">
      <span>한국어</span>
    </a>
    <a href="#" class="b-locale-list-item">
      <img :src="SvgIcoCheck" class="b-locale-list-icon">
      <img :src="SvgIcoCheckActive" class="b-locale-list-icon active">
      <span>English</span>
    </a>
    <a href="#" class="b-locale-list-item">
      <img :src="SvgIcoCheck" class="b-locale-list-icon">
      <img :src="SvgIcoCheckActive" class="b-locale-list-icon active">
      <span>日本語</span>
    </a>
  </div>
</template>

<script>
import SvgIcoCheck from '@bigpi-dakgg/style/dist/images/ico-check.svg';
import SvgIcoCheckActive from '@bigpi-dakgg/style/dist/images/ico-check-active.svg';

export default {
  data() {
    return {
      SvgIcoCheck,
      SvgIcoCheckActive,
    };
  },
};
</script>
