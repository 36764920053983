const view = path => () => import(/* webpackChunkName: "signup" */ `../views/pages/signup/${path}.vue`);
// noinspection JSUnresolvedVariable
export default () => ({
  path: 'signup',
  component: view('Signup'),
  children: [
    {
      path: '/',
      redirect: 'membership-agreement',
    },
    {
      path: 'membership-agreement',
      name: 'Signup',
      component: view('MembershipAgreement'),
    },
    {
      path: 'form',
      name: 'SignupForm',
      component: view('SignupForm'),
    },
    {
      path: 'representative',
      name: 'Representative',
      component: view('Representative'),
    },
    {
      path: 'verification',
      name: 'Verification',
      component: view('Verification'),
    },
    {
      path: 'reverification-agreement',
      name: 'Reverification',
      component: view('MembershipAgreement'),
      props: { reverification: true }
    },
    {
      path: 'reverification-verification',
      name: 'ReverificationVerification',
      component: view('Verification'),
      props: { reverification: true }
    },
    {
      path: 'reverification-representative',
      name: 'ReverificationRepresentative',
      component: view('Representative'),
      props: { reverification: true }
    },
    {
      path: 'duplicated-verification',
      name: 'DuplicatedVerification',
      component: view('DuplicatedVerification'),
    },
    ...(TARGET_NODE ? [] : [{ path: '*', redirect: '/404' }])
  ],
});
