import SendBird from 'sendbird';
import { appendQuery, eqLikeLink } from '@shared/utils/urlUtils';
import moment from 'moment';
import Vue from 'vue';
import _debounce from 'lodash/debounce';
import ChattingContainer from '@/views/components/chatting/ChattingContainer.vue';

const { VUE_APP_SENDBIRD_APP_ID } = process.env;

/**
 * @description
 * 채팅 서비스
 * - 채팅 데이터는 chat.store.js에서 보관
 * - 현재 보고있는 채팅방의 대화내역은 vuex store chat/receivedMessages안에 있음
 * 작동 순서
 * 1. setApp()을 통해 앱의 컨텍스트를 주입(app.mount)
 * 2. setHandler를 통해 센드버드의 이벤트 리스너를 연결
 * 3. init()을 통해 속성값을 초기화
 * 3-1. fetchChannelList()를 통해 기존에 열려있는 모든 채팅방을 가져옴
 * 4. 메시지를 새롭게 받을 경우: fetchChannelMsg()를 통하여 새로운 메시지 목록을 수신함
 * 4-1. 여기서 같은 채널에 메세지가 또 왔을 경우 기존 메시지를 날리지 않고 새로운 메시지만 추가함
 * - 채널이 변경되었을 경우 별도의 이벤트를 통해 기존에 받은 메시지를 모두 제거
 * 주의할 점
 * 스스로 채널을 변경했을 때, store에 예전 채널 정보가 그대로 남아있을 수 있음.
 * 따라서 직접 채팅방을 새로 열 때, 채널이 달라졌으면 기존 내용을 제거해야 함
 *
 * 메시지는 여러 채널에서 듣지만,
 * 개별 채널의 상세 스펙을 조회(query)할 때에는 1개의 채널만 선택(select/active)하여 리스닝한다. i.g. 대화 내용 전체 조회
 */

export default class ChattingService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ApiConnection} */
  #coachApi;
  /** @type {SendBird.SendBirdInstance} */
  #sendBird;
  /** @type {ServiceManager} */
  #services;
  /** @type {SendBird.GroupChannelListQuery} */
  #channelListQuery;
  /** @type {HTMLAudioElement} */
  #sound;
  #userListeners = [];
  markAsReadDebounce = null;
  constructor(services) {
    this.#api = services.commonApi;
    this.#coachApi = services.coachingApi;
    this.#sendBird = new SendBird({ appId: VUE_APP_SENDBIRD_APP_ID });
    this.#services = services;
  }
  /**
   * @description
   * 현재 리스닝하고 있는 센드버드 채널에 대한 wrapper object(model)
   * @returns {?{selectedChannel: SendBird.GroupChannel, query: PreviousMessageListQuery}}
   */
  get activeChannel() {
    return this.#services.store.getState('chat', 'activeChannelQuerySet');
  }
  /**
   * @description
   * activeChannel 의 실질적인 인스턴스
   * @returns {SendBird.GroupChannel}
   */
  get selectedChannel() {
    return this.activeChannel?.selectedChannel;
  }
  /**
   * @description
   * 현재 DOM 상에 채팅 모달이 mount 되었는지 여부
   * @returns {boolean}
   */
  get chatModalExist() {
    return Boolean(document.body.querySelector('[chatting-container]'));
  }
  /** @type {boolean} */
  get isConnected() {
    return !!this.#sendBird?.currentUser;
  }
  /**
   * @description chatting service 용 error 처리
   * @param {string} msg
   */
  err(msg) {
    throw new Error(`SendBird Error: ${msg}`);
  }
  /**
   * @description
   * 특정 채널이 현재 activeChannel.selectedChannel 과 같은 채널인지 확인
   * @param {SendBird.GroupChannel} channel
   * @returns {boolean}
   */
  equalToSelectedChannel(channel) {
    return this.selectedChannel?.url === channel?.url;
  }
  async getTotalUnReadMsgCount() {
    try {
      this.#services.store.commit('chat/setUnReadCount', await this.#sendBird.getTotalUnreadMessageCount(["COACH_DM", "COACH_SYSTEM"]));
    } catch (error) {
      this.err(`UnRead Count Error -> ${error}`);
    }
  }
  /**
   * @description 샌드버드 인증 토큰 발급
   * @param {{nickname: string}} sendBirdUserRequest
   * @return {Promise<ChatUserResponse>}
   */
  getSendBirdUser(sendBirdUserRequest) {
    return this.#api.post(`/sendbird/auth`, sendBirdUserRequest);
  }

  /**
   * @description 현재 유저 상태를 업데이트 처리
   * @param {ChatUserResponse} userInfo
   * @param {string} userInfo.nickname
   */
  async updateCurrentUser({ nickname }) {
    await this.#sendBird.updateCurrentUserInfo(nickname.trim(), '', (user, error) => {
      if (error) this.err(`Update User Error -> ${error}`);
    });
  }

  /**
   * @description 유저를 연결 시킴
   * @param {ChatUserResponse} userInfo
   */
  async connectUser(userInfo) {
    const { accessToken, userId } = userInfo;
    await this.#sendBird.connect(userId.trim(), accessToken.trim(), async (user, error) => {
      if (error) this.err(`Connection Error Occurred -> ${error}`);
      else await this.updateCurrentUser(userInfo);
    });
  }

  setChannelListQuery() {
    return new Promise(resolve => {
      if (!this.#channelListQuery?.hasNext) {
        this.#channelListQuery = this.#sendBird.GroupChannel.createMyGroupChannelListQuery();
        // 코치 DM만 표기하도록 설정 - 해당 줄을 삭제하면 코치 메시지 말고 다른 메시지도 들어옴
        this.#channelListQuery.customTypesFilter = ["COACH_DM", "COACH_SYSTEM"];
        this.#channelListQuery.includeEmpty = true;
        this.#channelListQuery.limit = 20;
        resolve();
      }
    });
  }

  /**
   * @description 리더 위임 api
   * @param {string} arenaId
   * @param {string} applyId
   * @param {string} leaderId
   */
  async changeLeader({ arenaId, applyId, leaderId }) {
    try {
      await this.#api.post(appendQuery(`/arenas/${arenaId}/applications/${applyId}/change-leader`, { leaderId }));
      this.#services.toast.toast('chatting.changeLeader.success', { type: 'success' });
    } catch ({ code }) {
      throw ['chatting.changeLeader', code];
    }
  }

  initActiveChannel() {
    this.#services.store.commit('chat/setActiveChannelQuerySet', null);
    this.#services.store.commit('chat/setReceivedMessages', []);
  }

  /**
   * @param {ChatUserResponse} userInfo
   */
  setSbUserInfo(userInfo) {
    this.#services.store.commit('chat/setSbUserInfo', userInfo);
  }

  /**
   * @param {SendBird.GroupChannel} channel
   */
  setActiveChannel(channel) {
    if (!this.equalToSelectedChannel(channel)) this.initActiveChannel();
    this.#services.store.commit('chat/setActiveChannelQuerySet', {
      selectedChannel: channel,
      query: channel.createPreviousMessageListQuery(),
    });
  }

  /**
   * @description
   * 센드버드 메시지의 date를 파싱하고 메타데이터를 계산
   * @param {SendBird.UserMessage[] | SendBird.AdminMessage[]} acc
   * @param {SendBird.UserMessage | SendBird.AdminMessage} cu
   * @param {number} idx
   * @return {SendBird.UserMessage[] | SendBird.AdminMessage[]}
   */
  parseMsg(acc, cu, idx) {
    /** @description 먼저 초기화를 시켜줌 지난 데이터에 이미 찍혀 있을수도 있으니 재계산이 필요 */
    cu._showDate = null;
    if (moment(cu.createdAt).format('YYYYMMDD') !== moment(acc[idx - 1]?.createdAt).format('YYYYMMDD')) cu._showDate = cu.createdAt;
    return [...acc, cu];
  }

  /**
   * @typedef {SendBird.UserMessage | SendBird.AdminMessage} UserAdminMessage
   * @description 메세지를 가져오는 로직
   * @param {SendBird.GroupChannel} channel
   * @param {UserAdminMessage} newMsg?
   */
  async fetchChannelMsg(channel, newMsg) {
    if (channel?.url !== this.selectedChannel?.url && channel?.url !== undefined) {
      return;
    }
    const receivedMessages = this.#services.store.getState('chat', 'receivedMessages');
    if (newMsg) this.#services.store.commit('chat/setReceivedMessages', [...this.parseMsg(receivedMessages, newMsg, receivedMessages.length)]);
    else this.#services.store.commit('chat/setReceivedMessages', [...(await this.activeChannel.query.load()).reduce(this.parseMsg, []), ...this.#services.store.getState('chat', 'receivedMessages')]);
  }

  /**
   * @description 텍스트 메세지 전송
   * @param {string} msg
   * @return {Promise<SendBird.UserMessage | SendBird.AdminMessage>}
   */
  async sendTxtMsg(msg) {
    const { selectedChannel } = this.#services.store.getState('chat', 'activeChannelQuerySet');

    const req = new Promise((resolve, reject) => {
      selectedChannel.sendUserMessage(msg, (message, error) => {
        if (error) reject();
        else resolve(message);
      });
    });

    // 카카오 알림톡으로 해당 메시지 전송
    if (!this.#services.store.getGetter('auth', 'isCoach') && selectedChannel?.customType === 'COACH_DM') {
      const coachId = selectedChannel?.members.find(m => !!m?.metaData?.gcoCoachId)?.metaData?.gcoCoachId ?? '';
      if (coachId !== '') await this.#coachApi.post(`/chat/coaches/${coachId}/kakao`, undefined, { silent: true });
    }
    return req;
  }

  /**
   * @description 수강 강의 정보를 가져오는 api
   * @param {string} coachUserId
   * @param {string} userId
   * @param {string?} status
   * @param {string?} order
   * @return {Promise<ChatLessonInfoCursorList>}
   */
  fetchLesson({ coachUserId, userId, status, order }) {
    return this.#coachApi.get(`/lessons/products/orders`, { cursor: 0, page: 0, size: 10, order, q: eqLikeLink({ coachUserId, userId, status }) });
  }

  /**
   * @description 코치 수업 시작하기
   * @param {string} lessonProductOrderId
   * @return {Promise<ChatLessonInfo>}
   */
  startLesson(lessonProductOrderId) {
    return this.#coachApi.post(`/lessons/products/orders/${lessonProductOrderId}/start`);
  }

  /**
   * @description 코치 수업 종료하기
   * @param {string} lessonProductOrderId
   * @return {Promise<void>}
   */
  endLesson(lessonProductOrderId) {
    return this.#coachApi.post(`/lessons/products/orders/${lessonProductOrderId}/end`);
  }

  /**
   * @description 코치 수강료 환불하기
   * @param {string} lessonProductOrderId
   * @return {Promise<void>}
   */
  refundLesson(lessonProductOrderId) {
    return this.#coachApi.post(`/lessons/products/orders/${lessonProductOrderId}/refund`);
  }

  /**
   * @description 수업 이슈 등록하기
   * @param {string} lessonProductOrderId
   * @param {string} title
   * @param {string} content
   * @return {Promise<void>}
   */
  postIssue({ lessonProductOrderId, title, content }) {
    return this.#coachApi.post(`/lessons/products/orders/${lessonProductOrderId}/issue`, { title, content });
  }

  async* fetchChannelListGen() {
    while (this.#channelListQuery.hasNext) yield await this.#channelListQuery.next() ?? [];
  }

  /**
   * @description channel list(방) 를 가져오는 api
   */
  async fetchChannelList() {
    await this.setChannelListQuery();
    const channelList = this.fetchChannelListGen();
    const isInitialFetch = this.#services.store.getState('chat', 'channelList').length === 0;
    let temp = [];
    for await (const channels of channelList) {
      temp = [...temp, ...channels];
      if (isInitialFetch) this.#services.store.commit('chat/setChannelList', temp);
    }
    if (!isInitialFetch) this.#services.store.commit('chat/setChannelList', temp);
  }

  /**
   * @description #activeChannelQuerySet?.selectedChannel 가 있다는 의미는 채팅방안에 들어가 있다는 의미임으로 markAsRead 함수를 호출한다.
   * @param {SendBird.GroupChannel} channel
   */
  markAsRead(channel) {
    if (!this.markAsReadDebounce) { this.markAsReadDebounce = _debounce(function (channelUrl) {
          if (this.#services.store.getState('chat', 'activeChannelQuerySet')?.selectedChannel?.url === channelUrl) {
            const activeChannelQuerySet = this.#services.store.getState('chat', 'activeChannelQuerySet');
            if (activeChannelQuerySet?.selectedChannel?.unreadMessageCount) activeChannelQuerySet?.selectedChannel.markAsRead();
          }
        }, 300, { trailing: true }); }
    this.markAsReadDebounce?.(channel.url);
  }

  /**
   * @param {SendBird.GroupChannel} channel
   */
  async playReceiveMessageSound(channel) {
    async function playSoundEl() {
      const sEl = document.createElement('audio');
      const appEl = document.getElementById('app');
      if (!appEl) return;
      sEl.src = '/audio/message-receive.mp3';
      sEl.type = 'audio/mp3';
      sEl.autoplay = true;
      sEl.style.display = 'none';
      sEl.addEventListener('ended', () => {
        appEl.removeChild(sEl);
      });
      appEl.appendChild(sEl);
      try {
        await sEl.play();
      } catch (e) {
        appEl.removeChild(sEl);
      }
    }
    if (channel?.url === this.#services.store.getState('chat', 'activeChannelQuerySet')?.selectedChannel?.url) return;
    await playSoundEl();
  }

  /**
   * 채팅 창을 여는 기능과 닫는 기능, 토글 기능이 모두 따로 구현되어 있다.
   * 항상 창이 무조건 열어져야만 하는 케이스가 있고, (i.e. 상품 구매후 자동 채팅창 열림)
   * 반대로 항상 창이 무조건 닫혀야만 케이스가 있고 (i.e. 채팅창이 UI-UX상으로 불필요한 경우)
   * 또 상태를 보고 둘 중에 필요한 기능(열기/닫기)를 모두 구현해야 하기 때문에 모두 필요하다.
   * 이 중에 한 가지를 불필요하다고 보고 함부로 삭제하지 말 것.
   */

  /**
   * @description 채팅창을 여는 기능
   * @param appContext
   * @param {SendBird.GroupChannel?} channel
   */
  async openChatModal(appContext, channel) {
    if (channel) this.setActiveChannel(channel);
    /** @description 테블릿이나 모바일이면 $modal 3번째 인자(clear)에 false 를 피씨라면 true 를 넣는다. */
    const isMobile = (appContext.matchedMediaDevice === 'T' && appContext.matchedMedia === 'TP') || appContext.matchedMediaDevice === 'M';
    if (this.chatModalExist) return;
    await appContext.$modal(ChattingContainer, {}, !isMobile);
  }

  async closeChatModal(appContext) {
    if (this.chatModalExist) await appContext.$modal(ChattingContainer, { close: true });
  }

  async toggleChatModal(appContext, channel) {
    if (this.chatModalExist) {
      await this.closeChatModal(appContext);
      return;
    }
    await this.openChatModal(appContext, channel);
  }

  /**
   * @description
   * 코치와 채팅방 열기
   * 옵션은 GTM 추적을 위해 있는 부분입니다.
   * @param appContext
   * @param {string} coachId
   * @param {boolean?} isPurchaseEvent - 구매로 인해 발생한 이벤트인지 여부
   */
  async openChatWithCoach(appContext, coachId, { isPurchaseEvent = false } = {}) {
    // 값이 이후에 임의로 바뀌지 않도록 형변환 처리하여 observe 속성이 복사되지 않도록한다
    const isFirstChat = Boolean(!this.#services.store.getGetter('chat', 'hasCoachDM'));

    // 수강 구매로 인한 코치 DM은 웰컴메시지 여부가 무조건 disabled 되어 있어야 한다
    /** @type {ChatWithCoachResponse} */
    const { channelUrl } = await this.#coachApi.post(`/chat/coaches/${coachId}`, { useWelcomeMessage: !isPurchaseEvent });
    console.log({ useWelcomeMessage: !isPurchaseEvent, isPurchaseEvent });
    await this.fetchChannelList();
    // 기존에 코치와 DM내역이 없는 상태일 경우 GTM으로 보고
    Vue.prototype.$gtag.event(isFirstChat ? 'chat_with_coach_first_time' : 'chat_with_coach', { category: 'sendbird_chatting', event_label: isPurchaseEvent ? 'lesson_purchase' : 'direct', value: coachId });
    await this.openChatModal(appContext, this.#services.store.getState('chat', 'channelList').find(channel => channel.url === channelUrl));
  }

  /**
   * @description 채팅방 열기
   * @param appContext
   * @param {string} chatUrl
   */
  async openChatWithUrl(appContext, chatUrl) {
    await this.fetchChannelList();
    await this.openChatModal(appContext, this.#services.store.getState('chat', 'channelList').find(channel => channel.url === chatUrl));
  }

  /**
   * @description 메시지 수신 후, messageCode 동작
   */
  async messageReceiveAction(appContext, channel, message) {
    const isCoach = this.#services?.auth?.myInfo?.isCoach;
    const metaData = JSON.parse(message?.data || '{}');

    /** @type {'LESSON_PRODUCT_ORDER_STARTED' | 'LESSON_PRODUCT_ORDER_ORDERED' | 'LESSON_PRODUCT_ORDER_CANCELED', 'LESSON_PRODUCT_ORDER_REFUNDED' | 'QNA_ANSWER_POSTED' | 'COUPON_ISSUED', 'RECOMMEND_COACH_COUNSELING'} */
    const messageCode = metaData?.messageCode || undefined;
    const isChatModalOpened = appContext.$checkModal(ChattingContainer);

    if (!messageCode) return;

    switch (messageCode) {
      case 'RECOMMEND_COACH_COUNSELING': // 상담 요청
        if (isCoach) {
          await this.openChatModal(appContext, null);
        }
        break;
      case 'LESSON_PRODUCT_ORDER_ORDERED': // 수강 주문
        if (isCoach) {
          if (isChatModalOpened) {
            await this.initActiveChannel();
            await this.setActiveChannel(channel);
          } else await this.openChatModal(appContext, channel);
        }
      // eslint-disable-next-line no-fallthrough
      case 'COUPON_ISSUED': // 쿠폰 발행
      case 'QNA_ANSWER_POSTED': // 질문에 답변 완료
        if (isCoach) return;
      // eslint-disable-next-line no-fallthrough
      case 'LESSON_PRODUCT_ORDER_STARTED': // 수강 시작
      case 'LESSON_PRODUCT_ORDER_CANCELED': // 수강 취소
      case 'LESSON_PRODUCT_ORDER_REFUNDED': // 수강 환불
        if (isChatModalOpened || this.#services.store.getState('chat', 'activeChannelQuerySet')) return;
        await this.openChatModal(appContext, channel);
        break;
      default:
    }
  }

  /**
   * @description handler 를 실행 하는 구문. 실행 시점은 App 의 mount 시점에 작동
   */
  setHandler(app) {
    const channelHandler = new this.#sendBird.ChannelHandler();
    channelHandler.onMessageReceived = async (channel, message) => {
      console.log('onMessageReceived', { channel, message });
      await Promise.all([this.playReceiveMessageSound(channel), this.fetchChannelMsg(channel, message)]);
      this.markAsRead(channel);
      await this.messageReceiveAction(app, channel, message);
    };
    channelHandler.onChannelChanged = async channel => {
      console.log('onChannelChanged', { channel });
      await this.getTotalUnReadMsgCount();
    };
    channelHandler.onUserLeft = async (channel, user) => {
      console.log('onUserLeft', { channel, user });
      if (this.#userListeners.length) this.#userListeners.forEach(l => l('left'));
      await Promise.all([this.fetchChannelList(), this.getTotalUnReadMsgCount()]);
      if (this.#services.store.getState('chat', 'activeChannelQuerySet')?.selectedChannel?.url === channel.url) this.initActiveChannel();
    };
    channelHandler.onUserJoined = async (channel, user) => {
      console.log('onUserJoined', { channel, user });
      if (this.#userListeners.length) this.#userListeners.forEach(l => l('joined'));
      await Promise.all([this.fetchChannelList(), this.getTotalUnReadMsgCount()]);
      await this.openChatModal(app, channel);
    };
    this.#sendBird.addChannelHandler('GLOBAL_HANDLER', channelHandler);
  }

  addUserListener(listener) {
    this.#userListeners.push(listener);
  }

  removeUserListener(listener) {
    const idx = this.#userListeners.indexOf(listener);
    if (idx > -1) this.#userListeners.splice(idx, 1);
  }

  async setApp(app) {
    if (!this.isConnected) return null;

      /** @description 빠른 로딩을 위해 미리 불러 옴 */
      await this.fetchChannelList();
      this.setHandler(app);
  }

  /**
   * @description entry-client 에서 처음 sendBird 에 필요한 정보 initiation
   */
  async init() {
    if (!this.#services.auth.isLogin || this.isConnected) return null;

      const userInfo = await this.getSendBirdUser({ nickname: '' });
      this.setSbUserInfo(userInfo);
      await Promise.all([this.connectUser(userInfo), this.getTotalUnReadMsgCount()]);
  }
}
