<template>
  <div chatting-room>
    <div class="chat-header">
      <button class="btn-home" @click="$emit('back')" />
      <button v-if="isCoach && isCoachDm && lessonStatus === 'ONGOING'" class="btn-cs" @click="classIssueSend" />
    </div>
    <div class="chat-wrapper">
      <div class="room-header">
        <span class="title">{{ $t('_.groupChat') }} <em class="count">{{ memberCount }}</em>
        </span>
      </div>
      <div class="participant">
        <div v-if="isCoach && isCoachDm" class="coach-bts">
          <color-button type="purple-style" class="btn-start" @click="onClickLessonStart" :disabled="lessonStatus !== 'WAIT'">{{ $t('_.startClass') }}</color-button>
          <color-button type="purple-style" class="btn-end" @click="onClickLessonEnd" :disabled="lessonStatus !== 'ONGOING'">{{ $t('_.endClass') }}</color-button>
          <color-button type="red" class="btn-cancel" @click="onClickLessonCancel" :disabled="!lessonInfo || lessonStatus === 'CANCEL' || lessonStatus === 'REFUNDED' || lessonStatus === 'END'">{{ $t('_.cancelClass') }}</color-button>
        </div>
        <div :class="['loading-wrapper', isCoachDm && isCoach ? 'coach-loading' : '']" v-if="loading">
          <SvgLoading />
          LOADING
        </div>
      </div>
      <ul :class="['contents scroll-area', isCoachDm && isCoach ? 'coach-content' : '']" ref="chat-content" @scroll="onScrollList">
        <li :class="{'prev-top-msg': idx % 20 === 0 }" v-for="(msg, idx) in receivedMessages" :key="msg.url">
          <p v-if="msg._showDate" class="date">{{ $date(msg._showDate, 'ymdday') }}</p>
          <p v-if="idx === newMsgMarkIndex" class="new-indicator" ref="new-indicator">NEW</p>
          <AdminMessageFormat v-if="isAdminMsg(msg)" :info="msg" />
          <NormalMessageFormat v-else :info="msg" />
        </li>
      </ul>
      <div class="noti-new-msg" v-if="newMsgReceivedOnScrollTop" @click="scrollToBtm">{{ $t('_.newMsg') }}</div>
      <div class="chat">
        <TextInput ref="msg-input" :placeholder="isCoachSystem ? $t('_.disabledPlaceholder') : $t('_.normalPlaceholder') " v-model="msgInput" :disabled="isCoachSystem" @enter="sendMsg" />
        <color-button type="dark" class="send" :disabled="isCoachSystem" @click="sendMsg">{{ $t('_.send') }}</color-button>
      </div>
    </div>
  </div>
</template>
<script>
import _isEqual from 'lodash/isEqual';
import Specific from '@shared/types/Specific';
import TextInput from '@shared/components/common/input/TextInput.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import { getter, state } from '@shared/utils/storeUtils';
import { getScrolledRatio } from '@shared/utils/domUtils';
import LinkCopyModal from '@/views/components/chatting/modal/LinkCopyModal.vue';
import AdminMessageFormat from '@/views/components/chatting/AdminMessageFormat.vue';
import NormalMessageFormat from '@/views/components/chatting/NormalMessageFormat.vue';
import ClassIssueSendModal from '@/views/components/chatting/modal/ClassIssueSendModal.vue';
import SvgLoading from '@/views/graphics/svg-loading.vue';

export default {
  name: 'ChattingRoom',
  lexicon: 'chatting.room',
  components: { ColorButton, NormalMessageFormat, AdminMessageFormat, TextInput, SvgLoading },
  props: {
    info: Specific,
  },
  data() {
    return {
      /** @type {string} */
      msgInput: '',
      /** @type {boolean} */
      open: false,
      /** @type {boolean} */
      initialLoading: false,
      /** @type {boolean} */
      loading: false,
      /** @type {boolean} */
      newMsgReceivedOnScrollTop: false,
      /** @type {?number} */
      newMsgMarkIndex: null,
      /** @type {ChatLessonInfo} */
      lessonInfo: null,
    };
  },
  computed: {
    /** @type {string} */
    myUserId: getter('auth', 'userId'),
    /** @type {Array<SendBird.UserMessage | SendBird.AdminMessage>} */
    receivedMessages: state('chat', 'receivedMessages'),
    /** @type {boolean} */
    isCoach: getter('auth', 'isCoach'),
    /** @type {SendBird.GroupChannel} */
    roomInfo() {
      return this.info ?? {};
    },
    /** @type {number} */
    unreadMessageCount() {
      return this.roomInfo?.unreadMessageCount ?? 0;
    },
    /** @type {ChatType | ''} */
    customType() {
      return this.roomInfo?.customType ?? '';
    },
    /** @type {number} */
    memberCount() {
      return this.roomInfo.memberCount ?? 0;
    },
    /** @type {boolean} */
    isCoachSystem() {
      return this.roomInfo?.customType === 'COACH_SYSTEM';
    },
    /** @type {boolean} */
    isCoachDm() {
      return this.roomInfo?.customType === 'COACH_DM';
    },
    /** @type {SendBird.Member} */
    opponent() {
      return this.roomInfo?.members.find(team => team?.metaData?.lvupUserId !== this.myUserId);
    },
    /** @type {'WAIT' | 'ONGOING' | 'END' | 'REFUNDED' | 'CANCEL' | ''} */
    lessonStatus() {
      return this.lessonInfo?.status ?? '';
    },
    /** @type{string} */
    lessonProductOrderId() {
      return this.lessonInfo?.lessonProductOrderId ?? '';
    },
  },
  methods: {
    async onClickShare() {
      await this.$modal(LinkCopyModal);
    },
    async classIssueSend() {
      await this.$modal(ClassIssueSendModal, this.lessonInfo);
    },
    /** @description 기본적인 data 를 가져오는 method */
    async getBasicInfoInfo() {
      await this.$services.auth.fetchMyInfo();
      if (this.isCoachDm) {
        const opponentId = this.opponent?.metaData?.lvupUserId;
        const coachUserId = this.isCoach ? this.myUserId : opponentId;
        const userId = this.isCoach ? opponentId : this.myUserId;
        const [waitingLesson, previousLesson] = await Promise.all([this.$services.chat.fetchLesson({ coachUserId, userId, status: 'WAIT', order: 'buyDateTime ASC' }), this.$services.chat.fetchLesson({ coachUserId, userId, order: 'buyDateTime DESC' })]);
        this.lessonInfo = waitingLesson.items.length === 0 ? previousLesson.items[0] : waitingLesson.items[0];
      }
    },
    async sendMsg() {
      await this.timeoutShield();
      const message = await this.$services.chat.sendTxtMsg(this.msgInput);
      await this.$services.chat.fetchChannelMsg(this.roomInfo, message);
      this.msgInput = '';
      this.scrollToBtm();
    },
    /**
     * @param {SendBird.UserMessage | SendBird.AdminMessage} msg
     */
    isAdminMsg(msg) {
      return msg?.messageType === 'admin';
    },
    async onScrollList(e) {
      if (getScrolledRatio(e) === 1) this.newMsgReceivedOnScrollTop = false;
      if (!this.initialLoading && getScrolledRatio(e) === 0) {
        await this.timeoutShield();
        this.loading = true;
        await this.$services.chat.fetchChannelMsg(this.roomInfo);
        this.$nextTick(() => {
          const prevTopMessages = document.querySelectorAll('.prev-top-msg');
          if (!prevTopMessages.item(1)) {
            this.loading = false;
            return;
          }
          this.setScrollTop(prevTopMessages.item(1).getBoundingClientRect().top - 200);
          this.loading = false;
        });
      }
    },
    markNewMsgIndex() {
      if (this.unreadMessageCount > 0) this.newMsgMarkIndex = this.receivedMessages.length - this.unreadMessageCount;
    },
    async initialFetchMsg() {
      await this.$services.chat.fetchChannelMsg(this.roomInfo);
      /** @description 만약 처음 불러온 것보다 unreadMessage 가 뒤에있다면 그만큼 더 불러옴 */
      while (this.receivedMessages.length < this.unreadMessageCount) await this.$services.chat.fetchChannelMsg(this.roomInfo);
    },
    checkStartScroll() {
      this.$nextTick(() => {
        if (this.$refs['new-indicator']) this.setScrollTop(document.querySelector('.new-indicator').getBoundingClientRect().y - 300);
        else this.scrollToBtm();
      });
    },
    setScrollTop(newScrollTop) {
      const refChatContent = this.$refs['chat-content'];
      if (!refChatContent) return;
      refChatContent.scrollTop = newScrollTop;
    },
    scrollToBtm() {
      this.$nextTick(() => this.setScrollTop(this.$refs['chat-content']?.scrollHeight));
    },
    newMsgArrivedWatcher(newMsgList, oldMsgList) {
      /** @description 새로운 메세지가 왔을때의 조건식 */
      if (newMsgList.length - oldMsgList.length === 1 && !_isEqual(newMsgList[newMsgList.length - 1], oldMsgList[oldMsgList.length - 1])) {
        const { scrollTop, scrollHeight, clientHeight } = this.$refs['chat-content'];
        if (scrollHeight - clientHeight - scrollTop > 120) this.newMsgReceivedOnScrollTop = true;
        else this.scrollToBtm();
      } else if (this.newMsgMarkIndex) this.newMsgMarkIndex += newMsgList.length - oldMsgList.length;
    },
    async onClickLessonStart() {
      this.lessonInfo = await this.$services.chat.startLesson(this.lessonProductOrderId);
    },
    async onClickLessonEnd() {
      await this.$services.chat.endLesson(this.lessonProductOrderId);
      await this.getBasicInfoInfo();
    },
    async onClickLessonCancel() {
      await this.$services.chat.refundLesson(this.lessonProductOrderId);
      await this.getBasicInfoInfo();
    },
  },
  watch: {
    receivedMessages(newMsgList, oldMsgList) {
      this.newMsgArrivedWatcher(newMsgList, oldMsgList);
    },
  },
  async mounted() {
    this.initialLoading = true;
    await Promise.all([this.getBasicInfoInfo(), this.initialFetchMsg()]);
    this.markNewMsgIndex();
    this.initialLoading = false;
    this.$services.chat.markAsRead(this.roomInfo);
    this.checkStartScroll();
  },
};
</script>
<style lang="less">
@import '~@/less/proj.less';

[chatting-room] {
  .chat-header { .p(15, 20, 36, 20); .mb(-20); background: linear-gradient(126deg, rgba(223, 58, 58), rgba(255, 144, 144));
    > h2 { .fs(16); .bold; .fs(16); .c(#ebebf0); }
    .room-title { .ellipsis(1); .ib; .fs(16); .max-w(220); .ml(10); .mr(6); .extra-bold; .vam; text-overflow: ellipsis; white-space: nowrap; }
    .btn-home { .rel; .wh(20);
      &:before { .abs; .lt(-2, -3); .wh(20); .cnt; .contain('@{icon}/Arrow-Active.svg');}
    }
    .btn-share { .abs; .rt(0, 0); .mt(13); .mr(20); .wh(30, 32); .bgc(rgba(255, 255, 255, 0.2)); .br(8);
      &:before { .abs; .lt(5, 5); .wh(18, 20); .cnt; .contain('@{icon}/ico_share_white.svg');}
    }
    .btn-cs { .abs; .rt(0, 0); .mt(13); .mr(40); .bgc(rgba(255, 255, 255, 0.2)); .br(8);
      &:before { .wh(32); .cnt; .contain('@{icon}/ico_headphone_white.svg'); .m(auto); .o(0.9); }
    }
  }
  .chat-wrapper { .rel; .crop; .w(100%); height: calc(100% - 57px); .bgc(white); .c(black); .br(0);
    .room-header { .h(62); .tc; .rel; .lh(62);
      .btn-open { .abs; .lt(20, 18); .z(1);
        &:before { .cnt; .wh(32); .contain('@{icon}/ico_person.svg'); }
      }
      .title { .bold; .vam;
        .count { .ml(4); .c(#df3c3c); .bold;}
      }
      [avatar] { .wh(35); .mr(5); .vam; }
    }
    .participant { .rel; .z(1); box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); .bgc(#ebebf0);
      .coach-bts { .p(12, 12, 8); .tc;
        [color-button] { width: calc((100% / 3) - 4px); .mr(6);
          &:last-child { .mr(0); }
        }
      }
      [avatar] { .wh(40); .vam; .bgc(blue); .br(50%);}
    }
    .loading-wrapper { .abs; .lt(0, 0); .w(100%); .bgc(#ebebf0); .c(#787781); .fs(12); .h(28); .flex; .flex-ai; .flex-jc;
      &.coach-loading { .t(60); }
      > [svg-loading] { .block; .wh(14); .mr(6);
        > .fill-target { .fill(#787781); }
      }
    }
    .contents { .p(20, 16); height: calc(100% - 125px); .min-h(60);
      &.coach-content { height: calc(100% - 185px); }
      > li {
        .date { .mb(20); .fs(11); .c(#787781); .bgc(#ebebf0); .br(50); .tc; }
        .new-indicator { .tr; .c(rgba(223, 58, 58)); .-b(#dce0e3); .mb(4); }
      }
    }
    .noti-new-msg { .pointer; .c(rgba(223, 58, 58)); .fs(11); .w(100%); .bgc; .tc; .abs; .rb(0, 63); .bgc(#fff); }
    .chat { .wh(100%, 63); .bgc(#ebebf0); .p(11, 8, 11, 12);
      > [text-input] { .ib; .h(40); .calc-w(100%, 100px);
        &::after { .hide; }
        > input {.bgc(white); .w(100%);}
      }
      > button { .wh(89, 40); .ml(10); .bgc(black); .c(white); .br(10); .vat;}
    }
    .team-info { .abs; .lt(0, 0); .z(1); .w(100%); .p(13, 12, 20); .bgc(#ebebf0); .o(0); visibility: hidden; transition: max-height 0.2s, opacity 0.2s;
      .roll-up-btn { .br(6); .abs; .rb(50%, -12); .bgc(#ebebf0);
        &:after { .wh(24); .cnt; .contain('@{icon}/Arrow.svg'); transform: rotate(180deg); }
      }
      &.open { .o(1); visibility: visible; }
      .detail-holder { .crop; .w(100%); .mb(8); .p(0, 8); .bgc(#d5d5de);
        .detail { .fl;
          .participant-team { .fs(14, 56px);}
          .count { .wh(32, 20); .ib; .tc; .m(0, 4); .bgc(#ebebf0);.br(4); .fs(14);}
          .min { .fs(11); .c(#a4a3ae);}
        }
        .btn-roster { .fr; .wh(112, 40); .m(8, 0); .br(12); }
      }
      .team-list { .p(20, 12); .-b(#d5d5de); .h(360);
        .item { .rel; .mb(10);
          [avatar] { .pointer; .abs; .lt(0, 0); .z(1); .wh(40); .vam; .bgc(#ddd); .br(50%); }
          .name-wrapper { .h(38); .flex; .flex-jc; flex-direction: column; .pl(50); .pr(92);
            .name { .fs(14); }
            .warning { .fs(12); .c(#ffb600); }
          }
          .leader { .abs; .rt(0, 4); .z(1); .h(32); .fs(12, 32); .br(16);}
          &.leader-item {
            [avatar] { .-a(#df3c3c); }
            &:before { .abs; .z(2); .lb(0, 0); .cnt; .wh(16); .contain('@{icon}/ico_chat_leader.svg'); }
          }
          &.require-validation-item {
            &:before { .abs; .z(2); .lb(0, 0); .cnt; .wh(16); .contain('@{icon}/ico_chat_non_certi.svg'); }
          }
          &.free-agent-pending-item {
            &:before { .abs; .z(2); .lb(0, 0); .cnt; .wh(16); .contain('@{icon}/ico_chat_wait_agent.svg'); }
          }
        }
        .btn-toggle {.abs; .l(45%); .wh(32); .mt(8); .bgc(#ebebf0); .br(8);
          &:before { .abs; .lt(5.5, 5); .cnt; .contain('@{icon}/Arrow_down.svg'); .wh(20); .t-r(180deg);}
        }
      }
    }
  }

  @media (@tl-up) {
    .chat-wrapper { .wh(373, 100%); .br(20);
      .contents { height: calc(100vh - 580px);
        &.coach-content { height: calc(100vh - 580px); }
      }
      .team-info {
        .team-list { .h(100); }
      }
    }
  }
}
</style>
