// import _cloneDeep from 'lodash/cloneDeep';

const getDefaultPointBuyCause = () => ({
  type: '',
  neededPrice: 0,
  coachId: '', // 코치 모달 창을 다시 열기 위한 coachId
  purchaseLessonArg: {},
});

export default () => ({
  namespaced: true,
  state: {
    pointBuyCause: getDefaultPointBuyCause()
  },
  mutations: {
    setPointBuyCause(state, payload) {
      state.pointBuyCause = payload;
    },
    clearPointBuyCause(state) {
      state.pointBuyCause = getDefaultPointBuyCause();
    }
  },
});