// noinspection JSUnresolvedVariable
import { payment } from '@/modules/Payment';

const view = path => () => import(/* webpackChunkName: "coach[request]" */ `../views/pages/coaching/${path}.vue`);

export default () => {
  const coachingRoute = [
    {
      path: 'coach-intro/coach',
      name: 'CoachingIntro',
      redirect: 'coaching-intro',
    },
    {
      path: 'coach',
      redirect: 'lol',
    },
    {
      path: 'coach/lol',
      redirect: 'lol',
    },
    {
      path: 'coaching/coach-center',
      redirect: 'coach-center/lol',
    },
    {
      path: 'coach-center',
      redirect: 'coach-center/lol',
    },
    {
      path: 'coach-center/:gameId(lol)',
      name: 'TutorHome',
      meta: { active: 'main', id: 3, coachSubId: 0 },
      component: view('TutorHome'),
    },
    {
      path: 'coaching',
      redirect: 'lol',
    },
    {
      path: 'coaching/:gameId(lol)',
      redirect: ':gameId(lol)',
    },
    {
      path: ':gameId(lol)',
      name: 'CoachHome',
      meta: { active: 'main', id: 3, subId: 0 },
      component: view('CoachHome'),
      beforeEnter: (to, from, next) => {
        payment.tossPaymentsRouteHandler(to);
        next();
      },
    },
    {
      path: 'coach-finder',
      name: 'CoachFinder',
      meta: { active: 'findCoach', id: 3, subId: 2, coachSubId: 3 },
      component: view('CoachFinder'),
    },
    {
      path: 'coaching/:gameId(lol)/question/:boardWriteId',
      redirect: 'question/:gameId(lol)/:boardWriteId',
    },
    {
      path: ':gameId(lol)/question/:boardWriteId',
      redirect: 'question/:gameId(lol)/:boardWriteId',
    },
    {
      path: 'question/:gameId(lol)/:boardWriteId',
      meta: { active: 'ask', id: 3, subId: 3 },
      name: 'CoachQuestion',
      component: view('CoachQuestion'),
    },
    {
      path: 'coaching/:gameId(lol)/questions',
      redirect: 'questions/:gameId(lol)',
    },
    {
      path: ':gameId(lol)/questions',
      redirect: 'questions/:gameId(lol)',
    },
    {
      path: 'questions/:gameId(lol)',
      name: 'CoachQuestions',
      meta: { active: 'ask', id: 3, subId: 3 },
      component: view('CoachQuestions'),
    },
    {
      path: 'coaching/:gameId(lol)/online-coach',
      redirect: ':gameId(lol)/online-coach',
    },
    {
      path: ':gameId(lol)/online-coach',
      meta: { id: 3, subId: -1 },
      component: view('OnlineCoach'),
    },
    {
      path: 'coaching/:gameId(lol)/TV',
      redirect: 'tv/:gameId(lol)',
    },
    {
      path: 'tv/:gameId(lol)',
      name: 'CoachTvViewAll',
      meta: { active: 'coachTv', id: 3, subId: 4, coachSubId: 4 },
      component: view('CoachTvViewAll'),
    },
    {
      path: 'coaching/:gameId(lol)/TV/:tvContentId/:tvContentGroupId',
      redirect: 'tv/:gameId(lol)/:tvContentId/:tvContentGroupId',
    },
    {
      path: ':gameId(lol)/TV/:tvContentId/:tvContentGroupId',
      redirect: 'tv/:gameId(lol)/:tvContentId/:tvContentGroupId',
    },
    {
      path: 'tv/:gameId(lol)/:tvContentId/:tvContentGroupId',
      name: 'CoachTvViewer',
      meta: { active: 'coachTv', id: 3, subId: 4, coachSubId: 4 },
      component: view('CoachTvViewer'),
    },
    {
      path: 'coaching/:gameId(lol)/TV-filter',
      redirect: 'tv/:gameId(lol)/list',
    },
    {
      path: ':gameId(lol)/TV-filter',
      redirect: 'tv/:gameId(lol)/list',
    },
    {
      path: 'tv/:gameId(lol)/list',
      name: 'CoachTvViewFilter',
      meta: { active: 'coachTv', id: 3, subId: 4 },
      component: view('CoachTvViewFilter'),
    },
    {
      path: 'coach-center/sales-history',
      name: 'CoachSalesHistory',
      meta: { id: 3, subId: -1, coachSubId: 1 },
      component: view('CoachSalesHistory'),
    },
    {
      path: 'coach-center/sales-history/:lessonProductOrderId',
      name: 'CoachSalesHistoryDetail',
      meta: { id: 3, subId: -1, coachSubId: 1 },
      component: view('CoachSalesDetail'),
    },
    {
      path: 'coach-center/settlement-history',
      name: 'CoachSettlementHistory',
      meta: { id: 3, subId: -1, coachSubId: 2 },
      component: view('CoachSettlementHistory'),
    },
    {
      path: 'coach-center/settlement-history/:settlementId',
      name: 'CoachSettlementDetail',
      meta: { id: 3, subId: -1, coachSubId: 2 },
      component: view('CoachSettlementDetail'),
    },
    {
      path: 'coach-matching',
      name: 'CoachMatching',
      meta: { active: 'recommend', id: 3, subId: 1 },
      component: view('CoachRecommend'),
    },
    {
      path: 'coach/event/invitation/',
      redirect: 'event/invitation',
    },
    {
      path: 'coach/event/invitation/receive',
      redirect: 'event/invitation/receive',
    },
    {
      path: 'coaching/event/invitation',
      redirect: 'event/invitation',
    },
    {
      path: 'event/invitation',
      name: 'CoachEventInvitation',
      meta: { id: 3, subId: 5 },
      component: view('CoachEventInvitation'),
    },
    {
      path: 'coaching/event/invitation/receive',
      redirect: 'event/invitation/receive',
    },
    {
      path: 'event/invitation/receive',
      name: 'CoachEventInvitationReceive',
      meta: { id: 3, subId: 5 },
      component: view('CoachEventInvitationReceive'),
    },
    {
      path: 'coach/:coachId',
      name: 'CoachDetail',
      // .isCoachDetail -> 코치 상세정보 페이지이거나, 해당 페이지의 하위 페이지인지 여부
      meta: { active: 'findCoach', id: 3, subId: 2, coachSubId: 3, isCoachDetail: true },
      component: view('CoachDetail'),
      redirect: 'coach/:coachId/introduction',
      children: [
        {
          path: ':tab(introduction)',
          name: 'CoachDetailIntroduction',
          meta: { id: 3, subId: 2, coachSubId: 3, isCoachDetail: true },
          component: view('coach-detail/CoachDetailIntroduction'),
        },
        {
          path: ':tab(review)',
          name: 'CoachDetailReview',
          meta: { id: 3, subId: 2, coachSubId: 3, isCoachDetail: true },
          component: view('CoachDetailReview'),
        },
        {
          path: ':tab(activity)',
          name: 'CoachDetailActivity',
          meta: { id: 3, subId: 2, coachSubId: 3, isCoachDetail: true },
          component: view('CoachDetailActivity'),
        },
        {
          path: ':tab(class)',
          name: 'CoachDetailClass',
          meta: { id: 3, subId: 2, coachSubId: 3, isCoachDetail: true },
          component: view('coach-detail/CoachDetailClass'),
        },
        {
          path: ':tab(class-edit)',
          name: 'CoachDetailClassEdit',
          meta: { id: 3, subId: 2, coachSubId: 3, isCoachDetail: true },
          component: view('coach-detail/CoachDetailClassEdit'),
        },
      ],
    },
    {
      path: 'news/edit',
      name: 'CoachNewsItemCreate',
      meta: { active: 'news', id: 3, subId: 5, coachSubId: 5 },
      component: view('CoachNewsItemEdit'),
    },
    {
      path: 'news/edit/:newsId',
      name: 'CoachNewsItemEdit',
      meta: { active: 'news', id: 3, subId: 5, coachSubId: 5 },
      component: view('CoachNewsItemEdit'),
    },
    {
      path: 'news/:newsId',
      name: 'CoachNewsItem',
      meta: { active: 'news', id: 3, subId: 5, coachSubId: 5 },
      component: view('CoachNewsItem'),
    },
    {
      path: 'news',
      name: 'CoachNews',
      meta: { active: 'news', id: 3, subId: 5, coachSubId: 5 },
      component: view('CoachNews'),
    },
  ];
  return coachingRoute.map(c => ({ ...c, meta: { ...(c.meta || {}), category: 'coaching' } }));
};
