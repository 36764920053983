<template>
  <div login-required-modal :class="theme">
    <div class="dim" @click="close"></div>
    <div class="panel">
      <div class="header">
        <div class="title-img">
          <svg-thunder-yellow />
        </div>
        <a class="close" @click="close">
          <svg-x />
        </a>
        <p class="title-msg" v-html="$t('_.confirm.title')"></p>
        <p v-html="$t('_.confirm.subtitle')"></p>
        <div class="btn-wrapper">
          <ColorButton class="btn-cancel" @click="close" :type="theme === 'dark' ? 'white' : 'dark-line'">{{ $t('_.confirm.button.cancel') }}</ColorButton>
          <ColorButton class="btn-confirm" @click="resolve" :type="theme === 'dark' ? 'red' : 'dark'" color-only>{{ $t('_.confirm.button.ok') }}</ColorButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgX from '@shared/graphics/svg-x.vue';
import SvgThunderYellow from '@shared/graphics/svg-thunder-yellow.vue';

export default {
  name: 'LoginRequiredModal',
  lexicon: 'shared.member.login',
  components: {
    SvgX,
    ColorButton,
    SvgThunderYellow,
  },
  props: {
    options: {
      type: Object,
      default: () => ({ theme: 'light' }),
    },
  },
  computed: {
    theme() {
      return this.options.theme;
    },
  },
  watch: {
    $route: 'close',
  },
  methods: {
    close() {
      this.resolve(false);
    },
    resolve(flag = true) {
      this.$emit('resolve', flag);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[login-required-modal] { .abs; .f; .flex-center;
  > .dim { .abs; .f; .bgc(@c-b07); .lt; }
  > .panel { .p(20); .w(335); .block; .rel; .br(20); .bgc(#fff); .tc;
    > .header { .p(34, 0, 46); .rel;
      .close { .abs; .rt(0, 0); .z(100);
        svg { .block; }
      }
      .title-msg { .fs(20, 26); .bold; }
      .btn-cancel { border: 1px solid #D5D5DE; }
      [color-button] { .h(48); .br(12); .fs(16); .max-w(140); }
    }
    p {
      .mt(20); .mb(40); .fs(14, 21);
    }
    .btn-wrapper { .flex-list; .space-between; }
  }
  &.light {
    > .panel { .bgc(#fff);
      > .header {
        .close {
          svg {
            .stroke-target { stroke: #191919; }
          }
        }
      }
    }
  }
  &.dark {
    > .panel { .bgc(#161a1d);
      > .header { .c(#fff);
        .close {
          svg {
            .stroke-target { stroke: #fff; }
          }
        }
      }
    }
  }
}
</style>
