<template>
  <modal class-issue-send-modal size="normal" @close="close">
    <template v-slot:title>
      <h2>{{ $t('_.registerIssue') }}</h2>
    </template>
    <div class="contents-holder">
      <div class="class-info">
        <Avatar :info="coachUserInfo" :defaultImgType="'fruit'" userProfile />
        <div class="info">
          <b>{{ nickname }}</b>
          <span>{{ $t('_.classDate', { val: $date(startDateTime, 'datetime') }) }}</span>
          <span>{{ $t('_.classCode', { val: lessonProductOrderId }) }}</span>
        </div>
      </div>
      <ValidationObserver tag="div" class="form-holder" ref="validator">
        <ValidationProvider rules="required|lengthMax:20" name="title" v-slot="{errors}" class="inp-con">
          <label>{{ $t('_.title') }}</label>
          <TextInput :placeholder="$t('_.titlePlaceholder')" v-model="titleInput" />
          <ValidationErrors :errors="errors" />
        </ValidationProvider>
        <ValidationProvider rules="required|length:10,400" name="content" v-slot="{errors}" class="inp-con">
          <TextBox :placeholder="$t('_.contentPlaceholder')" v-model="contentInput" />
          <ValidationErrors :errors="errors" />
        </ValidationProvider>
      </ValidationObserver>
      <div class="btn-holder">
        <color-button type="dark" @click="send">{{ $t('_.send') }}</color-button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import Avatar from '@shared/components/common/Avatar.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import Specific from '@shared/types/Specific';
import TextBox from '@/views/components/common/input/TextBox.vue';

export default {
  name: 'ClassIssueSendModal',
  lexicon: 'chatting.modal',
  components: { ColorButton, TextBox, TextInput, Avatar, Modal },
  props: {
    options: Specific,
  },
  data: () => ({
    titleInput: '',
    contentInput: '',
  }),
  computed: {
    /** @type {ChatLessonInfo} */
    lessonInfo() {
      return this.options ?? {};
    },
    /** @type {CoachUser || {}} */
    coachUserInfo() {
      return this.lessonInfo?.coach?.user ?? {};
    },
    /** @type {string} */
    nickname() {
      return this.coachUserInfo?.nickname ?? '';
    },
    /** @type {string} */
    lessonProductOrderId() {
      return this.lessonInfo?.lessonProductOrderId ?? '';
    },
    /** @type {number} */
    startDateTime() {
      return this.lessonInfo?.startDateTime ?? 0;
    },

  },
  methods: {
    close() {
      this.$emit('close');
    },
    async send() {
      await this.timeoutShield();
      await this.$validate(this.$refs.validator);
      await this.$services.chat.postIssue({ lessonProductOrderId: this.lessonProductOrderId, title: this.titleInput, content: this.contentInput });
      this.$toast('_.issuePostSuccess', { type: 'success' });
      this.close();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[modal] {
  &[class-issue-send-modal] {
    .contents-holder {
      .class-info { .rel; .mb(20); .p(12); .-a(rgba(0, 0, 0, 0.1)); .br(12);
        [avatar] { .abs; .lt(0, 2); .z(1); .wh(48); .m(12); .br(50%); }
        .info { .pl(58);
          b, span { .block; }
          b { .fs(14); }
          span { .fs(12, 14); .c(#a4a3ae); }
        }
      }
      .form-holder {
        .inp-con { .mb(20);
          > label { .block; .mb(4); .fs(13); }
          [text-box] { .h(250); }
          .error { .tr; .fs(12); .c(#ffc70e); }
        }
      }

      .btn-holder { .mt(50); .tc;
        [color-button] { .max-w(240); .wh(100%, 56); .br(60); }
      }
    }
  }
}
</style>
