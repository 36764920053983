<template>
  <label checkbox>
    <input type="checkbox" v-model="boundValue" />
    <span class="checkbox-checkmark"></span>
    <span class="checkbox-label">
      <slot></slot>
    </span>
  </label>
</template>
<script>
export default {
  props: {
    value: {
      type: [Boolean, Array],
      default: false,
    },
  },
  computed: {
    boundValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[checkbox] { .block; .pointer;
  input[type='checkbox'] { user-select: none;.hide;}
  input[type='checkbox']:not(:checked) ~ .checkbox-checkmark {.o(.3)}
  .checkbox-checkmark {.bg('@{img}/icon/icon-checked.svg');.wh(20, 20);display: inline-block;float: left;.mr(6)}
  .checkbox-label {line-height: 21px;display: inline-block;word-break: break-word;.w(calc(100% - 26px))}
}
</style>
