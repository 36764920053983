export const NotificationModal = () => import(/* webpackChunkName: "notification" */ '@shared/components/notification/NotificationModal.vue');
export const WithdrawnModal = () => import(/* webpackChunkName: "login" */ '@shared/components/account/WithdrawnModal.vue');
export const ModalFilterChamp = () => import(/* webpackChunkName: "modalFilterChamp" */ './coaching/filter/ModalFilterChamp.vue');
export const ModalFilterMultiChamp = () => import(/* webpackChunkName: "modalFilterChamp" */ './coaching/filter/ModalFilterMultiChamp.vue');
export const ModalFilterLane = () => import(/* webpackChunkName: "modalFilterLane" */ './coaching/filter/ModalFilterLane.vue');
export const ModalPostQuestion = () => import(/* webpackChunkName: "modalPostQuestion" */'@/views/components/coaching/post-question/ModalPostQuestion.vue');
export const ModalTutor = () => import(/* webpackChunkName: "modalCoachDetail" */'./coaching/modal-tutor/ModalTutor.vue');
export const ModalCoupon = () => import(/* webpackChunkName: "modalCoupon" */'./coaching/modal-coupon/ModalCoupon.vue');
export const ModalChildRepExpire = () => import(/* webpackChunkName: "modalChildRepExpire" */'./coaching/modal-child-rep/ModalChildRepExpire.vue');
export const ModalRecommend = () => import(/* webpackChunkName: "modalRecommend" */'./coaching/modals/ModalRecommend.vue');
export const TopBanner = () => import(/* webpackChunkName "topBanner" */ './coaching/TopBanner.vue');
