const {
  VUE_APP_COOKIE_DOMAIN,
  VUE_APP_COOKIE_DEFAULT_EXPIRES_DAY,
  VUE_APP_COOKIE_USER_KEY,
  VUE_APP_COOKIE_ACCESS_TOKEN_KEY,
  VUE_APP_COOKIE_AUTO_LOGIN_KEY,
  VUE_APP_COOKIE_GNB_GAME_ID_KEY,
  VUE_APP_COOKIE_TIME_DIFF_KEY,
  VUE_APP_COOKIE_CANCEL_LIVE_CARD,
  VUE_APP_COOKIE_SLUG_ID,
  VUE_APP_COOKIE_CHANNEL_UPCOMING_CURSOR,
  VUE_APP_COOKIE_CHANNEL_ARENA_STATE,
  VUE_APP_COOKIE_CHANNEL_FEED_CURSOR,
  VUE_APP_COOKIE_VISITED_GAMES,
  VUE_APP_COOKIE_RIOT_GAME_ID,
} = process.env;

const DEFAULT_EXPIRES_DAY = parseFloat(VUE_APP_COOKIE_DEFAULT_EXPIRES_DAY);
const SESSION_ONLY = 0;

export default class CookieService {
  /** @type {JsCookie} */
  #cookies;

  constructor(cookies) {
    this.#cookies = cookies;
  }

  /**
   * @description
   * 가지고 있는 모든 쿠키의 키를 반환
   * @returns {string[]}
   */
  get keys() {
    return Object.keys(this.#cookies.getJSON());
  }

  /**
   * @description
   * 특정 정규식을 만족하는 모든 key를 찾아서 반환
   * @param {RegExp} regex
   * @returns {string[]}
   */
  findKeys(regex) {
    return this.keys.filter(v => regex.test(v));
  }

  /**
   * @param {JsCookie} cookies
   */
  setCookies(cookies) {
    this.#cookies = cookies;
  }

  /**
   * @param {string} key
   * @param {any} value
   * @param {number} expires
   */
  setCookie(key, value, expires = DEFAULT_EXPIRES_DAY) {
    this.#cookies.set(key, value, { domain: VUE_APP_COOKIE_DOMAIN, path: '/', expires: expires === SESSION_ONLY ? undefined : expires });
  }

  /**
   * @param {string} key
   */
  removeCookie(key) {
    this.#cookies.remove(key, { domain: VUE_APP_COOKIE_DOMAIN, path: '/' });
  }

  /** @returns {string} */
  getUser = () => this.#cookies.get(VUE_APP_COOKIE_USER_KEY);
  /** @returns {string} */
  getToken = () => this.#cookies.get(VUE_APP_COOKIE_ACCESS_TOKEN_KEY);
  /** @returns {boolean} */
  getAutoLogin = () => {
    const autoLogin = this.#cookies.get(VUE_APP_COOKIE_AUTO_LOGIN_KEY, true);
    return autoLogin ? JSON.parse(autoLogin) : false;
  };
  /** @returns {string} */
  getGnbGameId = () => this.#cookies.get(VUE_APP_COOKIE_GNB_GAME_ID_KEY);
  /** @returns {string} */
  getTimeDiff = () => this.#cookies.get(VUE_APP_COOKIE_TIME_DIFF_KEY);
  /** @returns {boolean} */
  getIsCancelLiveCard = () => this.#cookies.get(VUE_APP_COOKIE_CANCEL_LIVE_CARD, true);
  /** @returns {string} */
  getSlugId = () => this.#cookies.get(VUE_APP_COOKIE_SLUG_ID);
  /** @returns {object} */
  getChannelUpComingCursor = () => this.#cookies.get(VUE_APP_COOKIE_CHANNEL_UPCOMING_CURSOR, true);
  /** @returns {object} */
  getChannelArenaState = () => this.#cookies.get(VUE_APP_COOKIE_CHANNEL_ARENA_STATE, true);
  /** @returns {object} */
  getChannelFeedCursor = () => this.#cookies.get(VUE_APP_COOKIE_CHANNEL_FEED_CURSOR, true);
  /** @returns {array} */
  getVisitedGameId = () => {
    const v = this.#cookies.get(VUE_APP_COOKIE_VISITED_GAMES);
    return v && v.length ? v.split(',') : null;
  };
  /** @returns {string} */
  getRiotGnbKey = () => this.#cookies.get('riot-gnb');
  /** @returns {string} */
  getRiotGameId = () => this.#cookies.get(VUE_APP_COOKIE_RIOT_GAME_ID) || 'lol';
  /**
   * @description
   * COG-477: 코치 창 몇 번이나 닫았는지 여부
   * @returns {number}
   * */
  getTutorModalCloseAmount = () => {
    const amount = Number(this.#cookies.get('tutor-close'));
    if (Number.isNaN(amount)) return 0;
    return amount;
  };
  /**
   * @description COG-477 마지막으로 코치 추천 유도 모달 거절한 시기 확인. 없으면 undefined.
   * @returns {undefined | Date}
   */
  getTutorRecommendRejected = () => {
    const recommendReject = this.#cookies.get('tutor-recommend-rejected') ?? '';
    if (recommendReject === '') return;
    return new Date(Number(recommendReject));
  };

  /** @param {string} userId */
  setUser = userId => this.setCookie(VUE_APP_COOKIE_USER_KEY, userId, this.getAutoLogin() ? DEFAULT_EXPIRES_DAY : SESSION_ONLY);
  /** @param {string} token */
  setToken = token => this.setCookie(VUE_APP_COOKIE_ACCESS_TOKEN_KEY, token, this.getAutoLogin() ? DEFAULT_EXPIRES_DAY : SESSION_ONLY);
  /** @param {boolean} value */
  setAutoLogin = value => this.setCookie(VUE_APP_COOKIE_AUTO_LOGIN_KEY, value);
  /** @param {string} gameId */
  setGnbGameId = gameId => this.setCookie(VUE_APP_COOKIE_GNB_GAME_ID_KEY, gameId);
  /** @param {number} timeDiff */
  setTimeDiff = timeDiff => this.setCookie(VUE_APP_COOKIE_TIME_DIFF_KEY, timeDiff);
  /** @param {number} expireDate */
  setCancelLiveCard = expireDate => this.setCookie(VUE_APP_COOKIE_CANCEL_LIVE_CARD, true, expireDate);
  /** @param {string} slugId */
  setSlugId = slugId => this.setCookie(VUE_APP_COOKIE_SLUG_ID, slugId);
  /** @param {object} cursorMap */
  setSessionChannelUpComingCursor = cursorMap => this.setCookie(VUE_APP_COOKIE_CHANNEL_UPCOMING_CURSOR, cursorMap, SESSION_ONLY);
  /** @param {object} state */
  setSessionChannelArenaState = state => this.setCookie(VUE_APP_COOKIE_CHANNEL_ARENA_STATE, state, SESSION_ONLY);
  /** @param {object} cursorMap */
  setSessionChannelFeedCursor = cursorMap => this.setCookie(VUE_APP_COOKIE_CHANNEL_FEED_CURSOR, cursorMap, SESSION_ONLY);
  /** @param {string} gameId */
  setVisitedGameId = gameId => {
    const gameIds = this.getVisitedGameId() || [];
    gameIds.unshift(gameId);
    const filtered = [...new Set(gameIds)].slice(0, 6);

    this.setCookie(VUE_APP_COOKIE_VISITED_GAMES, filtered.join(','));
  };
  setRiotGnbKey = () => this.setCookie('riot-gnb', 1);
  /** @param {string} riotGameId */
  setRiotGameId = riotGameId => this.setCookie(VUE_APP_COOKIE_RIOT_GAME_ID, riotGameId);
  /**
   * @description COG-477 코치 창 닫은 횟수 기록
   * @param {number} amount
   */
  setTutorModalCloseAmount = amount => this.setCookie('tutor-close', amount);
  /** @description COG-477 코치 추천 유도 모달 거부 여부 해당 시간 기준으로 기록 */
  setTutorRecommendRejected = () => this.setCookie('tutor-recommend-rejected', new Date().getTime());
  /**
   * @description
   * 코치 추천 만료 시간 기록
   * typeof RecommendationCoaches.expiredDatetime
   * @param {number} recommendedTime - Date.getTime() 결과물
   * */
  setTutorRecommendExpire = (recommendedTime = -1) => {
    this.setCookie('tutor-recommend-expire', recommendedTime.toString());
  };
  /**
   * @description
   * 코치 추천 만료 시각 회수
   * @returns {Date | null | undefined}
   */
  getTutorRecommendExpire = () => {
    const strExpire = this.#cookies.get('tutor-recommend-expire') ?? '-1';
    if (strExpire === '-1') return;
    return new Date(Number(strExpire));
  };
  /**
   * 코치 추천 여부를 조회했는지 여부
   * @param {boolean} queried
   */
  setTutorRecommendQueried = (queried = false) => {
    this.setCookie('tutor-recommend-queried', queried.toString());
  };
  /**
   * 코치 추천 여부를 조회했는지 여부를 확인
   * @returns {boolean}
   */
  getTutorRecommendQueried = () => this.#cookies.get('tutor-recommend-queried') === 'true';

  removeUser = () => this.removeCookie(VUE_APP_COOKIE_USER_KEY);
  removeToken = () => this.removeCookie(VUE_APP_COOKIE_ACCESS_TOKEN_KEY);
  removeAutoLogin = () => this.removeCookie(VUE_APP_COOKIE_AUTO_LOGIN_KEY);
  removeRiotGnbKey = () => this.removeCookie('riot-gnb');
  removeRiotGameId = () => this.removeCookie(VUE_APP_COOKIE_RIOT_GAME_ID);
  /**
   * @description
   * 상단 배너 닫은 시간 기록
   * @param {number} time
   *  */
  setTopBannerCloseTime = time => this.setCookie('top-banner-close', time);
  /**
   * 상단 배너 닫은 시간 가져오기
   * @return {number} time
   *  */
  getTopBannerCloseTime = () => Number(this.#cookies.get('top-banner-close') ?? '-1');
}
