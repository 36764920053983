<template>
  <button chatting-widget @click="openChattingWidget" v-if="isChattingRoomExist" data-cy="chatting-btn">
    <span class="unread-count" v-show="unReadCount > 0">{{ unReadCount }}</span>
  </button>
</template>

<script>
import { state } from '@shared/utils/storeUtils';

export default {
  name: 'ChattingWidget',
  computed: {
    /** @type {number} */
    unReadCount: state('chat', 'unReadCount'),
    /** @type {SendBird.GroupChannel[]} */
    channelList: state('chat', 'channelList'),
    /** @type {boolean} */
    isChattingRoomExist() {
      return this.channelList.length > 0;
    },
  },
  methods: {
    async openChattingWidget() {
      await this.$services.chat.toggleChatModal(this);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[chatting-widget] { .fix; .wh(60); .bgc(#df3a3a); .br(50%); .r(20); .z(100); bottom: calc(160px + env(safe-area-inset-bottom)) !important;
  &:hover { .bgc(#f57f7f);}
  &:before { .cnt; .abs; .lt(16, 20); .wh(24); .contain('@{icon}/ico_chat_active.svg');}
  .unread-count { .abs; .rt(8, 9); .wh(20); .fs(10, 20); .bgc(#1f2226); .br(10); .c(white); }

  @media (@tp-up) {
    .r(40);
  }
}
</style>
