import { touchUrl } from '@shared/utils/urlUtils';

export default class UserService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ApiConnection} */
  #auth;
  /** @type {ServiceManager} */
  #services;

  /**
   * @param {ServiceManager} services
   */
  constructor(services) {
    this.#api = services.commonApi;
    this.#auth = services.authApi;
    this.#services = services;
  }

  // ------------------------------------ Validation --------------------------------------

  /**
   * 이메일 가입 가능 여부 (이미 등록된 이메일인지) 확인
   * @param {string} email
   * @returns {Promise<boolean>}
   */
  async checkEmailRegistered(email) {
    try {
      await this.#api.get('/users/validate-email', { email });
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * 닉네임 사용 가능 여부 (이미 등록된 닉네임인지) 확인
   * @param {string} nickname
   * @returns {Promise<boolean>}
   */
  async checkNicknameRegistered(nickname) {
    try {
      await this.#api.get('/users/validate-nickname', { nickname });
      return true;
    } catch (e) {
      return false;
    }
  }

  // ------------------------------------ Mypage --------------------------------------

  /**
   * 포인트 내역 조회
   * @param {'UP'} wallet
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<CashTransactionList>}
   */
  getPointHistory(wallet, cursor = 0, size = 10) {
    return this.#api.get('/cash/transactions', { wallet, size, cursor });
  }

  /**
   * 이벤트 내역 조회
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<LotteryResultList>}
   */
  getEventHistory(cursor = 0, size = 10) {
    return this.#api.get('/lottery/results', { size, cursor });
  }

  /**
   * @param {MyInfoUpdate} info
   */
  async updateMyInfo(info) {
    try {
      await this.#api.put('/users/me2', info);
    } catch ({ code }) {
      throw ['account.updateMyInfoFail', code];
    }
  }

  /**
   * @returns {Promise<string[]>}
   */
  async getArenaApplied() {
    return this.#api.get('/users/me/providers/arena-applied');
  }

  /**
   * @param {ConnectedPlatforms} providers
   * @returns {Promise<void>}
   */
  async updatePlatforms(providers) {
    try {
      await this.#api.put('/users/me/providers', { providers });
    } catch ({ code }) {
      throw ['user.platforms.updateFail', code];
    }
  }

  /**
   * @param {string} providers
   * @returns {Promise<LOLSeasonInfo>}
   */
  async refreshGameData({ providers }) {
    try {
      return await this.#api.put(`/users/me/providers/${providers}/refresh`);
    } catch ({ code }) {
      throw ['user.platforms.updateFail', code];
    }
  }

  /**
   * @param {string} platform
   * @param {string} platformId
   * @returns {Promise<void>}
   */
  async connectGameId({ platform, platformId }) {
    try {
      return await this.#api.post(`/users/me/providers/${platform}`, { platformId });
    } catch ({ code }) {
      throw ['user.platforms.updateFail', code];
    }
  }

  /**
   * @param {Vue.Component} context
   * @param {'battlenet'|'pubg_steam'} platform
   * @returns {Promise<PlatformConnectResult>}
   */
  async refreshPlatform(context, platform) {
    try {
      return await this.#auth.post('/api/oauth2/refresh', null, { params: { provider: platform } });
    } catch ({ code }) {
      if (platform === 'battlenet' && code === 'TOKEN_INVALID') {
        return this.connectPlatform(context, platform);
      } throw ['user.platforms.refreshFail', code];
    }
  }

  /**
   * @param {Vue.Component} context
   * @param {'battlenet'|'discord'|'lol_highest_tier'|'steam'} platform
   * @returns {Promise<PlatformConnectResult>}
   */
  #waitPlatformConnected = (context, platform) => new Promise(resolve => {
    const handler = e => {
      if (e.origin !== process.env.VUE_APP_AUTH_URL) return;
      window.removeEventListener('message', handler);
      if (e.data.provider === platform) resolve(e.data);
    };
    window.addEventListener('message', handler);
    context.$once('hook:beforeDestroy', () => window.removeEventListener('message', handler));
  });

  /**
   * @param {Vue.Component} context
   * @param {'battlenet'|'discord'|'lol_highest_tier'|'steam'} platform
   * @returns {Promise<PlatformConnectResult>}
   */
  async connectPlatform(context, platform) {
    if (platform === 'battlenet') await touchUrl('https://www.blizzard.com/logout');
    const token = this.#services.store.getState('auth', 'accessToken');
    const url = `${process.env.VUE_APP_AUTH_URL}/agreement/${platform}?a=${token}`;
    window.open(url, 'auth', 'height=800,width=500,toolbar=no,menubar=no,location=no,status=no');
    return this.#waitPlatformConnected(context, platform);
  }

  // -------------------------------------- User -------------------------------------------
  #userInfoCache = {};
  /**
   * 유저 정보 조회
   * @param {string} userId
   * @returns {Promise<LvupUser>}
   */
  async getUserInfo(userId) {
    const cache = this.#userInfoCache[userId];
    if (cache) return cache;
    try {
      const info = await this.#api.get(`/users/${userId}`);
      this.#userInfoCache[userId] = info;
      return info;
    } catch (e) {
      throw { code: 'NOT_FOUND' };
    }
  }

  /**
   * 유저 lol 시즌정보 조회
   * @param {string} userId
   * @returns {Promise<LOLSeasonInfo>}
   */
  getUserLolSeasonInfo(userId) {
    return this.#api.get('/third-party/lol/season/stats', { userId });
  }
}
