const view = path => () => import(/* webpackChunkName: "term" */ `../views/pages/terms/${path}.vue`);
// noinspection JSUnresolvedVariable
export default () => [
  {
    path: 'privacypage',
    name: 'PrivacyPage',
    component: view('PrivacyTermsPage'),
    meta: {
      ftNavHide: true,
    },
  },
  {
    path: 'termspage',
    name: 'TermsPage',
    component: view('ServiceTermsPage'),
    meta: {
      ftNavHide: true,
    },
  },
];
