<template>
  <Modal coach-lesson-review-modal @close="onClose">
    <template v-slot:title>
      <CoachLessonReviewModalViewHeader />
    </template>
    <template>
      <CoachLessonReviewModalView :info="info" @submit="submit" />
    </template>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import CoachLessonReviewModalViewHeader from '@/views/components/coaching/CoachLessonReviewModalViewHeader.vue';
import CoachLessonReviewModalView from '@/views/components/coaching/CoachLessonReviewModalView.vue';

export default {
  name: 'CoachLessonReviewModal',
  components: { Modal, CoachLessonReviewModalView, CoachLessonReviewModalViewHeader },
  props: {
    options: {
      default: () => ({}),
    },
  },
  computed: {
    info() {
      return /** @type {LessonOrder} */ this?.options?.info || {};
    },
    lessonProductOrderId() {
      return this?.info?.lessonProductOrderId || '';
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async submit({ rating, comment }) {
      await this.$services.coaching.postReview({ lessonProductOrderId: this.lessonProductOrderId, rating, description: comment });
      this.$emit('resolve');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-lesson-review-modal] {
  .panel.large { .max-w(540); .w(100%);}
}
</style>
