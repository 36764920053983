const view = path => () => import(/* webpackChunkName: "coach-my-page-[request]" */ `../views/pages/coaching/${path}.vue`);
const userView = path => () => import(/* webpackChunkName: "coach-my-page-[request]" */ `../views/pages/coaching/coach-my-page/${path}.vue`);
// noinspection JSUnresolvedVariable
/** @typedef {import('vue-router').Route} Route */
/** @return {Route[]} */
export default () => {
  const routes = [
    {
      path: 'me',
      component: view('CoachMyPage'),
      meta: { ftNavHide: true },
      children: [
        {
          path: '/',
          name: 'CoachMyPage',
          redirect: { name: 'CoachUserClasses' },
        },
        {
          path: 'class',
          name: 'CoachUserClasses',
          component: userView('CoachUserClasses'),
        },
        {
          path: 'favorites',
          name: 'CoachUserLikedCoaches',
          component: userView('CoachUserLikedCoaches'),
        },
        {
          path: 'info',
          name: 'CoachUserModify',
          component: userView('CoachUserModify'),
        },
        {
          path: 'payment',
          name: 'CoachUserPoint',
          component: userView('CoachUserPoint'),
        },
        {
          path: 'questions',
          name: 'CoachUserQuestions',
          component: userView('CoachUserQuestions'),
        },
      ],
    },
  ];
  routes[0].children = routes[0].children.map(c => ({ ...c, meta: { ...(c.meta || {}), category: 'user' } }));
  routes.map(c => ({ ...c, meta: { ...(c.meta || {}), category: 'user' } }));
  return routes;
};
