export default () => ({
  namespaced: true,
  state: {
    userId: null,
    accessToken: null,
    refreshToken: null,
    accountLocked: false,
    /** @type {MyInfo} */
    myInfo: null,
    // 사용자 role이 코치일 경우 코치 id
    /** @type {CoachDetail | null} */
    myCoachInfo: null,
  },
  getters: {
    /** @type {boolean} */
    isLogin(state) {
      return !!state.userId;
    },
    /** @type {string[]} */
    roles(state) {
      let { isCoach, isOrganizer } = state.myInfo ?? {};
      isCoach &&= 'coach';
      isOrganizer &&= 'organizer';
      return [isCoach, isOrganizer].filter(role => role);
    },
    /** @type {boolean} */
    isOrganizer(state, getters) {
      return getters.roles.includes('organizer');
    },
    /** @type {boolean} */
    isCoach(state, getters) {
      return getters.roles.includes('coach');
    },
    /** @type {string} */
    nickname(state) {
      return state?.myInfo?.nickname ?? '';
    },
    /** @type {string} */
    userId(state) {
      return state?.userId ?? '';
    },
    myCoachId(state) {
      return state?.myCoachInfo?.coachId ?? '';
    },
  },
  mutations: {
    setUserToken(state, { userId, accessToken, refreshToken }) {
      state.userId = userId;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    updateToken(state, { accessToken, refreshToken }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    clearUserToken(state) {
      state.userId = null;
      state.accessToken = null;
      state.refreshToken = null;
    },
    accountLock(state) {
      state.accountLocked = true;
    },
    setMyCoachInfo(state, coachInfo) {
      state.myCoachInfo = coachInfo;
    },
    setMyInfo(state, userInfo) {
      state.myInfo = userInfo;
    },
    clearMyInfo(state) {
      state.myInfo = null;
      state.myCoachInfo = null;
    },
  },
});
