<template>
  <button primary-button @click="click" :class="`${noGradient ? 'color' : 'gradient'}-${color}`">
    <span><slot></slot></span>
    <i v-for="{style, sq} in ripples" class="ripple" :key="sq" :style="style"></i>
  </button>
</template>

<script>
import { sleep } from '@shared/utils/commonUtils';

export default {
  name: 'PrimaryButton',
  props: {
    color: {
      type: String,
      default: 'purple',
    },
    noGradient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sq: 0,
      ripples: [],
    };
  },
  methods: {
    click(e) {
      this.addRipple(e);
      this.$emit('click');
    },
    async addRipple(e) {
      const rect = this.$el.getBoundingClientRect();
      this.ripples.push({
        sq: this.sq++,
        style: { left: `${e.x - rect.left}px`, top: `${e.y - rect.top}px`, '--material-scale': rect.width },
      });
      await sleep(500);
      this.ripples.splice(0, 1);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[primary-button] { .p(8,12); .rel; .crop; .-a; .t-x(0);
  span { .rel; .z(1); }
  .ripple { .wh(2,2); .block; .abs; .br(50%); .bgc(@c-w01); animation: materialRipple 0.5s linear; .z(2); }
  each(@gradients, {
    @color : @value[@color];
    &.gradient-@{color} { .rel; .c(@value[@text]);
      background-image: linear-gradient(@value[@degree], @value[@start], @value[@end] 100%);
      &:after { .cnt; .abs; .wh(100%,0); .lt(50%,50%); .o(0); .bgc(@value[@start]);
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        transition: all 0.5s;
      }
      @{hover} {
        &:after { .h(550%); .o(1); }
      }
      &:disabled, &.disabled, &.dragged {
        background-image: linear-gradient(@value[@degree], @value[@disableStart], @value[@disableEnd] 100%);
      }
      &:disabled{
        color: @value[@disableText];
        @{hover-press} { .o(.5);
          &:after { .o(0); }
        }
      }
    }
  });

  each(@btn-colors, {
    @color : @value[@color];
    &.color-@{color} { .bgc(@value[@bg]); .c(@value[@text]);
      @{hover-press} {
        .bgc(@value[@hoverBg]);
      }
      &:disabled, &.disabled {
        .o(0.5);
      }
    }
  });
}
</style>
