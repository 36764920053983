<template>
  <div chatting-list>
    <div class="list-header">
      <h4 class="title">{{ $t('_.chattingList') }}</h4>
      <span>{{ $t('_.noReadMsg', { count: filterUnReadCount }) }}</span>
    </div>
    <ul class="contents scroll-area">
      <li v-for="(item, index) in groupChannelList" :key="item.url">
        <chatting-item :info="groupChannelList[index]" @open="$emit('open', item)" />
      </li>
    </ul>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils';
import ChattingItem from '@/views/components/chatting/ChattingItem.vue';

export default {
  name: 'ChattingList',
  lexicon: 'chatting.list',
  components: { ChattingItem },
  props: {
    info: Specific,
  },
  computed: {
    /** @type {SendBird.GroupChannel[]} */
    groupChannelList() {
      if (!this.info) return [];
      return [...this.info].sort((a, b) => {
        const latestMessageTime = ch => ch?.lastMessage?.createdAt || ch?.invitedAt;
        return latestMessageTime(b) - latestMessageTime(a);
      });
    },
    /** @type {number} */
    unReadCount: state('chat', 'unReadCount'),
    /** @type {string} */
    filterUnReadCount() {
      return this.unReadCount > 999 ? `999+` : `${this.unReadCount}`;
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[chatting-list] { .w(100%); .h(100%); .p(20, 12); .bgc(white); .br(20); .br-b(0);
  .list-header { .mb(12);
    .title { .ib; .mr(5); .fs(14); .bold; .c(#787781);}
    > span { .fs(12); .c(#a4a3ae);}
  }
  .contents { height: calc(100vh - 100px); .min-h(70); .pb(20); }

  @media (@tl-up) {
    height: calc(100vh - 450px); .br(20);
    .contents { .h(100%); }
  }
  @media (@ds-up) {
    .contents { height: calc(100vh - 520px); }
  }
}
</style>
