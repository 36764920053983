<template>
  <modal link-copy-modal @close="close" theme="dark" size="medium">
    <div class="link-wrapper">
      <input :value="url" disabled />
      <button @click="copyUrl" />
    </div>
    <p v-t:_.linkCopyInfo />
    <color-button type="red" @click="close">확인</color-button>
  </modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import { copyClipboard } from '@shared/utils/commonUtils';

export default {
  name: 'LinkCopyModal',
  lexicon: 'chatting.modal',
  components: { Modal, ColorButton },
  computed: {
    /** @type {string} */
    url() {
      return `${window.location.origin}/arena/${this.$route.params.arenaId}/info`;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    copyUrl() {
      copyClipboard(this.url);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[link-copy-modal] { .tc;
  > .panel.medium { .pb(20);
    > .header { .pt(20);
      > .close { .hide() }
    }
    > .scroll-area { .p(0, 20); }
  }
  .link-wrapper { .rel;
    > input { .wh(100%, 40); .mb(12); .mt(20); .c(#e5bb30); .p(0, 12); .bgc(rgba(0, 0, 0, 0.2)); border: none; .-b(rgba(255, 255, 255, 0.1)); .fs(14); }
    > button { .abs; .rt(8, 28); .o(0.3);
      &:before { .cnt; .wh(24); .contain('@{icon}/ico_copy_clipboard.svg') }
    }
  }
  p { .c(rgba(255, 255, 255, 0.8)); }
  [color-button] { .mt(32); .wh(136, 40); }
}
</style>
