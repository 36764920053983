export default () => ({
  namespaced: true,
  state: {
    /** @type {GameInfo[]} */
    games: null,
    /** @type {Object.<string, GameInfo>} */
    gamesByKey: null,
    /** @type{?string} */
    referer: '',
    /** @type{?string} */
    previousQuery: '',
    /** @type{CoachTopBanner[]} */
    topBanners: [],
    topBannerClosed: false,
  },
  getters: {
    game: state => gameId => state.gamesByKey?.[gameId],
    /** @type {Boolean} */
    hasTopBanner: state => state.topBanners?.length >= 1 && !state.topBannerClosed,
  },
  mutations: {
    setGames(state, games) {
      state.games = games;
      state.gamesByKey = games.reduce((acc, cu) => {
        acc[cu.id] = cu;
        return acc;
      }, {});
    },
    setReferer(state, newReferer) {
      state.referer = newReferer;
    },
    setPreviousQuery(state, query) {
      state.previousQuery = query;
    },
    setTopBanners(state, topBanners) {
      state.topBanners = topBanners;
    },
    setTopBannerClosed(state, closed = false) {
      state.topBannerClosed = closed;
    },
  },
});
