const view = path => () => import(/* webpackChunkName: "misc" */ `../views/pages/misc/${path}.vue`);
export default () => [
  {
    path: 'leave-greeting',
    name: 'LeaveGreeting',
    component: view('LeaveOutGreeting'),
  },
  {
    path: '/resetpw/newpassword',
    name: 'ResetPassword',
    component: view('ResetPassword'),
  }
];



