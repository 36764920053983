/* global PaypleCpayAuthCheck */
import { touchUrl } from '@shared/utils/urlUtils';
const PAYPLE_AUTH_FILE = process.env.VUE_APP_PAYPLE_AUTH_FILE;
async function loadPaypleScript() {
  if(typeof window ==='undefined') return;

  const scripts = document.getElementsByTagName('script');
  for (var i = scripts.length; i--;) {
    if (scripts[i].src.includes("cpay.payple.1.0.1.js")) return;
  }

  if (process.env.NODE_ENV === 'development') {
    await touchUrl('https://democpay.payple.kr/js/cpay.payple.1.0.1.js');
  } else {
    await touchUrl('https://cpay.payple.kr/js/cpay.payple.1.0.1.js');
  }
}

export async function payple({ billingId = '', price = '', productName = '', payerNo = 0, payerName = '', payerCellphone = '', payerEmail = '', onFinish = null } = {}) {
  await loadPaypleScript();

  if (!billingId || !price || !productName) throw Error('payple param');

  const args = {
    PCD_CPAY_VER: '1.0.1',
    PCD_PAY_TYPE: 'card',
    PCD_PAY_WORK: 'CERT',
    PCD_CARD_VER: '01', /** 빌링 키 결제 */
    PCD_SIMPLE_FLAG: 'N', /** 간편결제 구분 */
    payple_auth_file: PAYPLE_AUTH_FILE, /** 가맹점 인증 요청 */
    PCD_PAY_OID: billingId,
    PCD_PAYER_NO: payerNo,
    PCD_PAYER_NAME: payerName,
    PCD_PAYER_HP: payerCellphone,
    PCD_PAYER_EMAIL: payerEmail,
    PCD_PAY_GOODS: productName,
    PCD_PAY_TOTAL: price,
    PCD_PAY_ISTAX: 'Y',
    PCD_PAY_TAXTOTAL: 10,
    callbackFunction: onFinish,
    PCD_RST_URL: '/',
  };
  await PaypleCpayAuthCheck(args);
}
