<template>
  <component :is="modalComponent" @close="$emit('close')" ref="modal" modal-child-rep-input class="custom-form">
    <template #title>
      <h1 class="modal-title">법정대리인 결제 동의</h1>
    </template>
    <!-- eslint-disable-next-line -->
    <p class="description-top">만 19세 미만이 유료 서비스를 이용하기 위해서는 부모님 혹은 보호자(법정대리인)가 회원님의 결제를 허락한다는 동의가 있어야 합니다.<br /> 인증과정에서 선택하신 동의 기간 내 재인증 없이 결제가 가능하며, 결제 내역이 등록하신 이메일로 발송됩니다.</p>
    <hr />
    <validation-observer>
      <validation-provider rules="required|email" name="email" v-slot="{errors, passed}">
        <label>
          <span class="form-label">이메일</span>
          <TextInput placeholder="이메일" type="text" :error="!!errors.length" :class="{passed, error: !!errors.length}" v-model="email" />
          <validation-errors :errors="errors" />
        </label>
        <ValueWatch :value="passed" @change="v => emailPassed = v" />
      </validation-provider>
    </validation-observer>
    <p class="form-description" style="margin-bottom:24px;">등록하신 이메일 주소로 만19세 미만 회원님의 결제내역이 발송됩니다</p>
    <label class="form-label">동의 기간 선택</label>
    <div class="radio-wrap">
      <radio-group-em :options="durationOptions" v-model="duration" />
      <p class="duration-info">동의 기간: {{ textDuration }}</p>
    </div>

    <p class="description-checkboxes">법정대리인은 만 19세 미만인 회원과 나이차가 만 18세 이상인 성인이어야 합니다.<br /> 동의철회를 원하시는 경우 레벨업코칭 고객센터로 연락 주시기 바랍니다.</p>
    <checkbox v-model="agree1">본인은 만 19세 미만 이용자의 법정대리인임을 확인하며, 해당 이용자의 서비스 및 유료결제 이용에 동의합니다.</checkbox>
    <checkbox v-model="agree2">빅픽처인터렉티브가 법정대리인의 개인정보를 수집 및 이용하는 것에 동의합니다.</checkbox>
    <template #floating>
      <primary-button class="btn-modal last-confirm" color="red" :disabled="!emailPassed || !allAgree" @click="submit">법정대리인 휴대폰 인증하기</primary-button>
    </template>
  </component>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import { addDuration } from '@shared/utils/timeUtils';
import RadioGroupEm from '@/views/components/coaching/RadioGroupEm.vue';
import Checkbox from '@/views/components/coaching/Checkbox.vue';
import ValueWatch from '@/views/components/coaching/ValueWatch.vue';

export default {
  components: { RadioGroupEm, PrimaryButton, TextInput, Checkbox, ValueWatch },
  data: () => ({ duration: 1, email: '', agree1: false, agree2: false, emailPassed: false }),
  props: {
    modalComponent: {
      type: Object,
      default: () => Modal,
    },
    options: {
      type: Object,
      default: () => ({
        duration: 1,
        email: '',
        agree1: false,
        agree2: false,
      }),
    },
  },
  computed: {
    durationOptions() {
      return [...[1, 3, 6].map(this.getOptionItem), { label: '12개월', value: 12 }];
    },
    textDuration() {
      const now = new Date().getTime();
      const startText = this.$date(now, 'dot');
      const endDate = addDuration(now, { months: this.duration });
      const endText = this.$date(endDate, 'dot');
      return `${startText} ~ ${endText}`;
    },
    allAgree() {
      return this.agree1 && this.agree2;
    },
  },
  methods: {
    getOptionItem(value) {
      const label = this.$duration({ months: value });
      return { label, value };
    },
    submit() {
      this.$emit('resolve', { email: this.email, periodMonth: this.duration });
    },
  },
  beforeDestroy() {
    if (this?.refs?.modal?.close) this.$refs.modal.close();
  },
};
</script>
<style lang="less">
@import '~@/less/coaching.less';
[modal-child-rep-input] {.noto;
  .modal-title {.c(@c-title-black);.fs(24);.lh(24);text-align: center;.mb(24)}
  .scroll-area {max-height: 60vh !important;}
  hr {.mt(24);.mb(24)}
  .description-top {.c(@c-title-black);.fs(14);.lh(20.72);}
  [radio-group-em] .radio-item label {.bgc(#fff);}
  .radio-wrap {.bgc(#ebebf0);.p(20);.br(12);.mb(24);
  }
  .duration-info {text-align: center;.mt(20);.c(#df3a3a);.fs(14);}
  .description-checkboxes {.mt(24);.mb(20);.fs(14);.lh(20);}
  [checkbox] .checkbox-label {.c(#787781);.fs(14);.lh(20);}
  [checkbox] {.mb(20)}
}
</style>
