<template>
  <!-- radio-emphasized의 약어로 radioem 선택 -->
  <!-- 수정할 일이 생길 경우 최대한 웹 접근성과 vue 공식 문서의 바인딩 방식을 고려할 것 -->
  <div radio-group-em :class="[`theme-${theme}`, horizontal && 'horizontal']">
    <div v-for="option in options" :key="`${id}-${option.value}`" class="radio-item">
      <input :id="`${id}-${option.value}`" type="radio" :value="option.value" v-model="boundValue" :name="id" :disabled="option.disabled || disabled || false" />
      <label :for="`${id}-${option.value}`">
        <slot :option="option">{{ option.label }}</slot>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'default-id',
    },
    theme: {
      type: String,
      default: 'base',
    },
    options: {
      // { label, value, disabled },
      type: Array,
      default: () => [],
    },
    value: {
      type: [Number, String, Boolean],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    boundValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    hasSlot() {
      return (this.$slots.default ?? []).length >= 1;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[radio-group-em] {
  // 테마
  &.theme-base {.flex;
    & > .radio-item {.h(40);display: inline-block;.rel;}
    input[type='radio'] ~ label {.wh(100%, 100%);border-width: 1px;.br(4);border-style: solid;display: inline-flex;.fs(14);.flex-jc(center);.flex-ai(center);.pointer;.abs;
    }
    input[type='radio']:checked ~ label {border-color: #000;color: #000;.bold;}
    input[type='radio']:not(:checked) ~ label {border-color: @c-weak-gray;color: @c-weak-gray;}
    @media (min-width: 1920px) {
      & > .radio-item {flex: 1;}
      .flex-gap(8px)
    }
    // 모바일
    @media (max-width: 1919px) {
      .flex-wrap;
      & > .radio-item {.w(50%);
        &:nth-of-type(2n) {.ml(8);.w(calc(50% - 8px))}
        &:nth-of-type(1), &:nth-of-type(2) {.mb(8)}
      }
    }
  }
  &.theme-radio-blue {.flex;flex-direction: column;
    input[type='radio'] ~ label {.rel;.pl(28);display: inline-block;.min-h(23);.lh(23.17);.fs(16);font-weight: 400;
      &:before {content: '';.wh(20);.br(50%);.abs;.lt(10, 50%);transform: translate(-50%, -50%);}
    }
    input[type='radio']:not(:checked):not(:disabled) ~ label {
      cursor: pointer;
      &:before {.bg('@{img}/coaching/radio-default.svg')}
      @media (pointer: fine) {
        // 마우스 사용하는 환경에서만 hover 효과 적용
        &:hover:before {.bg('@{img}/coaching/radio-hover.svg')}
      }
    }
    input[type='radio']:not(:checked):not(:disabled):focus ~ label {
      &:before {.bg('@{img}/coaching/radio-focus.svg')}
    }
    input[type='radio']:disabled ~ label {
      &:before {.bg('@{img}/coaching/radio-disable.svg')}
    }
    input[type='radio']:checked ~ label {
      &:before {.bg('@{img}/coaching/radio-select.svg')}
      @media (pointer: fine) {
        // 마우스 사용 환경에서만 hover 효과 적용
        &:hover:before {.bg('@{img}/coaching/radio-hover-select.svg')}
      }
      .bold;
    }
    &.horizontal {flex-direction: row;
      .radio-item + .radio-item {.ml(10)}
    }
  }
}
</style>
