// 코치 소식 관련 서비스
export default class NewsService {
    /** @type {ApiConnection} */
    #commonApi;
    /** @type {ApiConnection} */
    #api;
    /** @type {ServiceManager} */
    #services;

    /**
     * @param {ServiceManager} services
     */
    constructor(services) {
        this.#commonApi = services.commonApi;
        this.#api = services.coachingApi;
        this.#services = services;
    }

    /**
     * @description
     * 코치 소식 목록 조회. 어드민 권한 유저일 경우 내용이 다름.
     * @param {number} cursor
     * @param {string} order
     * @param {CoachNewsCategory | ''} category
     * @param {string} q
     * @returns {Promise<PagedCoachNews>}
     */
    async getNews({ size = 10, cursor = 0, order = 'insertDatetime desc', category = '', q = '' }) {
        const query = { order, cursor, size };
        if (category !== '' || q) {
            query.q = [`category eq ${category}`, q].filter(q => q).join(' and ');
        }
        if (order) query.order = order;
        return this.#api.get('/v1/news', query);
    }

    /**
     * @description
     * 코치 소식 단건 조회. 어드민 권한에서만 조회되는 뉴스 있음.
     * @param {string} newsId
     * @returns {Promise<CoachNews>}
     */
    getNewsDetail(newsId) {
        return this.#api.get(`/v1/news/${newsId}`);
    }

    /**
     * @description
     * 코치 소식 생성
     * @param {CoachNewsArgs} newsInfo
     * @returns {Promise<CoachNews>}
     */
    createNews(newsInfo) {
        return this.#api.post('/v1/news', newsInfo);
    }

    /**
     * @description
     * 코치 소식 수정
     * @param {CoachNewsArgs} newsInfo
     * @return {Promise<CoachNews>}
     */
    modifyNews(newsInfo) {
        return this.#api.put(`/v1/news`, newsInfo);
    }

    /**
     * @description
     * 코치 소식 삭제
     * @param {string} newsId
     * @return {Promise<void>}
     */
    removeNews(newsId) {
        return this.#api.delete(`/v1/news/${newsId}`);
    }
}
