import { copyClipboard } from '@shared/utils/commonUtils';
import { ModalPostQuestion } from '@/views/components/LazyLoadings';

const host = process.env.VUE_APP_HOME_URL;

export default {
  computed: {
    sharedBoardCommentId() {
      return this.$route?.query?.boardCommentId || '';
    },
  },
  methods: {
    _resolvePath(target) {
      const router = this.$router.resolve(target);
      const url = router?.href || '';
      return [host, url].join('');
    },
    getQuestionUrl(boardWriteId) {
      return this._resolvePath({ name: 'CoachQuestion', params: { boardWriteId } });
    },
    shareQuestion(boardWriteId) {
      copyClipboard(this.getQuestionUrl(boardWriteId));
      this.$toast('coaching.COACH_URL_COPIED_TO_CLIPBOARD');
    },
    getAnswerUrl(boardWriteId, boardCommentId) {
      return this._resolvePath({ name: 'CoachQuestion', params: { boardWriteId }, query: { boardCommentId } });
    },
    shareAnswer(boardWriteId, boardCommentId) {
      const answerUrl = this.getAnswerUrl(boardWriteId, boardCommentId);
      copyClipboard(answerUrl);
      this.$toast('coaching.COACH_URL_COPIED_TO_CLIPBOARD');
    },
    openQuestion(boardWriteId) {
      this.routeName('CoachQuestion', { gameId: 'lol', boardWriteId });
    },
    async postQuestion() {
      if (!this.$store.getters['auth/isLogin']) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }
      const userNickname = this.$store.state.auth?.myInfo?.nickname || '';
      const questionBody = await this.$modal(ModalPostQuestion, { userNickname });
      const question = await this.$services.coaching.postQuestion(questionBody);
      this.openQuestion(question?.boardWriteId);
    },
    openTutor(tutorId) {
      return this.$router.push(this.getCoachPageRoute(tutorId));
    },
    /**
     * @description
     * vue router에 사용할 수 있는 location object 반환
     * @param {string} coachId
     */
    getCoachPageRoute(coachId) {
      return /** @type{import('vue-router').RawLocation} */ { name: 'CoachDetail', params: { coachId } };
    },
    /**
     * @param {string} coachId
     * @returns {string}
     */
    getCoachPageUrl(coachId) {
      const route = this.getCoachPageRoute(coachId);
      return this.$router.resolve(route).href;
    },
    shareCoachUrl(coachId) {
      const route = this.getCoachPageRoute(coachId);
      const coachUrl = this._resolvePath(route);
      copyClipboard(coachUrl);
      this.$toast('coaching.COACH_URL_COPIED_TO_CLIPBOARD');
    },
  },
};
