<template>
  <div chatting-item @click="$emit('open')">
    <div :class="{'avatar-holder': true, 'alert-noti': isNeedRosterCheckOrCheckin}">
      <CdnImg v-if="isArenaApply" :src="itemInfoTable.imgInfo" />
      <Avatar v-else-if="isCoachDm" :defaultImgType="'fruit'" :info="itemInfoTable.imgInfo" userProfile />
      <img v-else-if="isCoachSystem" :src="itemInfoTable.imgInfo" alt="lvup" class="admin" />
    </div>
    <div class="detail-holder">
      <p class="name">{{ itemInfoTable.title }} <i v-if="isCoachDm" :class="['state', {'online': itemInfoTable.online}]" />
      </p>
      <p class="content">{{ itemInfoTable.message }}</p>
    </div>
    <div class="detail">
      <em class="date">{{ itemInfoTable.lastMessageTime }}</em>
      <span class="unread" v-if="itemInfoTable.unReadMessageCount > 0">{{ itemInfoTable.unReadMessageCount }}</span>
    </div>
  </div>
</template>

<script>
import { getter, state } from '@shared/utils/storeUtils';
import Specific from '@shared/types/Specific';
import Avatar from '@shared/components/common/Avatar.vue';
import CdnImg from '@shared/components/common/CdnImg.vue';

export default {
  name: 'ChattingItem',
  components: { Avatar, CdnImg },
  props: {
    info: Specific,
  },
  computed: {
    /** @type {string} */
    myNickname: getter('auth', 'nickname'),
    /** @type {Object.<string, GameInfo>} */
    gamesByKey: state('info', 'gamesByKey'),
    /** @type {SendBird.GroupChannel | {}} */
    groupChannelInfo() {
      return this.info ?? {};
    },
    /** @type {ChatType | ''} */
    customType() {
      return this.groupChannelInfo?.customType ?? '';
    },
    /** @type {boolean} */
    isArenaApply() {
      return this.customType === 'ARENA_APPLY';
    },
    /** @type {boolean} */
    isCoachDm() {
      return this.customType === 'COACH_DM';
    },
    /** @type {boolean} */
    isCoachSystem() {
      return this.customType === 'COACH_SYSTEM';
    },
    /** @type {ChatArenaData} */
    arenaData() {
      return JSON.parse(this.groupChannelInfo?.data || '{}');
    },
    /** @type {UserMessage} */
    lastMessage() {
      return this.groupChannelInfo?.lastMessage;
    },
    /** @type {?Member} */
    coachInfo() {
      if (this.isCoachDm) return this.groupChannelInfo?.members.filter(member => member.nickname !== this.myNickname)[0];
      return null;
    },
    /** @type {{ imgInfo: string | Member, title: string, online?: boolean, lastMessageTime: string, unReadMessageCount: number, message: string} | {}} */
    itemInfoTable() {
      const data = {
        ARENA_APPLY: {
          imgInfo: this.gamesByKey[this.arenaData?.gameId]?.images?.colorIcon,
          title: this.groupChannelInfo?.name ?? '',
        },
        COACH_DM: {
          imgInfo: this.coachInfo,
          title: this.coachInfo?.nickname ?? '',
          online: this.coachInfo?.connectionStatus === 'online',
        },
        COACH_SYSTEM: {
          imgInfo: '/img/icon/ico_lvupggTv.png',
          title: '게임코치 온라인',
          online: false,
        },
      };
      return {
        ...data[this.customType],
        lastMessageTime: this.$fromNow(this.groupChannelInfo?.lastMessage?.createdAt || this.groupChannelInfo?.invitedAt),
        unReadMessageCount: this.groupChannelInfo?.unreadMessageCount ?? 0,
        message: this.lastMessage?.message ?? '',
      };
    },
    isNeedRosterCheckOrCheckin() {
      return this.myUserId === this.arenaData.leaderId ? this.arenaData.progressType === 'PENDING' : (this.arenaData?.isAvailableCheckIn && !this.arenaData?.playoffCheckInAt);
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[chatting-item] {.rel; .wh(100%, 68); .p(10, 12); .bgc(white); .-a(#ebebf0); .br(12); .mb(5);
  &:hover { .-a(black); .pointer;}
  .avatar-holder { .abs; .lt(12, 10); .z(1);
    > img { .w(48); .bgc(black); .br(8); }
    .admin { .br(50%); }
    [avatar] { .wh(48); .vam; .br(50%); .bgc(black); }
    &.alert-noti {
      &:before { .abs; .z(2); .rb(-4, 0); .cnt; .wh(14); .contain('@{icon}/ico_chat_non_certi.svg'); }
    }
  }
  .detail-holder { .pl(58); .pr(55); .h(48);
    .name { .fs(14); .c(#191919); .mb(4); .bold; .ellipsis(1);
      .state { .wh(8); .m(0, 4, 3, 4); .vam; .ib; .br(8); .bgc(#d5d5de);
        &.online {.bgc(#00d6b0);}
      }
    }
    .content { .c(#787781); .fs(12); .ellipsis(1);}
  }
  .detail { .abs; .z(1); .rt(0, 0); .m(12); .fs(11); .c(#a4a3ae);
    .unread { .abs; .z(1); .rb(0, -22); .min-w(17); .h(17); .p(0, 2); .block; .tc; .fs(10, 16px); .c(white); .br(9); background: linear-gradient(135deg, rgba(223, 58, 58), rgba(255, 144, 144));}
  }
}

</style>
