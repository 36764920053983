import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export default {
    install(Vue, { router }) {
        Sentry.init({
            Vue,
            release: "coaching-version@" + process.env.VUE_APP_VERSION,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    tracingOrigins: ["localhost", "*.gamecoach.pro", /^\//],
                }),
            ],
            tracesSampleRate: 1.0,
        });
    }
}
