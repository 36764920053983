import Vue from 'vue';
import _get from 'lodash/get';
import { payple } from '@/modules/Payple';
import { checkTossPaymentsResultRedirection, tossPayments } from '@/modules/TossPayments';
import CoachConfirmModal from '@/views/components/coaching/CoachConfirmModal.vue';
import CoachReverificationModal from '@/views/components/coaching/CoachReverificationModal.vue';
import {
  PAYMENT_METHOD_CREDIT,
  PAYMENT_METHOD_CULTURE_GIFT_CARD,
  PAYMENT_METHOD_GAME_GIFT_CARD,
  PAYMENT_METHOD_BOOK_GIFT_CARD,
  PAYMENT_METHOD_TRANSFER,
  PROVIDER_PAYPLE,
  PROVIDER_TOSS_PAYMENTS,
} from '@/constants/payment';
import { ModalChildRepExpire } from '@/views/components/LazyLoadings';
import ModalChildRepInput from '@/views/components/coaching/modal-child-rep/ModalChildRepInput.vue';
import CoachExpiredTokenModal from '@/views/components/coaching/CoachExpiredTokenModal.vue';

export const paymentMethods = [
  { key: PAYMENT_METHOD_CREDIT, text: '신용카드 결제', icon: 'icon-credit.svg', provider: PROVIDER_PAYPLE },
  { key: PAYMENT_METHOD_CULTURE_GIFT_CARD, text: '문화상품권(컬쳐랜드)', icon: 'icon-gift-card.svg', provider: PROVIDER_TOSS_PAYMENTS },
  { key: PAYMENT_METHOD_GAME_GIFT_CARD, text: '게임문화상품권(스마트문상)', icon: 'icon-gift-card.svg', provider: PROVIDER_TOSS_PAYMENTS },
  { key: PAYMENT_METHOD_BOOK_GIFT_CARD, text: '도서문화상품권(북앤라이프)', icon: 'icon-gift-card.svg', provider: PROVIDER_TOSS_PAYMENTS },
  { key: PAYMENT_METHOD_TRANSFER, text: '실시간 계좌이체', icon: 'icon-financial-account.svg', provider: PROVIDER_TOSS_PAYMENTS },
];

class Payment {
  /** @type{import('Vue') | null} */
  app = null;

  constructor() {
    this.tossPayments = tossPayments.bind(this);
    this.payple = payple.bind(this);
    this.afterHandler = null;
  }

  setApp(app) {
    this.app = app;
  }

  setAfterHandler(func) {
    this.afterHandler = func;
  }

  paymentFuncWrap = (func, ...args) => {
    try {
      return func(...args);
    } catch (e) {
      /** handle payment error */
      return this.failHandler();
    }
  };

  getProvider = (paymentMethod) => {
    const paymentMethodItem = paymentMethods.find(m => m.key === paymentMethod);
    return paymentMethodItem?.provider;
  };

  purchasePoint = (productId, paymentMethod) => {
    return this.paymentFuncWrap(this._purchasePoint, productId, paymentMethod);
  };

  _purchasePoint = async (productId, paymentMethod) => {
    const provider = this.getProvider(paymentMethod);
    if (!provider) throw Error('none provider');
    let err;
    const billingResult = /** @type {Billing} */ await this.app.$services.coaching.makeBilling(productId, provider).catch(e => err = e);

    if (err?.code === 'UNAUTHORIZED') {
      this.app.$modal(CoachExpiredTokenModal);
      return;
    }

    if (err?.code === 'USER_VERIFICATION_REQUIRED') {
      this.app.$modal(CoachReverificationModal);
      return;
    }

    if (err?.code === 'LEGAL_REPRESENTATIVE_REQUIRED' || err?.code === 'LEGAL_REPRESENTATIVE_EXPIRED') {
      if (err?.code === 'LEGAL_REPRESENTATIVE_EXPIRED') {
        const repInfo = await this.app.$services.coaching.getRepresentativeInfo();
        try {
          await this.app.$modal(ModalChildRepExpire, repInfo);
        } catch (err) { // 확인 버튼을 누르지 않고 그냥 닫았을 때
          return;
        }
      }
      // this.app.$router.push({ name: 'ReverificationRepresentative' });
      const { email, periodMonth } = await this.app.$modal(ModalChildRepInput);
      const impUid = await this.app.$services.auth.getImpToken();
      await this.app.$services.coaching.agreeRepresentative({ impUid, periodMonth, email });
      return;
    }

    const { billingId, resultPrice, name: productName } = billingResult;
    const gameId = _get(this.$router, 'params.gameId');

    if (provider === PROVIDER_PAYPLE) {
      await this.payple({ billingId, price: resultPrice, productName, onFinish: this.confirmPayple.bind(this) });
    } else if (provider === PROVIDER_TOSS_PAYMENTS) {
      await this.tossPayments({ billingId, price: resultPrice, productName, paymentMethod, gameId });
    }
  };

  async tossPaymentsRouteHandler(to) {
    try {
      const query = checkTossPaymentsResultRedirection(to);
      await this.confirmTossPayments(query);
    } catch (err) {
      if (err.name === 'NO_TOSS') return;
      console.warn(err);
      await this.failHandler();
    }
  }

  confirmPayple = async (responseData) => {
    if (responseData?.PCD_PAY_RST === 'close') {
      await this.failHandler();
      return null;
    }
    const billingId = responseData?.PCD_PAY_OID;

    await this.app.$services.coaching.confirmPayple({ billingId, responseData });
    await this.successHandler();
  };

  confirmTossPayments = async ({ billingId, orderId, amount, paymentKey }) => {
    const itv = setInterval(async () => {
      if (!this.app) return;
      clearInterval(itv);
      await this.app.$services.coaching.confirmTossPayments({ billingId, orderId, amount, paymentKey });
      await this.app.$router.replace(this.app.$route.path);
      await this.successHandler();
    }, 600);
  };

  successHandler = async () => {
    await this.app.$services.auth.fetchMyInfo();
    const title = this.app.$t('coaching.PT_store_finish');
    const content = this.app.$t('coaching.PC_store_finish');
    const options = { title, content };
    await this.app.$modal(CoachConfirmModal, options);

    /** GA 전자상거래 추적코드 */
    Vue.prototype.$gtag.event('success_point_buy_payment', {
      'event_category': 'point_buy_modal',
      'event_label': 'success_point_payment'
    });

    this._afterHandler();
  };

  failHandler = () => {
    // TODO: 타이머 필요한 이유 확인해볼 것
    return new Promise((resolve) => {
      const itv = setInterval(async () => {
        if (!this.app) return;
        clearInterval(itv);
        const title = this.app.$t('coaching.PT_store_fail');
        const content = this.app.$t('coaching.PC_store_fail');
        const options = { title, content };
        await this.app.$modal(CoachConfirmModal, options);

        /** GA 전자상거래 추적코드 */
        Vue.prototype.$gtag.event('fail_point_buy_payment', {
          'event_category': 'point_buy_modal',
          'event_label': 'fail_point_payment'
        });

        resolve();
      }, 600);
    });
  };

  _afterHandler = () => {
    if (typeof this?.afterHandler === 'function')
      this.afterHandler();

    this.afterHandler = null;
  };
}

export const payment = new Payment;
