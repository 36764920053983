import _cloneDeep from 'lodash/cloneDeep';

export default () => ({
  namespaced: true,
  state: {
    champs: null,
    category: {},
  },
  getters: {
    champAmount: (state) => state.champs?.length || 0,
    champsByCode: (state, getters) => {
      const champs = new Map();
      if (getters.champAmount === 0) return champs;
      state.champs.forEach(c => champs.set(c.code, c));
      return champs;
    },
    hasCategory: (state) => Object.keys(state.category).length >= 1
  },
  mutations: {
    setChamps(state, payload) {
      state.champs = payload;
    },
    setCategory(state, [key, value]) {
      const category = _cloneDeep(state.category);
      category[key] = value;
      state.category = category;
    }
  },
});