import Vue from 'vue';
import Vuex from 'vuex';
import { store as browser } from '@shared/modules/Browser';
import auth from './auth.store';
import signup from './signup.store';
import info from './info.store';
import time from './time.store';
import notification from './notification.store';
import lol from './lol.store';
import chat from './chat.store';
import point from './point.store';
import ui from './ui.store';

Vue.use(Vuex);

/**
 * @returns {Vuex.Store}
 */
export default () => new Vuex.Store({
  modules: {
    auth: auth(),
    browser: browser(),
    signup: signup(),
    info: info(),
    time: time(),
    notification: notification(),
    lol: lol(),
    chat: chat(),
    point: point(),
    ui: ui(),
  },
});
