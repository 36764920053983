import { sleep } from '@shared/utils/commonUtils';
import CoachLessonReviewModal from '@/views/components/coaching/CoachLessonReviewModal.vue';
import ModalChildRepSuccess from '@/views/components/coaching/modal-child-rep/ModalChildRepSuccess.vue';
import ModalChildRepFail from '@/views/components/coaching/modal-child-rep/ModalChildRepFail.vue';

export default class CoachingService {
  /** @type {ApiConnection} */
  #commonApi;
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;

  /**
   * @param {ServiceManager} services
   */
  constructor(services) {
    this.#commonApi = services.commonApi;
    this.#api = services.coachingApi;
    this.#services = services;
  }

  async walletBalanceChangeHandler() {
    await this.#services.auth.fetchMyInfo();
  }

  async lessonProductOrderEndedHandler(arg) {
    const isCoach = this.#services.store.getters['auth/isCoach'];
    if (isCoach) return;
    const { lessonProductOrderId } = arg;
    const info = await this.getLessonOrder({ lessonProductOrderId });

    /** 정책 상에 3초 대기하도록 되어 있음 */
    await sleep(3000);
    await this.#services.modal.modal(CoachLessonReviewModal, { info });
  }

  setApp(app) {
    if (this.#services.auth.isLogin) {
      const userRole = this.#services.store.getters['auth/isCoach'] ? 'coach' : 'student';
      const { userId } = this.#services.store.state.auth;
      const privateChannel = `private-${userId}`;

      app.$gtag.set('user_properties', { user_role: userRole });
      app.$bindPushEvent(privateChannel, 'wallet_balance_changed', this.walletBalanceChangeHandler.bind(this));
      app.$bindPushEvent(privateChannel, 'lesson_product_order_ended', this.lessonProductOrderEndedHandler.bind(this));
    }
  }

  /**
   * @param {string} serviceType
   * @returns {Promise<BannerInfo[]>}
   */
  async getBanners(serviceType = 'COACHING') {
    return this.#api.get('/v1/banners', { serviceType });
  }

  /**
   * @description
   * 개인화 영역: 미션 가져오기(코칭 전용)
   * @param {number?} cursor
   * @param {number?} size
   * @returns {Promise<PagedMissions>}
   */
  async getMissions({ cursor = 0, size = 10 } = {}) {
    return this.#api.get('/missions', { cursor, size }, { silent: true });
  }

  /**
   * @description
   * 개인화 영역: 미션 보상 받기(코칭 전용)
   * @param {string} userMissionId
   * @returns {Promise<void>}
   */
  async receiveMissionReward(userMissionId) {
    try {
      const result = await this.#api.post(`/missions/reward/${userMissionId}`);
      this.#services.toast.toast('coaching.receiveMissionReward.SUCCESS');
      await this.#services.auth.fetchMyInfo();
      return result;
    } catch (err) {
      throw ['coaching.receiveMissionReward', err?.code];
    }
  }

  /**
   * @function getLessonOrders
   * @description
   * 개인화 영역: 수강 이력 가져오기(코칭 전용)
   * @param {number?} cursor
   * @param {number?} size
   * @param {string?} coachId - 코치 식별자
   * @param {string?} coachUserId - 코치 유저 식별자
   * @param {string?} userId - 수강권 구매 유저 식별자
   * @param {LessonStatus?} status - 수강권 상태
   * @param {string?} q - 커스텀 쿼리
   * @param {('startDateTime'|'buyDatetime')?} order - 정렬 조건 startDateTime 강의시작시간 buyDateTime 강의구매시간
   */
  async getLessonOrders({ cursor = 0, size = 10, coachId, coachUserId, userId, status, order, q } = {}) {
    const qs = [];
    if (coachId) qs.push(`coachId eq ${coachId}`);
    if (coachUserId) qs.push(`coachUserId eq ${coachUserId}`);
    if (userId) qs.push(`userId eq ${userId}`);
    if (status) qs.push(`status eq ${status}`);
    if (q) qs.push(q);
    const _q = qs.join(' and ');
    const opts = { cursor, size };
    if (_q.length >= 1) opts.q = _q;
    if (order) opts.order = order;
    return this.#api.get('/lessons/products/orders', opts, { silent: true });
  }

  /**
   * @function getLessonOrder
   * @description
   * 구매한 수업 단건 조회
   * @param {string} lessonProductOrderId
   * @returns {Promise<LessonOrder>}
   */
  async getLessonOrder({ lessonProductOrderId }) {
    return this.#api.get(`lessons/products/orders/${lessonProductOrderId}`);
  }

  /**
   * @description
   * 코치 TV 검색
   * @param {number} cursor
   * @param {('contentCreatedDatetime asc'|'contentCreatedDatetime desc')?} order
   * @param {string} tvContentGroupId
   * @param {string} tvContentId
   * @param {boolean} isRecommend
   * @param {string} title
   * @param {string} playlist
   * @param {string} platform
   * @param {number} size
   * @returns {Promise<CoachTvContentList>}
   */

  async getCoachTvContentList({ cursor = 0, order = '', tvContentGroupId = '', tvContentId = '', isRecommend = false, title = '', playlist = '', platform = '', size = 3, coachId = '' } = {}) {
    const query = { cursor, size, order };
    const q = [];
    if (tvContentGroupId !== '') q.push(`tvContentGroupId eq ${tvContentGroupId}`);
    if (tvContentId !== '') q.push(`tvContentId eq ${tvContentId}`);
    if (isRecommend) q.push('isRecommend eq true');
    if (title) q.push(`title like ${title}`);
    if (playlist) q.push(`playlist in ${playlist}`);
    if (platform) q.push(`platform in ${platform}`);
    if (coachId) q.push(`coachId eq ${coachId}`);
    if (q.length) query.q = q.join(' and ');
    return this.#api.get('/coach-tv', query, { silent: true });
  }

  /**
   * @description
   * 코치 TV 영상보기
   * @param {string} tvContentId
   * @returns {Promise<CoachTvContent>}
   */
  async getCoachTvContent({ tvContentId }) {
    return this.#api.get(`/coach-tv/${tvContentId}`, null, { silent: true });
  }

  /**
   * @description
   * 코치 TV 연관 재생 목록
   * @param {string} tvContentGroupId
   * @param {string} tvContentId
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<CoachTvContentList>}
   */
  async getCoachTvRelatedContentList({ tvContentGroupId = '', tvContentId = '', cursor = 0, size = 3, order = 'contentCreatedDatetime asc' } = {}) {
    const query = { cursor, size, order };
    const q = [];

    if (tvContentGroupId) q.push(`tvContentGroupId eq ${tvContentGroupId}`);
    if (tvContentId) q.push(`tvContentId gt ${tvContentId}`);
    if (q.length >= 1) query.q = q.join(' and ');

    return this.#api.get(`/coach-tv`, query, { silent: true });
  }

  /**
   * @description
   * 코치 TV 재생목록
   * @param {number} offset
   * @param {number} size
   * @returns {Promise<CoachTvPlaylist>}
   */
  async getCoachTvPlaylist({ offset = 0, size = 3 } = {}) {
    return this.#api.get('/coach-tv/playlist', { offset, size }, { silent: true });
  }

  /**
   * @description
   * 코치 TV 전체보기 필터
   * @returns {Promise<CoachTvFilter>}
   */
  async getCoachTvFilter() {
    return this.#api.get('/coach-tv/filter', null, { silent: true });
  }

  /**
   * @description
   * 코치 검색
   * @param {number} cursor
   * @param {number} size
   * @param {string} order
   * @param {string} name
   * @param {boolean} isBookmark
   * @param {string} tag
   * @param {string} rankQuery
   * @param {string} champQuery
   * @param {string} lolPositionQuery
   * @param {string} search
   * @returns {Promise<CoachList>}
   */
  async getCoachList({
    cursor = 0, size = 100, order = '', name = '', isBookmark = false, tag = '', rankQuery = '', champQuery = '',
    lolPositionQuery = '', search = '', isOnline = false, canLesson = false, silent = false,
  } = {}) {
    const query = { cursor, size };
    const q = [];
    if (order) query.order = order;
    if (name) q.push(`name like ${name}`);
    if (search) q.push(`search like ${search}`);
    if (isBookmark) q.push('isBookmark eq true');
    if (isOnline) q.push('isOnline eq true');
    if (canLesson) q.push('canLesson eq true');
    if (rankQuery) q.push(rankQuery);
    if (champQuery) q.push(champQuery);
    if (lolPositionQuery) q.push(lolPositionQuery);
    if (tag) q.push(`tag eq ${tag}`);
    if (q.length >= 1) query.q = q.join(' and ');
    return this.#api.get('coaches', query, { silent });
  }

  /**
   * @returns {Promise<RankCoachList>}
   */
  async getRankCoachList() {
    return this.#api.get('ranking-coaches', null, { silent: true });
  }

  /**
   * @description
   * [QnA] 질문 검색
   * @param {number} cursor
   * @param {string} gameId
   * @param {QuestionQueryCategory[]} category
   * @param {number} size
   * @param {string} orderBy
   * @param {string} order
   * @param {string} q
   * @returns {Promise<PreviewQuestionList>}
   */
  async getQuestions({ cursor = 0, gameId = 'LOL', category = [], size = 5, q = '', orderBy = '', order = 'desc' } = {}) {
    const query = { cursor, size };
    const _q = [q].filter(q => q !== '');
    if (category.length >= 1) {
      _q.push(`category eq ${encodeURI(JSON.stringify(category))}`);
    }
    if (_q.length >= 1) query.q = _q.join(' and ');
    if (orderBy !== '') {
      query.order = `${orderBy} ${order}`;
    }
    return this.#api.get(`v1/qna/${gameId}/questions`, query, { silent: true });
  }

  /**
   * @description
   * [QnA] 질문 검색 - 답변 완료
   * @param {number} cursor
   * @param {string} gameId
   * @param {QuestionQueryCategory[]} category
   * @param {number} size
   * @param {string} orderBy
   * @param {string} order
   * @param {string} q
   * @returns {Promise<PreviewQuestionList>}
   */
  getAnsweredQuestions({ cursor = 0, gameId = 'LOL', category = [], size = 5, q = 'isAnswered eq true', orderBy = 'latestAnswerDatetime', order = 'desc' } = {}) {
    return this.getQuestions({ cursor, gameId, category, size, q, orderBy, order });
  }

  /**
   * @description
   * 수업 목록 조회
   * @param {string?} gameId
   * @returns {Promise<LessonBestReviews[]>}
   */
  getLessons({ gameId = 'lol' } = {}) {
    return this.#api.get('lessons', { gameId }, { silent: true });
  }

  /**
   * @description
   * 코치 수업 리뷰 조회
   * @param cursor
   * @param order
   * @param page
   * @param q
   * @param size
   * @param coachId
   * @param {boolean?} silent
   * @returns {Promise<PagedLessonReview>}
   */
  getLessonReviews({ cursor = 0, order = '', q = '', size = 20, coachId = '' }, silent) {
    const params = { cursor, size };
    const _q = [q].filter(x => x !== '');
    if (coachId !== '') _q.push(`coachId eq ${coachId}`);
    if (_q.length >= 1) params.q = _q.join(' and ');
    if (order !== '') params.order = order;
    return this.#api.get('lessons/products/orders/reviews', params, { silent });
  }

  /**
   * @description
   * [모두] 코치 대표 리뷰 조회
   * @param coachId
   * @returns {Promise<PagedLessonReview>}
   */
  getRepresentativeReviews({ coachId, size = 5 }) {
    const query = { size };
    return this.#api.get(`v1/coaches/${coachId}/representative-reviews`, query);
  }

  /**
   * @description
   * [코치] 코치 대표 리뷰 변경
   * @param {string} coachId
   * @param {string[]} lessonReviewIds
   */
  putRepresentativeReviews({ coachId = '', lessonReviewIds = [] }) {
    return this.#api.put(`v1/coaches/${coachId}/representative-reviews`, { lessonReviewIds }).then(() => {
        this.#services.toast.toast('coaching.modifyCoach.SUCCESS');
      });
  }

  /**
   * @description
   * 선호 챔피언 조회
   * @param {string?} coachId
   * @param {string} userId
   * @param {boolean?} silent
   * @returns {Promise<PreferChamp[]>}
   *
   */
  getPreferChamp({ userId = '', coachId = '' } = {}, silent = true) {
    const query = {};
    if (userId) query.userId = userId;
    if (coachId) query.coachId = coachId;
    return this.#api.get('v1/thirdparty/lol/prefer-champions', query, { silent });
  }

  /**
   * @description
   * 선호 챔피언 등록/변경
   * @param {LolChamp[]} preferChampions
   */
  modifyUserLolChamps({ preferChampions = [] } = {}) {
    return this.#api.put('v1/thirdparty/lol/prefer-champions', preferChampions, { silent: true });
  }

  /**
   * @description
   * 챔피언 조회
   * @param {LolLane} lane
   * @param {string?} name
   * @returns {Promise<LolChamp[]>}
   */
  getLolChamps({ lane = 'ALL', name = '' } = {}) {
    if (lane === 'ALL' && name === '') return this.getLolChampsAll();

    const query = { lane };
    if (name) query.name = name;
    return this.#api.get('v1/thirdparty/lol/champions', query, { silent: true });
  }

  /**
   * @description
   * 추천 코치 소환사 조회
   * @param {string} summonerNickname
   * @returns {Promise<SummonerRankInfo>}
   */
  async getSummonerRankInfo(summonerNickname) {
    return this.#api.get(`/v1/recommendation/LOL/summoners/${summonerNickname}`, { silent: true });
  }

  /**
   * @description
   * 추천 코치 소환사 상세 조회
   * @param {string} summonerNickname
   * @returns {Promise<SummonerRankInfoDetail>}
   */
  async getSummonerRankDetailInfo(summonerNickname, silent = true) {
    return this.#api.get(`/v1/recommendation/LOL/summoners/${summonerNickname}/detail`, { silent });
  }

  /**
   * @description
   * 추천 코치 찾기
   * @param {SummonerRankInfoDetail} summonerRankInfo
   * @param {RecommendationCoachAnswer} answers
   * @param {boolean} isHigherTier
   * @returns {Promise<RecommendationCoachesResult>}
   */
  async requestRecommendationCoach({ summonerRankInfo, answers, isHigherTier, hasRiotError = false }) {
    let version = 'LOL_V1';
    if (!summonerRankInfo?.profile && !hasRiotError) version += '_NO_SUMMONER';
    else if (isHigherTier) version += '_HIGH_TIER';
    else version += '_DEFAULT';
    const body = { version, soloRankInfo: summonerRankInfo, qna: answers };
    return this.#api.post(`/v1/recommendation/LOL`, body);
  }

  /**
   * @description
   * 추천 코치 상담 요청
   * @returns {Promise<RecommendationCoachesResult>}
   */
  async requestCoachConsult(coachId) {
    try {
      return await this.#api.post('/v1/recommendation/LOL/consult', { coachId });
    } catch (err) {
      throw ['coaching.coachMatching.goRequest.result.fail.default', err?.code];
    }
  }

  /**
   * @description
   * 내 설문 및 추천 코치 보기
   * @returns {Promise<RecommendationCoachesResult>}
   */
  async getMyRecommendationCoaches() {
    return this.#api.get('/v1/recommendation/LOL', { silent: true });
  }

  /**
   * @description
   * 내 추천 코치 설문으로 변경
   * @param {string} surveyId
   * @returns {Promise<RecommendationCoachesResult>}
   */
  async setMyRecommendationCoachesWithSurveyId(surveyId) {
    return this.#api.put(`/v1/recommendation/LOL/surveys/${surveyId}`);
  }

  /**
   * @description
   * 비로그인 유저 설문 및 추천 코치 보기
   * @param {string} surveyId
   * @returns {Promise<RecommendationCoachesResult>}
   */
  async getMyRecommendationCoachesWithSurveyId(surveyId) {
    return this.#api.get(`/v1/recommendation/LOL/surveys/${surveyId}`, { silent: true });
  }

  /**
   * @description
   * 직접 상담 코치 보기
   * @returns {Promise<RecommendationCoaches>}
   */
  async getDirectRecommendationCoaches() {
    return this.#api.get('/v1/recommendation/LOL/direct', { silent: true });
  }

  /**
   * @description
   * 직접 상담 요청
   * @returns {Promise<RecommendationCoaches>}
   */
  async requestDirectCoachConsult(coachId) {
    try {
      return await this.#api.post('/v1/recommendation/LOL/direct/consult', { coachId });
    } catch (err) {
      throw ['coaching.coachMatching.goRequest.result.fail.default', err?.code];
    }
  }

  /**
   * @description
   * 전체 챔피언 조회.
   * 업데이트될 확률이 낮으므로 자료를 캐싱하여 다시 가져오지 않도록 한다.
   * @returns {Promise<LolChamp[]>}
   */
  async getLolChampsAll() {
    const cached = this.#services.store.getState('lol', 'champs');
    if (cached) return cached;
    const champs = await this.#api.get('v1/thirdparty/lol/champions', { lane: 'ALL' });
    this.#services.store.commit('lol/setChamps', champs, { silent: true });
    return champs;
  }

  /**
   * @description
   * 코드를 가지고 해당하는 챔프 자료를 반환한다
   * @param {string} champCode
   * @returns {Promise<LolChamp>}
   */
  async getChampByCode(champCode = 'Garen') {
    if (!this.#services.store.getState('lol', 'champs')) await this.getLolChampsAll();
    const champsByCode = this.#services.store.getGetter('lol', 'champsByCode');
    return champsByCode.get(champCode);
  }

  /**
   * @description
   * UP포인트 결제 목록
   * @returns {Promise<BillingProduct[]>}
   */
  getBillingProducts() {
    return this.#api.get('billing/products');
  }

  /**
   * @description
   * UP포인트 결제 생성
   * @returns {Promise<Billing>}
   */
  makeBilling(productId = '', provider = '') {
    if (!productId || !provider) return null;
    const body = { productId, provider };
    return this.#api.post('billing', body);
  }

  /**
   * @description
   * 수강 구매
   * @returns {Promise<void>}
   */
  async purchaseLesson({ lessonProductId, bundleSize = 1, couponId = null }) {
    try {
      const body = { bundleSize, couponId };
      const result = await this.#api.post(`lessons/products/${lessonProductId}/order`, body);
      this.#services.toast.toast('coaching.purchaseLesson.SUCCESS');
      await this.#services.auth.fetchMyInfo(); // 포인트 감소에 따른 포인트 새로고침 호출
      return result;
    } catch (err) {
      throw ['coaching.purchaseLesson', err?.code];
    }
  }

  /**
   * @description
   * 수업 리뷰 등록
   * @param {string} lessonProductOrderId
   * @param {number} rating
   * @param {string} description
   * @returns {Promise<void>}
   */
  async postReview({ lessonProductOrderId, rating, description }) {
    try {
      const body = { rating, description };
      const result = await this.#api.post(`lessons/products/orders/${lessonProductOrderId}/review`, body);
      this.#services.toast.toast('coaching.postReview.success');
      return result;
    } catch (err) {
      throw ['coaching.postReview', err?.code];
    }
  }

  /**
   * @description
   * 수업 취소
   * @param {string} lessonProductOrderId
   * @param {string} cancelReason
   * @param {string?} detail
   */
  async postLessonCancel({ lessonProductOrderId, cancelReason, detail }) {
    try {
      const body = { cancelReason, detail };
      const result = await this.#api.post(`lessons/products/orders/${lessonProductOrderId}/cancel`, body);
      this.#services.toast.toast('coaching.lessonCancel.SUCCESS');
      return result;
    } catch (err) {
      throw ['coaching.lessonCancel', err?.code];
    }
  }

  /**
   * @description
   * 토스 페이먼츠 클라이언트 키
   * @returns {Promise<string>}
   */
  getTossPaymentsAuthKey() {
    return this.#api.get('billing/toss-payments/auth');
  }

  /**
   * @description
   * 토스 페이먼츠 결제 완료
   * @returns {Promise<void>}
   */
  confirmTossPayments({ billingId, orderId, amount, paymentKey }) {
    if (!billingId || !orderId || !amount || !paymentKey) return null;
    const body = { orderId, amount, paymentKey };
    return this.#api.post(`billing/${billingId}/toss-payments/confirm`, body);
  }

  /**
   * @description
   * 페이플 결제 완료
   * @returns {Promise<void>}
   */
  confirmPayple({ billingId = '', responseData = {} } = {}) {
    const body = responseData;
    return this.#api.post(`billing/${billingId}/payple/confirm`, body);
  }

  /**
   * @description
   * 코치 대시보드 조회
   * @returns {Promise<TutorDashboard>}
   */
  async getTutorDashboard() {
    try {
      return await this.#api.get('coaches/me/dashboard');
    } catch (e) { /* empty */ }
  }

  /**
   * @description
   * 코치 정보 조회(코치 본인용)
   * @returns {Promise<CoachDetail>}
   */
  getCoachMe() {
    return this.#api.get('coaches/me');
  }

  /**
   * @description
   * 카테고리 조회
   * @template GroupName
   * @param {GroupName} groupName
   * @param {boolean?} silent
   * @returns {Promise<QuestionCategoryBase<GroupName>>}
   */
  async getCategories(groupName, silent) {
    const cached = this.#services.store.getState('lol', 'category')[groupName];
    if (cached) return cached;
    const groupData = await this.#api.get(`v1/qna/category/${groupName}`, null, { silent });
    this.#services.store.commit('lol/setCategory', [groupName, groupData]);
    return groupData;
  }

  /**
   * @description
   * 질문 단건 조회
   * @param {string} gameId
   * @param {string} boardWriteId - 게시물 일련번호
   * @returns {Promise<Question>}
   */
  async getQuestion({ gameId, boardWriteId }) {
    return this.#api.get(`/v1/qna/${gameId}/questions/${boardWriteId}`);
  }

  /**
   * @description
   * 질문 단건에 대한 답변 다중 조회
   * @param {string} boardWriteId
   * @param {'createdDatetime' | 'like'} orderBy
   * @param {number} cursor
   * @param {number} size
   * @param {'asc'|'desc'} order
   * @param {string?} q
   * @returns {Promise<PagedAnswers>}
   */
  async getAnswers({ boardWriteId = '', orderBy = 'createdDatetime', order = 'desc', cursor = 0, size = 5, q = '' }) {
    const query = { order: `${orderBy} ${order}`, cursor, size };
    if (q !== '') query.q = q;
    return this.#api.get(`v1/qna/questions/${boardWriteId}/answers`, query);
  }

  /**
   * @description
   * 질문 스크랩
   * @param {string} gameId
   * @param {string} boardWriteId
   * @return {Promise<Question>}
   */
  async scrapQuestion({ boardWriteId = '', gameId = '' }) {
    try {
      const result = await this.#api.post(`v1/qna/${gameId}/questions/${boardWriteId}/scrap`);
      this.#services.toast.toast('coaching.scrapQuestion.success');
      return result;
    } catch (err) {
      throw ['coaching.scrapQuestion', err?.code];
    }
  }

  /**
   * @description
   * 질문 스크랩 취소
   * @param {string} gameId
   * @param {string} boardWriteId
   * @return {Promise<Question>}
   */
  async cancelScrapQuestion({ boardWriteId = '', gameId = '' }) {
    try {
      const result = await this.#api.delete(`v1/qna/${gameId}/questions/${boardWriteId}/scrap`);
      this.#services.toast.toast('coaching.cancelScrapQuestion.success');
      return result;
    } catch (err) {
      throw ['coaching.cancelScrapQuestion', err?.code];
    }
  }

  /**
   * @description
   * 답변 공감
   * @param {string} boardWriteId - 질문 일련번호
   * @param {string} boardCommentId - 답변 일련번호
   * @return {Promise<Answer>}
   */
  async likeAnswer({ boardWriteId = '', boardCommentId = '' }) {
    try {
      const result = await this.#api.post(`v1/qna/questions/${boardWriteId}/answers/${boardCommentId}/like`);
      this.#services.toast.toast('coaching.likeAnswer.success');
      return result;
    } catch (err) {
      throw ['coaching.likeAnswer', err?.code];
    }
  }

  /**
   * @description
   * 답변 공감 취소
   * @param {string} boardWriteId - 질문 일련번호
   * @param {string} boardCommentId - 답변 일련번호
   * @return {Promise<Answer>}
   */
  async unlikeAnswer({ boardWriteId = '', boardCommentId = '' }) {
    try {
      const result = await this.#api.delete(`v1/qna/questions/${boardWriteId}/answers/${boardCommentId}/unlike`);
      this.#services.toast.toast('coaching.unlikeAnswer.success');
      return result;
    } catch (err) {
      throw ['coaching.unlikeAnswer', err?.code];
    }
  }

  /**
   * @description
   * 답변 작성
   * @param {string} boardWriteId - 질문 일련번호
   * @param {string} content - 답변 본문
   * @return {Promise<Answer>}
   */
  async postAnswer({ boardWriteId = '', content = '' }) {
    try {
      const result = await this.#api.post(`v1/qna/questions/${boardWriteId}/answers`, { content });
      this.#services.toast.toast('coaching.postAnswer.success');
      return result;
    } catch (err) {
      throw ['coaching.postAnswer', err?.code];
    }
  }

  /**
   * @description
   * 답변 편집
   * @param {string} boardWriteId
   * @param {string} boardCommentId
   * @param {string} content
   * @return {Promise<Answer>}
   */
  async editAnswer({ boardWriteId = '', boardCommentId = '', content = '' }) {
    try {
      const result = await this.#api.put(`v1/qna/questions/${boardWriteId}/answers/${boardCommentId}`, { content });
      this.#services.toast.toast('coaching.editAnswer.success');
      return result;
    } catch (err) {
      throw ['coaching.editAnswer', err?.code];
    }
  }

  /**
   * @description
   * 질문 작성
   * @param {string} gameId
   * @param {string} title
   * @param {string} content
   * @param {string[]} imageUrls
   * @param {boolean} usePrivateName
   * @param {QuestionQueryCategory[]}categories
   * @return {Promise<Question>}
   */
  async postQuestion({ title = '', content = '', imageUrls = [], usePrivateName = false, categories = [], gameId = 'LOL' }) {
    try {
      const result = await this.#api.post(`v1/qna/${gameId}/questions`, { title, content, imageUrls, usePrivateName, categories });
      this.#services.toast.toast('coaching.postQuestion.success');
      return result;
    } catch (err) {
      throw ['coaching.postQuestion', err?.code];
    }
  }

  /**
   * @description
   * 코치 접속상태 변경
   * @param {string} coachId
   * @param {boolean} isOnline
   * @returns {Promise<{isOnline: boolean}>}
   */
  setOnlineStatus({ coachId = '', isOnline = false }) {
    return this.#api.put(`coaches/${coachId}/online-status`, { isOnline });
  }

  /**
   * @description
   * 코치 상세정보
   * !주의: 랭킹 화면에서 코치 모달 열 때는 반드시 다른 엔드포인트 이용 필요
   * @param {string} coachId
   * @returns {Promise<CoachDetail>}
   */
  async getTutorDetail(coachId) {
    try {
      return await this.#api.get(`/coaches/${coachId}`);
    } catch(err) {
      if (err?.code === 'COACH_INACTIVE') {
        throw ['coaching', 'MC_CTA_COACH_INFO_INVALID_RESTING'];
      }
      // 기존 배포되어있는 내용을 참조하여 코치 비활성화인 경우를 제외하고 에러는 별도로 표기하지 않음.
    }
  }

  /**
   * @description
   * 랭킹 화면 전용 코치 상세정보
   * 랭킹 화면에서 코치 모달 열 때 사용
   * @param {string} coachId
   * @returns {Promise<CoachDetail>}
   */
  getTutorDetailForRanking(coachId) {
    return this.#api.get(`/ranking-coaches/${coachId}`, null, { silent: true });
  }

  /**
   * @description
   * 코치 상세 정보 수정
   * @param {CoachDetailModify} coachModifyData
   * @returns {Promise<CoachDetail>}
   */
  async modifyCoach(coachModifyData = {}) {
    try {
      const res = await this.#api.put(`/coaches/${coachModifyData?.coachId ?? ''}`, coachModifyData);
      this.#services.toast.toast('coaching.modifyCoach.SUCCESS');
      return res;
    } catch (err) {
      throw ['coaching.modifyCoach', err?.code];
    }
  }

  /**
   * @description
   * 코치 소개 탭 정보 수정
   * !! 나중에 코치 수정 요청 타입 추가해 놓을 것
   * @returns {Promise<CoachDetail>}
   */
  async modifyCoachIntroduction(coachId, coachIntroductionData) {
    try {
      const res = await this.#api.put(`/v1/coaches/${coachId}/introduction`, coachIntroductionData);
      // 임시로 코치 상세정보 수정과 같은 메시지 사용. 나중에 변경할 것
      this.#services.toast.toast('coaching.modifyCoach.SUCCESS');
      return res;
    } catch(err) {
      throw ['coaching.modifyCoach', err?.code];
    }
  }

  /**
   * @description
   * 코치 즐겨찾기 추가
   * @param {string} coachId
   * @returns {Promise<void>}
   */
  async likeCoach(coachId, showToast = true) {
    try {
      const result = await this.#api.post(`/coaches/${coachId}/like`, null, { silent: true });
      if (showToast) this.#services.toast.toast('coaching.likeCoach.success');
      return result;
    } catch (err) {
      throw ['coaching.likeCoach', err?.code];
    }
  }

  /**
   * @description
   * 코치 즐겨찾기 취소
   * @param {string} coachId
   * @returns {Promise<void>}
   */
  async unlikeCoach(coachId) {
    try {
      const result = await this.#api.delete(`/coaches/${coachId}/like`, { silent: true });
      this.#services.toast.toast('coaching.unlikeCoach.success');
      return result;
    } catch (err) {
      throw ['coaching.unlikeCoach', err?.code];
    }
  }

  /**
   * @description
   * 친구 초대 이벤트 - 초대 현황
   * @returns {Promise<inviteeCount: number, reward: number>}
   */

  async getFriendsInvitationStatus() {
    try {
      const result = await this.#api.get('/promotions/friends-invitation/status');
      return result;
    } catch (err) {
      throw ['coaching.getFriendsInvitationStatus', err?.code];
    }
  }

  /**
   * @description
   * 친구 초대 이벤트 - 초대 코드 확인
   * @returns {Promise<code: string, isCreated: boolean, userId: string>}
   */
  async getInvitationCode() {
    try {
      const result = await this.#api.get('/promotions/friends-invitation');
      return result;
    } catch (err) {
      throw ['coaching.getInvitationCode', err?.code];
    }
  }

  /**
   * @description
   * 친구 초대 이벤트 - 초대 등록
   * @param {string} invitationCode
   * @returns {Promise<void>}
   */
  async postInvitationCode({ invitationCode }) {
    try {
      const result = await this.#api.post('/promotions/friends-invitation', { code: invitationCode });
      this.#services.toast.toast(`coaching.FRIENDS_INVITATION_RECEIVE_SUCCESS`, { type: 'success' });
      return result;
    } catch (err) {
      throw ['coaching.postInvitationCode', err?.code];
    }
  }

  /**
   * @description
   * 수업료 할인 쿠폰 조회
   * @param {string} lessonProductId
   * @param {number} bundleSize
   * @returns {Promise<LessonCoupon[]>}
   */
  getLessonCoupons({ lessonProductId = '', bundleSize = 1 }) {
    return this.#api.get('/coupons', { lessonProductId, bundleSize });
  }

  /**
   * @description
   * [코치 유저] 판매 내역 조회
   * @param {string} order
   * @param {stinrg} q
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<SalesLessonOrderList>}
   * */
  getSalesLessonOrderList({ order = 'orderDateTime desc', q, cursor = 0, size = 10 } = {}) {
    const query = { order, cursor, size };
    if (q) query.q = q;
    if (order) query.order = order;
    return this.#api.get('/v1/product-orders/', query);
  }

  /**
   * @description
   * [코치 유저] 판매 내역 상세 조회
   * @param {string} lessonProductOrderId
   * @returns {Promise<SalesLessonOrder>}
   */
  getSalesLessonOrder({ lessonProductOrderId }) {
    return this.#api.get(`/v1/product-orders/${lessonProductOrderId}`);
  }

  /**
   * @description
   * [코치 유저] 판매 내역 상세 메모
   * @param {string} lessonProductOrderId
   * @param {string} description
   * @returns {Promise<createDateTime: number, description: string, memoId: string, writerUserId: string>}
   */
  postOrderMemo({ productOrderId, description }) {
    return this.#api.post(`/v1/product-orders/${productOrderId}/memo`, { description });
  }

  /**
   * @description
   * [코치 유저] 판매 내역 상세 수정
   * @param {string} lessonProductOrderId
   * @param {string} description
   * @returns {Promise<createDateTime: number, description: string, memoId: string, writerUserId: string>}
   */
  putOrderMemo({ productOrderId, description }) {
    return this.#api.put(`/v1/product-orders/${productOrderId}/memo`, { description });
  }

  /**
   * @description
   * [코치 유저] 판매 내역 다운로드
   * @param {string} order
   * @param {string} q
   * @returns {Promise<?>}
   */
  downloadSalesLessonOrderList({ order = 'orderDateTime desc', q }) {
    const query = { order };
    if (q) query.q = q;
    return this.#api.get('/v1/product-orders/download', query, { responseType: 'arraybuffer' });
  }

  /**
   * @description
   * [코치 유저] 정산 내역 조회
   * @param {string} order
   * @param {string} q
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<PagedSettlements>}
   */
  getSettlements({ order = 'settlementDatetime desc', q, cursor = 0, size = 10 }) {
    const query = { order, cursor, size };
    if (q) query.q = q;
    return this.#api.get('/v1/settlements', query, { silent: true });
  }

  /**
   * @description
   * [코치 유저] 정산 내역 단건 조회
   * @param {string} settlementId
   * @returns {Promise<SettlementDetail>}
   */
  getSettlementDetail(settlementId) {
    return this.#api.get(`/v1/settlements/${settlementId}`);
  }

  /**
   * @description
   * [코치 유저] 정산 내역 다운로드
   * @param {string} order
   * @param {string} q
   * @returns {Promise<?>}
   */
  downloadSettlements({ order = 'settlementDatetime desc', q }) {
    const query = { order };
    if (q) query.q = q;
    return this.#api.get('/v1/settlements/download', query, { responseType: 'arraybuffer' });
  }

  /**
   * @description
   * 법정대리인 정보
   * @returns {Promise<LegalRepresentative>}
   */
  getRepresentativeInfo() {
    return this.#api.get(`/v1/users/billing-legal-representative`);
  }

  /**
   * @description
   * 법정대리인 동의(신규)
   * @param {string} impUid
   * @param {number} periodMonth
   * @param {string} email
   */
  async agreeRepresentative({ impUid, periodMonth, email }) {
    try {
      await this.#api.post(`/v1/users/billing-legal-representative/agree`, { impUid, periodMonth, email });
      await this.#services.modal.modal(ModalChildRepSuccess, { email, periodMonth });
    } catch (err) {
      console.error(err);
      if (err?.code === 'LEGAL_REPRESENTATIVE_NOT_MAJOR' || err?.code === 'LEGAL_REPRESENTATIVE_NOT_SUFFICIENT') {
        this.#services.modal.modal(ModalChildRepFail);
        return;
      }
      throw(err);
    }
  }

  /**
   * @description
   * 코치에게 등록된 태그 목록
   */
  getCoachTags() {
    return this.#api.get(`coach-tags`, null, { silent: true });
  }

  /**
   * @description
   * [유저/코치]포인트 변동 내역 조회
   * @param {'createdDatetime desc' | 'createdDatetime asc' | 'transactionType desc' | 'transactionType asc'} order
   * @param {string} q
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<PagedTransactions>}
   */
  getTransactions({ order = 'createdDatetime desc', q, cursor = 0, size = 10 }) {
    const query = { order, cursor, size };

    if (q) query.q = q;
    return this.#api.get('/v2/transactions', query, { silent: true });
  }

  /**
   * @description
   * [유저/코치] 추천 수업 조회
   * @returns {Promise<RecommendLesson>}
   */
  getRecommendLesson() {
    return this.#api.get('/lessons/products/recommendation', null, { silent: true });
  }

  /**
   * @description
   * [코치]코치 수업 소개 변경
   * https://bigpic.atlassian.net/browse/COG-1209
   * @param {string} coachId
   * @param {CoachLesson} CoachLesson
   * @returns {Promise<CoachDetail>}
   */
  async modifyLessonIntroduction(coachId, coachLesson) {
    try {
      const res = await this.#api.put(`/v1/coaches/${coachId}/lesson-introduction`, coachLesson);
      this.#services.toast.toast('coaching.modifyLessonIntroduction.success');
      return res;
    } catch(err) {
      throw ['coaching.modifyLessonIntroduction', err.code];
    }
  }

  /**
   * @description
   * 코치용 상단 띠 배너
   * https://bigpic.atlassian.net/browse/COG-1014
   * @returns {CoachTopBanner[]}
   */
  async getTopBanner() {
    try {
      return await this.#api.get('/v1/banners/top', null, { silent: true });
    } catch(err) {
      // 배너 에러에 대해서는 별도의 에러처리 하지 않음
      return [];
    }
  }
}
