import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import signup from '@/router/signup';
import misc from '@/router/misc';
import coaching from '@/router/coaching';
import term from '@/router/term';
import { i18n } from '@/plugins/i18n';
import LanguageRoot from '@/views/pages/LanguageRoot.vue';
import NotFound from '@/views/pages/NotFound.vue';
import { payment } from '@/modules/Payment';
import user from '@/router/user';

Vue.use(VueRouter);

Vue.use(Meta);
// noinspection JSUnresolvedVariable
const notFound = TARGET_NODE ? [] : [{ path: '*', component: NotFound }];
const devOnly = process.env.NODE_ENV === 'production' ? [] : [];
const view = path => () => import(/* webpackChunkName: "coach[request]" */ `../views/pages/coaching/${path}.vue`);

const getRoutes = async () => [
  {
    path: '/',
    meta: { active: 'main', id: 3, subId: 0 },
    component: view('CoachHome'),
    beforeEnter: (to, from, next) => {
      payment.tossPaymentsRouteHandler(to);
      next();
    },
  },

  signup(),
  ...misc(),
  ...coaching(),
  ...user(), // 코치 전용 유저 기능
  ...term(),
  ...devOnly,
  ...notFound,
];

export default async () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => null,
  routes: [
    {
      path: `/:locale(${i18n.routeMatches()})?`,
      component: LanguageRoot,
      children: await getRoutes(),
    },
    ...notFound,
  ],
});
