import createPlugin from '@shared/plugins/validate';
import { getCharLength } from '@shared/utils/commonUtils';
import defaultRules from '@shared/plugins/validate/rules';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ValidationErrors from '@shared/plugins/validate/components/ValidationErrors.vue';

/**
 * @param {ServiceManager} services
 * @return {{}}
 */
const makeRules = services => ({
  ...defaultRules,
  byEmail: defaultRules.email,
  uploadImage: defaultRules.required,
  requiredNoname: defaultRules.required,
  requiredSelect: defaultRules.required,
  requiredCheck: defaultRules.required,
  isPhoneNumber: value => /^\+(?=(?:\s?\d){7,17}$)\d+(?:\s?\d+){0,3}$/.test(value),
  accountLocked: () => !services.store.getState('auth', 'accountLocked'),
  emailRegistered: value => services.user.checkEmailRegistered(value),
  verificationCode: value => /^[A-Z0-9]+$/.test(value),
  charNameType: value => /^[a-zA-Z0-9가-힣-_.' ]+$/.test(value),
  // [닉네임, 팀명, 채널명] 정책통합으로 regex 통일 (이슈 없을시, 추후 주석삭제) - 태형
  // nickname: value => /^[a-zA-Z0-9가-힣-_.'\s]+$/.test(value),
  // teamName: value => /^[a-zA-Z0-9가-힣-_.' ]+$/.test(value),
  nicknameUsed: value => services.user.checkNicknameRegistered(value),
  summonerName: value => /^[0-9a-zA-Z \u3131-\u314e\u314f-\u3163\uac00-\ud7a3]+$/.test(value),
  hanaCoupon: value => /^Q[0-9A-Z]{6,7}$/.test(value),
  hanaCouponTeam: value => /^T[0-9A-Z]{6,7}$/.test(value),
  kartRider: value => /^[A-Za-z0-9가-힣]+$/.test(value) && getCharLength(value) >= 4 && getCharLength(value) <= 12,
  kartRiderRushPlus: value => /^.{2,12}$/.test(value) && getCharLength(value) >= 4,
  kartRiderRushPlusUid: value => /^[a-zA-Z0-9]{13}$/.test(value),
  randomToYou: value => /^[A-Z0-9]{7}$/.test(value),
  timeRequired: value => value > 0,
  extraRequired: value => value.length > 0,
  sixPassword: value => (value.length === 0 || value.length === 6) && /^[0-9]+$/.test(value),
  // 리그 오브 레전드 특화 포지션
  lolLanes: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => ({valid: value.length >= min && value.length <= max}),
    computesRequired: true
  },
  // 롤 특화챔피언 등록
  preferChamps: {
    // positionMax는 밸리데이션 에러 표현 용도로만 사용
    params: ['minChamp','positionMax'],
    // eslint-disable-next-line
    // 최소 챔프 수
    validate: (value, { minChamp, positionMax }) => {
      // PreferChamps.vue 참조
      if ((value?.length ?? 0) < minChamp) return false;
      return { valid: value.every(c => (c?.lanes || [])?.length <= positionMax ) };
    },
    // 모든 rules는 기본적으로 required가 있는 상태에서만 적용됨.
    // required없이 해당 기능을 사용하고자 할 때 아래 옵션이 반드시 필요
    // https://vee-validate.logaretm.com/v2/guide/custom-rules.html#require-like-rules
    // 매뉴얼상 내용과 실제 코드가 살짝 다름
    // https://github.com/logaretm/vee-validate/blob/v3/src/extend.ts
    // 우리쪽 구현은 rules.js 참조
    computesRequired: true,
  },
  confirm: {
    params: ['target'],
    validate: (value, { target }) => value === target,
  },
  channelNameUsed: {
    params: ['specialId'],
    validate: (value, { specialId }) => services.channel.getValidatedTitle({ specialId, name: value }),
  },
  teamNameUsed: {
    params: ['currentName'],
    validate: (value, { currentName }) => currentName === value || services.team.checkTeamNameUsed(value),
  },
  afterDate: {
    params: ['startTime'],
    validate: (value, { startTime }) => value > 0 && startTime > 0 && value > startTime,
  },
  selectedImage: {
    params: ['key'],
    validate: (value) => Object.values(value).some(is => is),
  },
});

export default {
  install(Vue, { services }) {
    const { validate, extend } = createPlugin();
    // noinspection JSUnresolvedVariable
    if (!TARGET_NODE) {
      const rules = makeRules(services);
      extend(rules);
    }
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('ValidationErrors', ValidationErrors);
    Vue.prototype.$validate = validate;

    // 아래와 같은 코드를 사용할 경우 iteration이 발생하여 에러 토스트의 에러 종류가 바뀜.
    // i.e. 에러 캐치 스코프가 현재 코드 내부인지, 컴포넌트 내부인지에 따라 에러 종류가 바뀜.
    // $getError()를 사용할 것
    // try {
    //   await this.$getError(this.$refs.validator);
    //   this.$scroll.scrollTo(0);
    // } catch (err) {
    //   throw err;
    // }

    // https://github.com/logaretm/vee-validate/issues/2395
    // 위와 같은 케이스에서 에러가 있을 경우 포커싱 및 스크롤 이벤트를 사용하기 위해서 작성
    // UserRecordEmpty.vue 참조
    Vue.prototype.$getError = async function (validator, errorCallback) {
      try {
        await Vue.prototype.$validate(validator, errorCallback);
        return null;
      } catch (err) {
        return err;
      }
    };
  }
}
