<template>
  <div class="header-inner">
    <h2>{{ $t('coaching.PT_review') }}</h2>
    <div class="bonus-point">
      <span class="bonus-point-text">등록 시, 지급 포인트</span>
      <PointIcon :value="50" :showCurrency="false" />
    </div>
  </div>
</template>

<script>
import PointIcon from '@/views/components/coaching/PointIcon.vue';

export default {
  name: 'CoachLessonReviewModalViewHeader',
  components: {
    PointIcon,
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

.header-inner { .flex; .pb(12); border-bottom: solid 2px #d5d5de;
  > h2 {.fs(20); .bold; .c(#191919); .mr(12); align-self: center;}
  .bonus-point { .flex;
    .bonus-point-text {.fs(14); .c(#8300ff); .bold; .mr(8); align-self: center;}
    [point-icon] {
      &.normal > img {.w(38); .h(38);}
      &.normal > .amount {.fs(16); .bold; .c(#8300ff); }
    }
  }
}
</style>
