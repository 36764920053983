<template>
  <component :is="tag" :class="[{'selected': selected}, theme]" basic-button @click="$emit('click',$event)" :disabled="disabled" :data-size="size" v-bind="buttonAttrs" :to="_to">
    <slot />
  </component>
</template>

<script>
import _omit from "lodash/omit";

export default {
  name: 'BasicButton',
  props: {
    tag: { type: String, default: 'button' },
    disabled: { type: Boolean, default: false },
    theme: { type: String, default: 'white' },
    // xs, sm, m, l, xl
    size: { type: String, default: 'm' },
    selected: { type: Boolean, default: false },
    to: {},
    buttonAttrs() {
      // vue fall-through attributes를 사용하기 위한 필터
      return _omit(this.$attrs, ['disabled', 'theme', 'size', 'selected']);
    },
  },
  computed: {
    _to() {
      if (this.tag === 'router-link') return this.to;
      return '';
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

.hover-pressed(@background-color, @border: solid 1px @background-color) {
  @media not all and (pointer: coarse) {
    &:hover, &:active {.bgc(@background-color);border: @border; }
  }
}

[basic-button] { .h(48); .p(0, 32); .flex; .justify-center; .items-center; .bgc(#fff); box-sizing: border-box; .br(4);
  border: solid 1px transparent;.fs(16);
  &:disabled { pointer-events: none; .bgc(#f2f2f4); border: 1px solid #d5d5de;}

  ._c (@color) { .c(@color);
    .fill-target {fill: @color}
  ;
    .stroke-target {stroke: @color}
  }

  &.white {.c(#787781); border: 1px solid #d5d5de;
    @media not all and (pointer: coarse) {
      &:hover { .c(#191919); border: 1px solid #191919; }
    }
    &:active { .c(#787781); }
    &:focus {border: 1px solid #2c81ff;}
    &.selected {border: 1px solid #191919; .c(#191919); }
  }

  &.dark {.c(#fff); border: 1px solid #191919; .bgc(#191919);
    @media not all and (pointer: coarse) {
      &:hover, &:active {.bgc(#7e7e7e); border: 1px solid #7e7e7e; }
    }
    &:focus {.bgc(#191919); border: 1px solid #2c81ff;}
  }

  &.grey { ._c(#444); border: 1px solid #eaeaea; .bgc(#eaeaea);
    @media not all and (pointer: coarse) {
      &:hover, &.test-hover { .c(#191919); border: 1px solid #f7f7f7; .bgc(#f7f7f7); }
    }
    &:active { .c(#444); }
    &:disabled { .bgc(#f2f2f4);._c(rgba(164, 163, 174, 0.3));border-color: transparent;}
    &:focus, &.test-focus {border: 1px solid #2c81ff;}
  }

  &.red { .c(#fff); border: 1px solid rgba(0, 0, 0, 0); .bgc(#d40404);
    .hover-pressed(#b40000);
    &:focus { border: 1px solid #2c81ff; }
  }

  &.blue {.bgc(@c-blue-bg);._c(@c-white);
    .hover-pressed(@c-blue-bg-bright);
  }

  &.text-only-dark-small {.bgc(transparent);.c(@c-black-primary);.p(8, 12);.fs(12);.h(unset);
    &:hover, &:active { text-decoration: underline; text-underline-color: @c-black-primary;text-decoration-thickness: 1px;text-underline-offset: 2px; }
    &:disabled {.c(#ddd); border: solid 1px transparent;}
    //&:focus { border: 1px solid @blue-primary; }
  }

  &.white-dark-small {.bgc(@c-white);.pl(12);.pr(12);.h(32);.fs(12);.c(@c-black-primary);
    border: solid 1px @c-black-primary;font-weight: 500;
    &:disabled {.c(#777);border-color: #ddd;}
  }

  &.white-small {.bgc(@c-white);.pl(12);.pr(12);.h(32);.fs(12);.c(#787781);
    border: solid 1px #d5d5de;font-weight: 500;
    &:disabled {.c(#777);border-color: #d5d5de;}
    @media not all and (pointer: coarse) {
      &:hover { .c(#191919); border: 1px solid #191919; }
    }
  }

  &.text-only-dark {.bgc(transparent);._c(@c-black-primary);.h(unset);
    &:hover, &:active { text-decoration: underline; text-underline-color: @c-black-primary;text-decoration-thickness: 1px;text-underline-offset: 2px; }
    &:disabled {._c(#ddd); border: solid 1px transparent;}
  }

  &.white-dark {.bgc(@c-white);.c(@c-black-primary);
    border: solid 1px @c-black-primary;font-weight: 500;
    &:disabled {.c(#777);border-color: #ddd;}
  }

  // 통일된 disabled 스타일 사용을 위하여 disabled 스타일만 분리
  &.red, &.dark, &.blue {
    &:disabled {.bgc(#ddd); .c(rgba(255, 255, 255, .5));border-color: transparent;}
  }

  &[data-size='sm'] {.fs(12);.lh(16);font-weight: 500;.p(8);.h(30);}
}
</style>
