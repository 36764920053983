<template>
  <div coach-lesson-review-modal-view>
    <div class="info-wrap">
      <div class="info-top">
        <div class="avatar-wrap">
          <avatar dimension="48x48" :info="coachUser" :defaultImgType="'fruit'" userProfile />
        </div>
        <div class="info">
          <div class="info-inner-top">
            <span>{{ coachNickname }}</span>
          </div>
          <div class="info-inner-bottom">
            <span class="lesson-code">{{ $t('coaching.PC_lecture_cord') }} {{ lessonProductOrderId }} |</span>
            <span class="end-date-time">{{ endDate }} | {{ endTime }}</span>
          </div>
        </div>
      </div>
      <div class="info-bottom">
        <h2>{{ name }}</h2>
        <div class="duration-wrap">
          <span class="duration-text">{{ $t('coaching.PC_TITLE_CLASS_TIME') }}</span>
          <span class="duration-time">{{ duration }}</span>
        </div>
      </div>
    </div>
    <div class="rating-wrap">
      <h2 class="rating-text">
        <img src="/img/icon/icon-check-dark.svg">
        {{ $t('coaching.PC_review_star') }}
      </h2>
      <RatingStar v-model="ratingValue" :starSize="32" :step="0.5" activeColor="#2C81FF" strokeColor="#2C81FF" />
    </div>
    <validationObserver ref="validator" name="comment">
      <div class="comment-wrap">
        <h2 class="comment-text">
          <img src="/img/icon/icon-check-dark.svg">
          {{ $t('coaching.PC_review_write') }}
        </h2>
        <validationProvider v-slot="{errors}" name="review" rules="required|length:10,200">
          <TextBox class="comment-text-box" v-model="commentValue" :placeholder="$t('coaching.IG_REQUEST_CANCEL_REASON_TEXT')"></TextBox>
          <validation-errors :errors="errors" />
        </validationProvider>
      </div>
    </validationObserver>
    <div class="control-wrap">
      <ColorButton type="gray" @click="submit">
        <span>{{ $t('coaching.BA_chatting_register') }}</span>
      </ColorButton>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Avatar from '@shared/components/common/Avatar.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import RatingStar from '@/views/components/general/RatingStar.vue';
import TextBox from '@/views/components/common/input/TextBox.vue';

export default {
  name: 'CoachLessonReviewModalView',
  components: { RatingStar, Avatar, ColorButton, TextBox },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    ratingValue: 5,
    commentValue: '',
  }),
  computed: {
    _info() {
      return /** @type {LessonOrder} */ this.info;
    },
    coachUser() {
      return this?._info?.coach?.user;
    },
    coachNickname() {
      return this?.coachUser?.nickname;
    },
    lessonProductOrderId() {
      return this?._info?.lessonProductOrderId;
    },
    endDate() {
      return this.$date(this?._info?.endDateTime, 'dot');
    },
    endTime() {
      return this.$date(this?._info?.endDateTime, 'timeMeridiem');
    },
    name() {
      return this?._info?.lesson?.name;
    },
    duration() {
      if (!this?._info?.endDateTime || !this?._info?.startDateTime) return;
      const diff = moment(this?._info?.endDateTime).diff(this?._info?.startDateTime);
      return moment.utc(diff).format('HH:mm:ss');
    },
  },
  methods: {
    async submit() {
      await this.$validate(this.$refs.validator);
      this.$emit('submit', { comment: this.commentValue, rating: this.ratingValue });
    },
    getRandomContent() {
      const codes = ['coaching.PC_REVIEW_WRITE_AUTO_A', 'coaching.PC_REVIEW_WRITE_AUTO_B', 'coaching.PC_REVIEW_WRITE_AUTO_C'];
      const index = Math.round(Math.random() * (codes.length - 1));
      return this.$t(codes[index]);
    },
  },
  mounted() {
    this.commentValue = this.getRandomContent();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-lesson-review-modal-view] { .pb(40);
  .info-wrap { .mt(20);
    .info-top {.flex; .items-center; .mb(18);
      .avatar-wrap { .w(48); .h(48);
        [avatar] {.w(48); .h(48);}
      }
      .info { .flex; flex-direction: column; .ml(4);
        .info-inner-top > span { .fs(14); .bold; }
        .info-inner-bottom {
          > span { .c(#a4a3ae); .block; float: left;}
          .lesson-code {.mr(2);}
        }
      }
    }
    .info-bottom { .flex; .items-center; .bgc(#ebebf0); .p(15, 20); .br(20); .mb(20);
      .duration-wrap { .p(10, 30); .br(20); .ml(auto); .bgc(#fff);
        .duration-text {.c(#787781); .fs(14);}
        .duration-time {.fs(14); .ml(4);}
      }
    }
  }
  .rating-wrap {
    .rating-text {.fs(16); .bold; .lh(28); .c(#191919);
      img {.w(20); .mr(2); }
    }
    [rating-star] { .flex; .items-center; .justify-center; .m(50, 0);
      .score {.fs(14); .c(#2c81ff); .bold; }
    }
  }
  .comment-wrap {
    .comment-text { .fs(16); .bold; .lh(28); .c(#191919); }
    .comment-text-box { .w(100%); .mt(30); .h(200); .fs(14);}
    [validation-errors] {.h(12); .mt(4);}
    .error {text-align: right; .c(#ffc70e); .fs(12); .lh(12); }
  }
  .control-wrap { .flex; .justify-center; .mt(40);
    [color-button] {.w(240); }
  }
}
</style>
