<template>
  <component :is="modalComponent" modal-child-rep-success class="custom-form" @close="$emit('close')">
    <template #title>
      <h1 class="title">법정대리인 결제 동의 완료</h1>
    </template>
    <p class="success-description">법정대리인 결제 동의가 무사히 완료되었습니다!</p>
    <hr class="divider" />
    <div>
      <p class="content-label">법정대리인 이메일 주소</p>
      <p class="content-callout">{{ email }}</p>
    </div>
    <div>
      <p class="content-label">동의 기간</p>
      <p class="content-callout">{{ textDuration }}</p>
    </div>
    <primary-button class="btn-modal" color="red" @click="$emit('close')">확인</primary-button>
  </component>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import { addDuration } from '@shared/utils/timeUtils';

export default {
  components: { PrimaryButton },
  props: {
    modalComponent: {
      type: Object,
      default: () => Modal,
    },
    options: {
      type: Object,
      default: () => ({
        periodMonth: 0,
        // agreeDatetime: 0,
        // expireDatetime: 0,
        email: '-',
      }),
    },
  },
  computed: {
    textDuration() {
      const now = new Date().getTime();
      const startText = this.$date(now, 'dot');
      const endDate = addDuration(now, { months: this.options?.periodMonth });
      const endText = this.$date(endDate, 'dot');
      return `${startText} ~ ${endText}`;
    },
    email() {
      return this.options?.email;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[modal-child-rep-success] {.noto;
  .title {.bold;.fs(24);.lh(100%);text-align: center;.mb(24);.mt(14)}
  .icon-exclamation {fill: @c-red-strong;.wh(24, 27.15);.ml(auto);.mr(auto);.block;}
  .success-description {.mb(24);text-align: center;}
  .divider {.mb(24)}
  .content-label {.fs(14);.lh(21);}
  .content-callout {.h(74);.bgc(#f7f7fb);.mt(8);.mb(24);.pt(30);.pb(30);text-align: center;.fs(14);.lh(100%);.bold;}
  .btn-modal {.mb(40);.mt(calc(40px - 24px));.w(100%)}
}
</style>
