<template>
  <div error-page>
    <div class="vertical-center">
      <lottie src="/lottie/loop-anim.json" class="logo-area" autoplay loop />
      <div>
        <h1 class="error-notice">OOPS!</h1>
        <p class="error-text">{{ $t('noPage.clean') }}</p>
      </div>
      <locale-link to="/" class="to-home">{{ $t('noPage.goHome') }}</locale-link>
    </div>
  </div>
</template>

<script>
import Lottie from '@shared/components/general/Lottie.vue';

export default {
  name: 'notFound',
  components: { Lottie },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[error-page] { .table; .f; .p(100, 0); .min-h(100vh); .bgc(#000); .tc;
  .vertical-center {
    display: table-cell;
    vertical-align: middle;
    max-width: 375px;
    margin: 0 auto;
    text-align: center;
  }
  .logo-area {
    margin: 0 auto 56px;
    height: 375px;
  }
  .error-notice {
    margin-bottom: 6px;
    font-size: 48px;
    line-height: 1;
    color: #fff;
    font-family: 'trade-gothic-next', 'Noto Sans', 'Malgun Gothic', '맑은 고딕', sans-serif;
  }
  .error-text {
    color: #bcbcbc;
    font-size: 14px;
  }
  .to-help {
    text-decoration: none;
    margin-top: 12px;
    font-size: 12px;
    display: inline-block;
    color: #bcbcbc;
  }
  .to-home {
    margin-top: 44px;
    text-decoration: none;
    display: inline-block;
    width: 300px;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    border-radius: 2px;
    border: solid 1px #777;
    background-color: #1e1e1e;
    color: #bcbcbc;
  }
  .to-home:hover, .to-home:focus {
    background-color: #777;
    color: #fff;
  }
}
</style>
