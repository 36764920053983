import { hasRole, isAuthenticated, permitAll, expiredEvent } from '@shared/utils/routerUtils';
import { coachIdMatch } from "shared/utils/routerUtils";

export default [
  { path: '/signup/reverification**', access: isAuthenticated() },
  { path: '/question/lol/*', access: permitAll() },
  { path: '/event/invitation', access: expiredEvent() },
  { path: '/coach', access: permitAll() },
  { path: '/coach/*/class-edit', access: coachIdMatch() },
  { path: '/coach/**', access: permitAll() },
  { path: '/coach-center**', access: hasRole('coach') },
  { path: '/coach-finder', access: permitAll() },
  { path: '/tv/lol**', access: permitAll() },
  { path: '/me/class', access: isAuthenticated() },
  { path: '/me/**', access: isAuthenticated() },
  // { path: '/lol**', redirect: { to: '/coach-center', role: hasRole('coach') }, access: permitAll() }, 코치홈
  // { path: '/questions/lol', redirect: { to: '/coach-center', role: hasRole('coach') }, access: permitAll() }, questions
  { path: '/news/edit**', access: hasRole('organizer') },
  { path: '/news**', access: permitAll() },
  // { path: '/', redirect: { to: '/coach-center', role: hasRole('coach') }, access: permitAll() }, 홈
];
