import { makeMutations } from '@shared/utils/storeUtils';

const state = {
  tempId: null,
  fromUrl: null,
  olderFourteen: true,
  agreeTermsConditions: false,
  agreePrivacyPolicy: false,
  agreeProvideEmail: false,
  email: '',
  password: '',
  passwordConfirm: '',
  nickname: '',
  document: '',
  duplicatedVerificationInfo: null,
};

export default () => ({
  namespaced: true,
  state: Object.assign({}, state),
  getters: {
    allAgreeChecked(state) {
      return state.agreeTermsConditions && state.agreePrivacyPolicy && state.agreeProvideEmail;
    }
  },
  mutations: {
    ...makeMutations(state),
    queryCondition(state, {ti, fromUrl}) {
      state.tempId = ti;
      state.fromUrl = fromUrl;
    },
    duplicatedVerification(state, info) {
      state.duplicatedVerificationInfo = info;
    }
  },
});
