export default () => ({
  namespaced: true,
  state: {
    /** @type {number} */
    unReadCount: 0,
    /** @type {SendBird.GroupChannel[]} */
    channelList: [],
    /** @type {Array<SendBird.UserMessage | SendBird.AdminMessage>} */
    receivedMessages: [],
    /** @type {?{selectedChannel: SendBird.GroupChannel, query: PreviousMessageListQuery}} */
    activeChannelQuerySet: null,
    /** @type {ChatUserResponse} */
    sbUserInfo: null,
  },
  getters: {
    /** @return {string} */
    sbUserId: (state) => state.sbUserInfo?.userId ?? '',
    hasCoachDM: (state) => {
      const channelList = state?.channelList ?? [];
      return channelList?.findIndex(c => c?.customType === 'COACH_DM') !== -1;
    }
  },
  mutations: {
    setUnReadCount(state, count) {
      state.unReadCount = count;
    },
    setChannelList(state, newChannelList) {
      state.channelList = newChannelList;
    },
    setReceivedMessages(state, newReceivedMessages) {
      state.receivedMessages = newReceivedMessages;
    },
    setActiveChannelQuerySet(state, newActiveChannelQuerySet) {
      state.activeChannelQuerySet = newActiveChannelQuerySet;
    },
    setSbUserInfo(state, newSbUserInfo) {
      state.sbUserInfo = newSbUserInfo;
    }
  },
});
