<template>
  <div id="app" class="b-lvup" :class="[lang, matchedMedia, touch ? 'touch' : 'no-touch', hasTopBanner? 'with-top-banner': 'no-top-banner']">
    <client-only>
      <top-banner :top-banners="topBanners" v-if="hasTopBanner" />
    </client-only>
    <HeaderNav />
    <div class="contents-wrapper" :class="{'no-sub': noSub,'no-header': $route.meta.hideHeader, 'no-scroll': !isBackgroundScrollMovable, 'with-top-banner': hasTopBanner}">
      <router-view />
      <AppFooter v-if="!ftHide" />
    </div>
    <ChattingWidget v-if="isLogin" />
  </div>
</template>

<script>
import { getter, state } from '@shared/utils/storeUtils';
import HeaderNav from '@/views/layout/HeaderNav.vue';
import AppFooter from '@/views/layout/AppFooter.vue';
import ChattingWidget from '@/views/components/chatting/ChattingWidget.vue';
import TopBanner from '@/views/components/coaching/TopBanner.vue';

export default {
  name: 'App',
  components: { AppFooter, HeaderNav, ChattingWidget, TopBanner },
  data() {
    return {
      noSub: true,
    };
  },
  metaInfo() {
    // noinspection JSCheckFunctionSignatures
    return {
      meta: [
        { vmid: 'title', content: this.$t('meta.title') },
        { vmid: 'description', content: this.$t('meta.description') },
        { vmid: 'keywords', content: this.$t('meta.keywords') },
        { vmid: 'image', content: 'https://d3hqehqh94ickx.cloudfront.net/prod/images/sns/SNS_Share_Lvup_R2.png' },
      ],
    };
  },
  watch: {
    $route: 'routeUpdate',
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    isCoach: getter('auth', 'isCoach'),
    hasTopBanner: getter('info', 'hasTopBanner'),
    topBanners: state('info', 'topBanners'),
    touch() {
      return this.$store.state.browser.touch;
    },
    matchedMedia() {
      return this.$store.state.browser.matchedMedia;
    },
    ftHide() {
      return Boolean(this.$route.meta.ftHide);
    },
    lang() {
      return this.$i18n('lang');
    },
    isBackgroundScrollMovable() {
      return this.$store.state.ui.isBackgroundScrollMovable;
    },
  },
  methods: {
    /**
     * @typedef {import('vue-router').Route} VueRoute
     */
    /**
     * @param {VueRoute} route
     */
    routeUpdate(route) {
      const subId = this.$route?.meta?.subId;
      const isCoachingRoute = route?.meta?.category === 'coaching';
      // 2021.10월 기준
      // 코치가 코칭페이지를 접근할 때에는 서브메뉴가 모두 표기되지 않는 정책.
      // 구두로 채성님이 전달하신 내용이며, 해당 내용은 11월 초에 문서화될 예정
      this.noSub = subId === undefined || subId < 0 || (this.isCoach && isCoachingRoute);
    },
  },
  created() {
    this.routeUpdate(this.$route, null);
  },
};
</script>

<style lang="less">
@import '~@/less/common.less';
@header-nav-height-with-shadow: 0px;
#app {
  > .contents-wrapper { .rel; .min-h(100vh); overflow-x: hidden;
    &.with-top-banner { .pt(70); }
    &.no-header { .pt(0);}
    > :first-child { .t1(68); .t2(80); .min-h(calc(100vh - @_t1 - @_t2)); }
  }
  &.clear { display: none; }
}

@media (@tl-up) {
  #app {
    > .contents-wrapper {
      &.with-top-banner { .pt(calc(@header-nav-height-with-shadow + @banner-height-mobile));}
      &.no-header { .pt(0); }
    }
  }
}

@media (@ds-up) {
  #app {
    > .contents-wrapper {
      &.with-top-banner { .pt(calc(@header-nav-height-with-shadow + @banner-height-pc));}
      > :first-child { .t1(68); .min-h(calc(100vh - @_t1)); }
    }
  }
}
</style>
