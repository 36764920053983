<template>
  <component :is="modalComponent" modal-child-rep-fail class="custom-form" @close="$emit('close')">
    <template #title>
      <svg-fail class="icon-exclamation"></svg-fail>
      <h1 class="title">법정대리인 인증 실패</h1>
    </template>
    <p class="fail-description">법정대리인은 만 19세 미만인 회원과 나이차가 만 18세 이상인 성인이어야 합니다.</p>
    <p class="fail-description warning">보호자의 생년월일을 다시 한 번 확인해 주세요.</p>
    <primary-button class="btn-modal" color="red" @click="onClose">확인</primary-button>
  </component>
</template>

<script>
import SvgFail from '@shared/graphics/svg-fail.vue';
import Modal from '@shared/components/common/Modal.vue';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';

export default {
  components: { SvgFail, PrimaryButton },
  props: {
    modalComponent: {
      type: Object,
      default: () => Modal,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[modal-child-rep-fail] {
  .title {.bold;.fs(20);.lh(30);text-align: center;.mb(24);.mt(14);.mb(40)}
  .icon-exclamation {fill: @c-red-strong;.wh(24, 27.15);.ml(auto);.mr(auto);.block;}
  .fail-description {.fs(14);.lh(20);text-align: center;
    &.warning {.c(@c-red-strong)}
  }
  .btn-modal {.w(100%);.mb(40);.mt(40)}
}
</style>
