<template>
  <div :style="{display:'none'}" class="value-watch"></div>
</template>
<script>
export default {
  props: ['value'],
  watch: {
    value: {
      immediate: true,
      handler() {
        this.$emit('change', this.value);
      }
    }
  }
};
</script>