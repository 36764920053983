<template>
  <header header-nav class="b-global-header" v-if="!$route.meta.hideHeader" :class="{hidden: hiddenHeader,'with-top-banner': hasTopBanner}">
    <nav class="b-gnb">
      <div class="b-container">
        <div class="b-gnb-content">
          <div class="b-gnb-service">
            <a href="https://dak.gg" class="b-logo">
              <img :src="SvgLogoDakGG" alt="Logo" />
            </a>
            <div class="b-gnb-service-gradation">
              <div class="b-gnb-service-content">
                <div class="b-service">
                  <a href="https://dak.gg" class="b-service-item">{{ $t('_.dakgg') }}</a>
                  <div class="b-service-divider"></div>
                  <a href="https://lvup.gg" class="b-service-item">{{ $t('_.lvupgg') }}</a>
                  <div class="b-service-divider"></div>
                  <a href="https://wcg.lvup.gg" class="b-service-item hot">WCG</a>
                  <div class="b-service-divider"></div>
                  <a href="https://www.youtube.com/c/GCL%EC%A7%80%EC%94%A8%EC%97%98" target="_blank" class="b-service-item">GCL
                    <img :src="SvgIcoExternal" class="b-external" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="b-container">
      <div class="b-header">
        <div class="b-header-left">
          <a @click="logoClick">
            <img src="https://d3hqehqh94ickx.cloudfront.net/gcaon/Frame.png" alt="게임코치온라인" width="45" height="32" />
          </a>
          <h1 class="b-service-title">
            <a @click="logoClick" class="b-service-title-link">{{ $t('_.gco') }}</a>
          </h1>
        </div>
        <div class="b-header-right">
          <client-only>
            <div class="b-login-info" v-if="isLogin">
              <Avatar :info="myInfo" @click="myMenuOn = true" dimension="80x80" userProfile class="b-login-info-image" style="cursor: pointer" />
              <div class="b-login-info-list" v-if="myMenuOn">
                <a @click="goMyPage({name: 'CoachUserClasses'})" class="b-login-info-list-item">{{ $t('_.myClassRoom') }}</a> <a v-if="!isCoach" @click="goMyPage({name: 'CoachUserPoint'})" class="b-login-info-list-item">{{ $t('_.myPoint') }}</a> <a v-if="!isCoach" @click="goMyPage({name: 'CoachUserQuestions'})" class="b-login-info-list-item">{{ $t('_.myQuestion') }}</a> <a @click="goMyPage({name: 'CoachUserLikedCoaches'})" class="b-login-info-list-item">{{ $t('_.myBookmark') }}</a>
                <a @click="goMyPage({name: 'CoachUserModify'})" class="b-login-info-list-item">{{ $t('_.myInfo') }}</a> <a @click="logout" class="b-login-info-list-item">{{ $t('_.logout') }}</a>
              </div>
            </div>
            <a @click="login" class="b-header-login" v-else>
              <img :src="SvgIcoArrowRightToBracket" alt="Login">
            </a>
          </client-only>
        </div>
      </div>
      <div class="b-header-mobile">
        <button class="b-header-bars b-header-absolute-left" @click="showMobileNav">
          <img :src="SvgIcoBars" alt="bars" width="18" height="20">
        </button>
        <div class="b-header-title" @click="logoClick">
          <img src="https://d3hqehqh94ickx.cloudfront.net/gcaon/Frame.png" alt="게임코치온라인" width="28" height="20" />
          <h1 class="b-header-title-text">{{ $t('_.gco') }}</h1>
        </div>
      </div>
    </div>
    <div class="b-lnb">
      <div class="b-lnb-pc">
        <div class="b-lnb-pc-transparent"></div>
        <div class="b-lnb-pc-container">
          <div class="b-container">
            <div class="b-lnb-pc-content">
              <div class="b-lnb-pc-list">
                <div class="b-lnb-pc-menu" :class="{ active: active === 'main' }">
                  <LocaleLink to="/" class="b-lnb-pc-menu-text">{{ $t('_.main') }}</LocaleLink>
                </div>
              </div>
              <div class="b-lnb-pc-list">
                <div class="b-lnb-pc-menu" :class="{ active: active === 'recommend' }">
                  <LocaleLink to="/coach-matching" class="b-lnb-pc-menu-text">{{ $t('_.recommend') }}</LocaleLink>
                </div>
              </div>
              <div class="b-lnb-pc-list">
                <div class="b-lnb-pc-menu" :class="{ active: active === 'findCoach' }">
                  <LocaleLink to="/coach-finder" class="b-lnb-pc-menu-text">{{ $t('_.findCoach') }}</LocaleLink>
                </div>
              </div>
              <div class="b-lnb-pc-list">
                <div class="b-lnb-pc-menu" :class="{ active: active === 'ask' }">
                  <LocaleLink to="/questions/lol" class="b-lnb-pc-menu-text">{{ $t('_.ask') }}</LocaleLink>
                </div>
              </div>
              <div class="b-lnb-pc-list">
                <div class="b-lnb-pc-menu" :class="{ active: active === 'coachTv' }">
                  <LocaleLink to="/tv/lol" class="b-lnb-pc-menu-text">{{ $t('_.coachTv') }}</LocaleLink>
                </div>
              </div>
              <div class="b-lnb-pc-list">
                <div class="b-lnb-pc-menu" :class="{ active: active === 'news' }">
                  <LocaleLink to="/news" class="b-lnb-pc-menu-text">{{ $t('_.news') }}</LocaleLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="b-lnb-mobile" v-if="mobileNavOn" :class="{'with-top-banner': hasTopBanner}">
        <header class="b-lnb-mobile-header">
          <div class="b-lnb-mobile-header-left">
            <img src="https://d3hqehqh94ickx.cloudfront.net/gcaon/Frame.png" alt="게임코치온라인" width="28" height="20" />
            <h2 class="b-lnb-mobile-header-title">{{ $t('_.gco') }}</h2>
          </div>
          <div class="b-lnb-mobile-header-right">
            <div class="b-locale mobile">
              <button class="b-locale-button" @click="toggleLocaleList" v-if="false">
                <img :src="SvgIcoGlobe2" class="b-locale-globe" />
              </button>
              <LocaleList v-if="localeListOn" />
            </div>
            <button class="b-lnb-mobile-header-close" @click="hideMobileNav">
              <img :src="SvgIcoClose" alt="Close" width="13" height="20">
            </button>
          </div>
        </header>
        <client-only>
          <div class="b-lnb-mobile-login">
            <template v-if="isLogin">
              <span class="b-lnb-mobile-login-link">
                <Avatar @click="goMyPage({name: 'CoachUserClasses'})" :info="myInfo" dimension="80x80" userProfile class="b-lnb-mobile-login-image" />
                <strong @click="goMyPage({name: 'CoachUserClasses'})">{{ myInfo.nickname }}</strong>
                <img @click="goMyPage({name: 'CoachUserClasses'})" :src="SvgIcoArrowRight" alt="Arrow Right" width="8" height="12">
              </span>
              <a @click="logout" class="b-lnb-mobile-login-button">로그아웃</a>
            </template>
            <template v-else>
              <a @click="login" class="b-lnb-mobile-login-link">
                <img :src="SvgImgPlaceholder" alt="Login" class="b-lnb-mobile-login-image">
                <span><strong>로그인</strong>이 필요합니다</span>
                <img :src="SvgIcoArrowRight" alt="Arrow Right" width="8" height="12">
              </a> <a @click="login" class="b-lnb-mobile-login-button">로그인</a>
            </template>
          </div>
        </client-only>
        <div class="b-lnb-mobile-menu">
          <LocaleLink to="/" class="b-lnb-mobile-menu-link">{{ $t('_.main') }}</LocaleLink>
          <LocaleLink to="/coach-matching" class="b-lnb-mobile-menu-link">{{ $t('_.recommend') }}</LocaleLink>
          <LocaleLink to="/coach-finder" class="b-lnb-mobile-menu-link">{{ $t('_.findCoach') }}</LocaleLink>
          <LocaleLink to="/questions/lol" class="b-lnb-mobile-menu-link">{{ $t('_.ask') }}</LocaleLink>
          <LocaleLink to="/tv/lol" class="b-lnb-mobile-menu-link">{{ $t('_.coachTv') }}</LocaleLink>
          <LocaleLink to="/news" class="b-lnb-mobile-menu-link">{{ $t('_.news') }}</LocaleLink>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import SvgIcoGlobe from '@bigpi-dakgg/style/dist/images/ico-globe.svg';
import SvgIcoGlobe2 from '@bigpi-dakgg/style/dist/images/ico-globe2.svg';
import SvgIcoArrowDown from '@bigpi-dakgg/style/dist/images/ico-arrow-down.svg';
import SvgIcoCheck from '@bigpi-dakgg/style/dist/images/ico-check.svg';
import SvgIcoCheckActive from '@bigpi-dakgg/style/dist/images/ico-check-active.svg';
import SvgLogoLvup from '@bigpi-dakgg/style/dist/images/logo-lvup.svg';
import PngImgPlaceholderProfile from '@bigpi-dakgg/style/dist/images/img-placeholder-profile.png';
import SvgIcoBars from '@bigpi-dakgg/style/dist/images/ico-bars.svg';
import SvgIcoClose from '@bigpi-dakgg/style/dist/images/ico-close.svg';
import SvgImgPlaceholder from '@bigpi-dakgg/style/dist/images/img-placeholder.svg';
import SvgIcoArrowRight from '@bigpi-dakgg/style/dist/images/ico-arrow-right.svg';
import SvgIcoExternal from '@bigpi-dakgg/style/dist/images/ico-external.svg';
import SvgLogoDakGG from '@bigpi-dakgg/style/dist/images/logo-dakgg.svg';
import SvgIcoArrowRightToBracket from '@bigpi-dakgg/style/dist/images/ico-arrow-right-to-bracket.svg';

import { getter, state } from '@shared/utils/storeUtils';
import Avatar from '@shared/components/common/Avatar.vue';
import LocaleList from '@/views/components/common/LocaleList.vue';

export default {
  name: 'HeaderNav',
  components: {
    LocaleList,
    Avatar,
  },
  lexicon: 'nav',
  data() {
    return {
      SvgIcoGlobe,
      SvgIcoGlobe2,
      SvgIcoArrowDown,
      SvgIcoCheck,
      SvgIcoCheckActive,
      SvgLogoLvup,
      PngImgPlaceholderProfile,
      SvgIcoBars,
      SvgIcoClose,
      SvgImgPlaceholder,
      SvgIcoArrowRight,
      SvgIcoExternal,
      SvgLogoDakGG,
      SvgIcoArrowRightToBracket,
      localeListOn: false,
      mobileNavOn: false,

      mainId: -1,
      subId: -1,
      coachSubId: -1,
      mainMenuList: [
        { label: this.$t('_.introduction'), path: '/' },
        { label: this.$t('_.coachRecommendation'), path: '/coach-matching' },
        { label: this.$t('_.coachFinder'), path: '/coach-finder' },
        { label: this.$t('_.coachQnA'), path: '/questions/lol' },
        { label: this.$t('_.coachTV'), path: '/tv/lol' },
        { label: this.$t('_.news'), path: '/news' },
      ],
      coachMenuList: [
        { label: this.$t('_.coachHome'), path: '/coach-center/lol' },
        { label: this.$t('_.coachSaleHistory'), path: '/coach-center/sales-history' },
        { label: this.$t('_.coachSettlementHistory'), path: '/coach-center/settlement-history' },
        { label: this.$t('_.coachFinder'), path: '/coach-finder' },
        { label: this.$t('_.coachTV'), path: '/tv/lol' },
        { label: this.$t('_.news'), path: '/news' },
      ],
      tree: null,
      myMenuOn: false,
      sideMenuOn: false,
      lastScrollY: 0,
      hiddenHeader: false,
    };
  },
  computed: {
    active() {
      return this.$route.matched.reduce((p, v) => v.meta.active || p, '');
    },
    isLogin: getter('auth', 'isLogin'),
    myInfo: state('auth', 'myInfo'),
    hasTopBanner: getter('info', 'hasTopBanner'),
    isCoach() {
      return this.myInfo?.isCoach ?? false;
    },
  },
  watch: {
    $route: 'update',
    myInfo: 'initialize',
  },
  methods: {
    toggleLocaleList() {
      this.localeListOn = !this.localeListOn;
    },
    documentDown(e) {
      if (this.myMenuOn && !document.querySelector('.b-login-info-list').contains(e.target)) {
        this.myMenuOn = false;
      }
      if (this.localeListOn && !document.querySelector('.b-locale-list').contains(e.target)) {
        this.localeListOn = false;
      }
    },
    logoClick() {
      if (this.$route.path === '/' || this.$route.path === this.$i18n('path')) {
        window.location.reload();
      } else {
        this.$router.push(this.$i18n('path') || '/');
      }
    },
    showMobileNav() {
      this.mobileNavOn = true;
      this.$scroll.freeze();
    },
    hideMobileNav() {
      this.mobileNavOn = false;
      this.$scroll.release();
    },

    login() {
      this.$services.auth.oAuthLogin();
    },
    /** @param {import('vue-router').RawLocation} route */
    goMyPage(route) {
      this.closeOptions();
      this.closeMyMenu();
      this.closeSideMenu();
      this.$router.push(route);
    },
    openAcademy() {
      window.open('https://gamecoachacademy.com/', '_blank');
    },
    logout() {
      this.$services.auth.logout();
    },
    update() {
      this.hideMobileNav();
      this.subId = typeof (this.$route.meta.subId) === 'number' ? this.$route.meta.subId : -1;
      this.coachSubId = typeof (this.$route.meta.coachSubId) === 'number' ? this.$route.meta.coachSubId : -1;
      this.mainId = this.subId >= 0 && !this.$route.meta.id ? this.tree.length - 1 : this.$route.meta.id;
    },
    clickedMainIndex(info) {
      this.closeSideMenu();
      if (info.path) {
        if (info.path.indexOf('http://') === 0 || info.path.indexOf('https://') === 0) {
          window.location.href = info.path;
        } else {
          this.$router.push(info.path);
        }
      } else if (info.children) {
        this.$router.push(info.children[0].path);
      }
    },
    toggleMyMenu() {
      this.myMenuOn = !this.myMenuOn;
      if (this.myMenuOn) {
        this.$services.chat.closeChatModal(this);
      }
    },
    closeMyMenu() {
      this.myMenuOn = false;
    },
    closeOptions() {
      this.myMenuOn = false;
    },
    toggleSideMenu() {
      this.sideMenuOn = !this.sideMenuOn;
      if (this.sideMenuOn) {
        this.$store.commit('ui/setBackgroundScrollMovable', false);
      } else {
        this.$store.commit('ui/setBackgroundScrollMovable', true);
      }
    },
    closeSideMenu() {
      this.sideMenuOn = false;
      this.$store.commit('ui/setBackgroundScrollMovable', true);
    },
    initialize() {
      this.tree = this.isCoach ? [...this.coachMenuList] : [...this.mainMenuList];
    },
    checkPageMatch(routeName = '') {
      return this.$route.name === routeName;
    },
    hasUrlNow(url) {
      return this.$route.path.includes(url);
    },
    checkedScroll() {
      if (window.scrollY > 100 && window.scrollY > 0) {
        this.hiddenHeader = this.lastScrollY - window.scrollY < 0;
        this.lastScrollY = window.scrollY;
      } else {
        this.hiddenHeader = false;
      }
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    this.update();

    document.addEventListener('mousedown', this.documentDown);

    window.addEventListener('scroll', this.checkedScroll);
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.documentDown);

    window.removeEventListener('scroll', this.checkedScroll);
  },
};
</script>
<style lang="less">
@import '~@/less/proj.less';
.b-lvup [header-nav].b-global-header { .z(11); .rel; .t(0); .bgc(#26292d);
  img { .block; }
  .b-lnb-pc-transparent { .bgc(#1f2225); }
  &.with-top-banner { .t(@banner-height-pc); }
  .b-gnb-service-content { .pr(10); }
  .b-header {
    .alarm { .rel; .mt(4); .mr(41);
      &:after { .cnt; .wh(1, 20); .abs; .rt(-21, 4); .bgc(@c-base-gray);}
    }
  }
  .b-header-mobile {
    .b-header-right { .w(0);
      .b-login-info { .l(-10); }
    }
  }
  .b-login-info-list { .block; .z(1010); .min-w(150px); }

  .b-lnb-pc-sub-menu:hover { .bgc; .rel;
    &.active { .c(#fff); }
    &:before { .cnt; .block; .abs; .bgc(#2d2f37); .f; .lt; .br(8); .z(-1); .ph(8); .ml(-8); }
  }

  .b-lnb-mobile { .block;
    &.with-top-banner { .t(@banner-height-pc); }
    @media (@ml-down) {
      &.with-top-banner { .t(@banner-height-mobile); }
    }
  }
  .b-locale-list { .block; .t(100%); }
  .b-lnb-mobile-menu { height: calc(var(--innerHeight) - 177px); }

  .b-service-item { .rel;
    &.hot:after { .cnt; .wh(4, 4); .abs; .br(2); .bgc(#ff4655); .rt(-6, 0); }
  }

  @media (@ml-down) {
    &.with-top-banner { .t(@banner-height-mobile); }
  }
}

</style>
