import { values } from '@shared/utils/commonUtils';
import * as Sentry from '@sentry/vue';
import { NoToast as _NoToast } from '@shared/modules/ToastErrorHandler';
import CoachExpiredTokenModal from '@/views/components/coaching/CoachExpiredTokenModal.vue';

export const NoToast = _NoToast;

/**
 * 작성자: 김병규
 * 코칭서비스 별도로 센트리 연동을 진행하기위해 ErrorHandler를 Shared를 참조하지 않고 재정의 함
 */
export default (Vue, app, store) => {
  const errorHandler = error => {
    console.trace("error", error);
    Sentry?.captureException(error);
    if (!error || error === NoToast || error?.name === 'SendBirdException') return;
    let message;
    let translate = true;
    if (typeof error === 'string') {
      message = error;
    } else if (Array.isArray(error)) {
      if (store.getters['auth/isLogin'] && error[error.length - 1] === 'UNAUTHORIZED') {
        app.$modal(CoachExpiredTokenModal);
        return;
      }
      if (!app.$te(error)) error[error.length - 1] = 'DEFAULT';
      message = error;
    } else if (error._rule_) {
      message = app.$tv(error);
      translate = false;
    } else if (error.code in values('301', '302', '401', '403')) { // router exception ignore
      return;
    } else if (error.code === 'DEFAULT') {
      message = 'unhandledApiError';
    } else if (error.code === 'UNAUTHORIZED') {
      if (store.getters['auth/isLogin']) {
        app.$modal(CoachExpiredTokenModal);
        return;
      }
    } else {
      message = 'unhandledError';
    }

    app.$toast(message, { translate, type: 'fail' });
  };

  Vue.config.errorHandler = errorHandler;
  window.addEventListener('unhandledrejection', event => event.promise.catch(errorHandler));
};
