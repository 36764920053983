<template>
  <div chatting-container>
    <!-- 여기서 v-show로 처리한 이유는 채팅룸에 갔다가 다시 채팅 리스트 화면으로 돌아올때 스크롤 위치를 유지 시켜주기 위해서 입니다. -->
    <div v-show="!isRoomSelected" class="chatting-home">
      <div class="header">
        <h2><em>CHAT</em>{{ $t('_.title') }}</h2>
      </div>
      <chatting-list :info="groupChannelList" @open="changeRoom" />
    </div>
    <chatting-room v-if="isRoomSelected" ref="chattingRoom" :info="_get(activeChannelQuerySet, 'selectedChannel')" @back="back" @close="close" />
    <button class="close-btn" @click="close">
      <svg-x />
    </button>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { state, getter } from '@shared/utils/storeUtils';
import SvgX from '@shared/graphics/svg-x.vue';
import ChattingList from '@/views/components/chatting/ChattingList.vue';
import ChattingRoom from '@/views/components/chatting/ChattingRoom.vue';

export default {
  name: 'ChattingContainer',
  lexicon: 'chatting',
  singleModal: true,
  components: { SvgX, ChattingRoom, ChattingList },
  props: {
    options: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      /** @type {?number} */
      fetcherTimer: null,
    };
  },
  computed: {
    /** @type {?{selectedChannel: SendBird.GroupChannel, query: PreviousMessageListQuery}} */
    activeChannelQuerySet: state('chat', 'activeChannelQuerySet'),
    /** @type {SendBird.GroupChannel[]} */
    groupChannelList: state('chat', 'channelList'),
    /** @type {boolean} */
    isRoomSelected() {
      return Boolean(this.activeChannelQuerySet?.selectedChannel);
    },
    isCoach: getter('auth', 'isCoach'),
  },
  methods: {
    _get,
    /**
     * @param {SendBird.GroupChannel} roomInfo
     */
    changeRoom(roomInfo) {
      if (roomInfo.customType === 'ARENA_APPLY') {
        window.location.href = `${process.env.VUE_APP_LVUP_URL}arena/${JSON.parse(roomInfo.data).arenaId}/info`;
      }
      this.roomInfo = roomInfo;
      this.$services.chat.setActiveChannel(roomInfo);
    },
    close() {
      this.$services.chat.initActiveChannel();
      this.$emit('close');
    },
    back() {
      this.$services.chat.initActiveChannel();
    },
    lessonProductOrderHandler(arg) {
      if (this.isCoach && this.isRoomSelected) {
        const members = _get(this.activeChannelQuerySet, 'selectedChannel.members');
        let flag = false;

        for (let i = 0; i < members.length; i++) {
          if (arg?.userId === members[i]?.metaData?.lvupUserId) {
            flag = true;
            break;
          }
        }

        if (flag) this.$refs?.chattingRoom?.getBasicInfoInfo();
      }
    },
  },
  async mounted() {
    if (this.options.close) {
      this.close();
      return;
    }
    window.history.pushState(null, 'chatting-modal', window.location.href);
    window.addEventListener('popstate', this.close);
    await this.$services.chat.fetchChannelList();
    this.fetcherTimer = setInterval(async () => {
      await this.$services.chat.fetchChannelList();
    }, 5000);

    const privateChannel = `private-${this.$store.state.auth.userId}`;
    this.$bindPushEvent(privateChannel, 'lesson_product_order_ordered', this.lessonProductOrderHandler);
    this.$bindPushEvent(privateChannel, 'lesson_product_order_canceled', this.lessonProductOrderHandler);
  },
  beforeDestroy() {
    // history.back();
    window.removeEventListener('popstate', this.close);
    if (this.fetcherTimer) clearInterval(this.fetcherTimer);

    const privateChannel = `private-${this.$store.state.auth.userId}`;
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_ordered', this.lessonProductOrderHandler);
    this.$unbindPushEvent(privateChannel, 'lesson_product_order_canceled', this.lessonProductOrderHandler);
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[chatting-container] { .fix; .rt(0, 0); .wh(100%); filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.08));
  > .close-btn { .abs; .rt(11, 17); .z(1);
    > svg {
      path { stroke: #fff; }
    }
  }
  .chatting-home, [chatting-room] { .crop; .h(100%); .br(0); }
  .header { .p(5, 20, 25, 20); .mb(-20); background: linear-gradient(126deg, rgba(223, 58, 58), rgba(255, 144, 144));
    > h2 { .fs(16); .bold; .fs(16); .c(#ebebf0);
      em { .fs(32); .mr(6); .extra-bold; .c(white); vertical-align: sub; }
    }
  }
  .chatting { .p(15, 20, 36, 20); }
  .avatar {
    &.leader { .-a(red);
      &:before { .abs; .cnt; .contain('@{icon}/ico_chat_leader.svg'); .wh(15); .mt(24); .ml(-1); }
    }
    &.non-certi {
      &:before { .abs; .cnt; .contain('@{icon}/ico_chat_non_certi.svg'); .wh(15); .mt(24); .ml(-1);}
    }
    &.wait-agent {
      &:before {.abs; .cnt; .contain('@{icon}/ico_chat_wait_agent.svg'); .wh(15); .mt(24); .ml(-1);}
    }
  }

  [color-button] {
    &.purple-style { .c(white); border: none; background: linear-gradient(286deg, #9eadff, #8300ff); transition: background 0.2s;
      &:active { background: linear-gradient(300deg, #9eadff, #8300ff); }
    }

    &:disabled { cursor: not-allowed; .o(0.2); }
  }

  @media (@tl-up) {
    top: 10%;
    right: 20px; .w(373); .h;
    .chatting-home, [chatting-room] { .h; .br(20); }
  }

  @media (@ds-up) {
    top: 10%;
    right: 40px;
  }
}
</style>
