export default () => ({
  namespaced: true,
  state: {
    loading: false,
    isBackgroundScrollMovable: true
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setBackgroundScrollMovable(state, payload) {
      state.isBackgroundScrollMovable = payload;
    }
  }
});
