<template>
  <div app-footer v-if="!$route.meta.hideFooter">
    <div class="footer-wrapper">
      <div class="child links first">
        <nav>
          <div><a href="https://www.gamecoach.pro" target="_blank">{{ $t('_.gca') }}</a></div>
          <div><a href="/policy/coach-terms.html" target="_blank">{{ $t('_.coach') }}</a></div>
          <div><a :href="serviceUrl" target="_blank">{{ $t('_.service') }}</a></div>
          <div><a :href="privacyUrl" target="_blank"><strong>{{ $t('_.privacy') }}</strong></a></div>
        </nav>
      </div>
      <div class="child links second">
        <nav>
          <div><a href="https://bigpi-dakgg.notion.site/FAQ-64b9fdc61e394aa9a65681742a16ef48" target="_blank">{{ $t('_.note') }}</a></div>
          <div><a @click="openCoachSupport" target="_blank">{{ $t('_.askCoaching') }}</a></div>
        </nav>
        <p v-html="$t('_.askCoachingTime')"></p>
      </div>
      <hr class="divider" />
      <client-only>
        <div class="child third">
          <p v-html="$t('_.info')"></p>
        </div>
      </client-only>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AppFooter',
  lexicon: 'home.footer',
  computed: {
    serviceUrl() {
      return `${process.env.VUE_APP_MEMBERSHIP_URL}/terms/service`;
    },
    privacyUrl() {
      return `${process.env.VUE_APP_MEMBERSHIP_URL}/terms/privacy`;
    },
  },
  methods: {
    openCoachSupport() {
      window.Beacon('open');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

.bar-split() { .ml(5); .pl(10); .rel;
  &:after { .cnt; .abs; .lt(0, 4); .wh(2, 14); .bgc(#444); }
}
.bar-split-none() {.ml(0); .pl(0); .rel;
  &:after { .wh(0, 0); .lt(0, 0); }
}

[app-footer] { .p(70, 0, 60); .bgc(#191919); .tc; .c(#aaa);
  .footer-wrapper {
    .flex; .flex-wrap; .max-w(1260); .ml(auto); .mr(auto);
    .child { flex: 1; .tl; }
    .first { .max-w(250); .min-w(250); }
    .second { .max-w(300); .min-w(300); }
    .third { .min-w(400); }
    .links {
      nav {
        div { .mh(10); .mb(12); }
        a { .underline; }
      }
    }
    p { .c(#777); .mh(10); .lh(21);
      dt, dd, dl { .ib; }
      dt + dt { .bar-split; }
      dt + dl { .bar-split; }
    }
  }

  @media (@tl-down) {
    .divider {.hide}
    .footer-wrapper {
      .pl(30);
    }
    .third { .max-w(400); }
    p {
      dt + dl { .bar-split-none !important; }
    }
  }

  @media (@ml-down) {
    .footer-wrapper {
      .second { .mt(20); }
    }
  }

  @media (@mm-down) {
    .footer-wrapper {
      .pl(10);
    }
  }

  .divider {
    border-top: 1px solid #444; .w(92%); .mt(25); .mb(30);
  }
}
</style>
