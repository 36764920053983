<template>
  <div other-message-format>
    <div class="account">
      <img alt="lvup" src="/img/icon/ico_lvupggTv.png" />
      <div class="info">
        <span class="name">게임코치 온라인</span>
        <span class="date-format">{{ createdAt }}</span>
      </div>
    </div>
    <div class="message">
      <pre>{{ message }}</pre>
      <ul v-if="metaData.couponTitle">
        <li>{{ metaData.couponTitle }}</li>
        <li>{{ metaData.couponDescription }}</li>
        <li>{{ metaData.couponExpiration }}</li>
      </ul>
      <button v-if="metaData.btnUrl || metaData.coachId" class="msg-btn" @click="onClickBtn">{{ metaData.btnName }}</button>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import Specific from '@shared/types/Specific';
import moment from 'moment';
import coach from '@/mixins/coach';

export default {
  name: 'AdminMessageFormat',
  lexicon: 'chatting.room',
  mixins: [coach],
  props: {
    info: Specific,
    leaderUserId: { type: String, default: '' },
  },
  computed: {
    /** @type {SendBird.AdminMessage} */
    msgInfo() {
      return this.info ?? {};
    },
    /** @type {'SYSTEM_MESSAGE' | 'LVUP_MESSAGE'} */
    customType() {
      return this.msgInfo?.customType;
    },
    /** @type {string} */
    createdAt() {
      return this.$fromNow(this.msgInfo?.createdAt);
    },
    /** @type {string} */
    message() {
      return this.msgInfo?.message ?? '';
    },
    btnType() {
      const metaData = JSON.parse(this.msgInfo?.data || '{}');
      const dataType = metaData?.template;
      if (dataType !== 'BUTTON') return '';
      return metaData?.data?.buttonName;
    },
    linkBtnType() {
      const metaData = JSON.parse(this.msgInfo?.data || '{}');
      const dataType = metaData?.template;
      if (dataType !== 'LINK_BUTTON') return '';
      return metaData?.data?.buttonName;
    },
    metaData() {
      const metaData = JSON.parse(this.msgInfo?.data || '{}');
      /** @type {'INVITATION_COUPON_ALERT' | 'LINK_BUTTON'} */
      const dataType = metaData?.template ?? '';
      const data = {
        INVITATION_COUPON_ALERT: {
          couponTitle: this.$t('_.couponTitle', { title: _get(metaData, 'data.title') }),
          couponDescription: this.$t('_.couponDescription', { description: _get(metaData, 'data.description') }),
          couponExpiration: this.$t('_.couponExpireDate', { date: this.$date(_get(metaData, 'data.expirationDatetime')), dateDiff: moment(_get(metaData, 'data.expirationDatetime', 0)).diff((this.msgInfo?.createdAt ?? 0), 'days') }),
        },
        LINK_BUTTON: {
          btnName: this.$t(`_.${_get(metaData, 'data.buttonName')}`),
          btnUrl: _get(metaData, 'data.url'),
        },
        BUTTON: {
          coachId: _get(metaData, 'data.buttonInfo.coachId'),
          btnName: this.$t(`_.${_get(metaData, 'data.buttonName')}`),
        },
      };
      return data[dataType] ?? {};
    },
  },
  methods: {
    onClickBtn() {
      let url = this.metaData?.btnUrl;

      if (this.btnType === 'COACH_DETAIL_VIEW') {
        const coachId = this.metaData?.coachId;
        this.openTutor(coachId);
        return;
      }

      /** 코치의 답변 확인 알림 버튼인 경우. */
      if (this.linkBtnType === 'CHECK_ANSWER') {
        const isLegacyUrl = String(url).includes('lvup.gg/coach/');

        /** 리뉴얼 전 Url인 경우. */
        if (isLegacyUrl) {
          const queryUrl = url.slice(url.indexOf('?'), url.length);
          const urlParams = new URLSearchParams(queryUrl);
          const params = Object.fromEntries(urlParams.entries());
          const boardWriteId = params?.['question-id'];
          const boardCommentId = params?.['comment-id'];
          url = process.env.VUE_APP_HOME_URL + this.$router.resolve({ name: 'CoachQuestion', params: { boardWriteId, gameId: 'lol' }, query: { boardCommentId } }).href;
        } else {
          url = process.env.VUE_APP_HOME_URL + url;
        }
      }
      window.open(url, '_blank');
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[other-message-format] { .crop; .mb(15);
  .account {
    img { .wh(32); .bgc(#ddd); .br(50%); .ib;}
    .info { .ib; .ml(5);
      .name { .block; .bold; .fs(14);}
      .date-format { .fs(11); .c(#6b6b6b);}
    }
  }
  .message { .ib; .fl; .h(100%); .max-w(290); .min-w(30); .ml(37); .mt(10); .p(8); .mb(10); .fs(12); .bgc(#df3c3c); .c(white); .br(8); border-top-left-radius: 0;
    > pre {white-space: pre-wrap; line-break: anywhere;}

    > ul {
      > li { .mt(18); }
    }
    .msg-btn { .wh(100%, 40); .min-w(200); .mt(12); .mb(8); .bgc(white); .fs(14); .bold; .c(black); .br(12); .block; .mh-c;}
  }
}
</style>
