<template>
  <Modal coach-confirm-modal @close="$emit('close')">
    <template v-slot:title>
      <h2 class="title-text">{{ title }}</h2>
    </template>
    <p class="content-text">{{ content }}</p>
    <div class="control">
      <ColorButton class="button" @click="buttonHandler">
        <span class="button-text">{{ buttonText }}</span>
      </ColorButton>
    </div>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  name: 'CoachConfirmModal',
  components: { ColorButton, Modal },
  props: {
    options: Object,
  },
  computed: {
    _options() {
      return /** @type { {title: string, content: string, buttonText: string}} */ this.options;
    },
    title() {
      return this.options?.title || '';
    },
    content() {
      return this.options?.content || '';
    },
    buttonText() {
      return this._options?.buttonText || this.$t('coaching.BA_REQUEST_CANCEL_CONFIRM');
    },
  },
  methods: {
    buttonHandler() {
      this.$emit('resolve', true);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-confirm-modal] { .flex; .c(#191919); text-align: center; .pb(20);
  .title-text {.fs(20); .bold; }
  .content-text {.fs(16); font-weight: 500; .mt(20); }
  .control {.mt(32); .pb(40);
    .button { .w(100%);}
  }
}
</style>
