<template>
  <div :class="{'my-msg': isMyMsg}" normal-message-format>
    <div :class="{account: true, leader: isLeader}">
      <div class="info">
        <span class="name">{{ nickname }}</span>
        <span class="time-format">{{ date() }}</span>
      </div>
      <Avatar :defaultImgType="'fruit'" :info="senderInfo" userProfile />
    </div>
    <div class="message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import Avatar from '@shared/components/common/Avatar.vue';
import { getter } from '@shared/utils/storeUtils';

export default {
  name: 'NormalMessageFormat',
  components: { Avatar },
  props: {
    info: Specific,
    leaderUserId: { type: String, default: '' },
  },
  computed: {
    /** @type {string} */
    mySbUserId: getter('chat', 'sbUserId'),
    /** @type {SendBird.UserMessage | {}} */
    msgInfo() {
      return this.info ?? {};
    },
    /** @type {SendBird.Sender} */
    senderInfo() {
      return this.msgInfo?.sender;
    },
    /** @type {string} */
    nickname() {
      return this.senderInfo?.nickname ?? '';
    },
    /** @type {boolean} */
    isLeader() {
      return this.senderInfo?.metaData?.lvupUserId === this.leaderUserId;
    },
    /** @type {string} */
    message() {
      return this.msgInfo?.message ?? '';
    },
    /** @type {boolean} */
    isMyMsg() {
      return this.senderInfo?.userId === this.mySbUserId;
    },
  },
  methods: {
    /** @type {string} */
    date() {
      return this.$fromNow(this.msgInfo?.createdAt);
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[normal-message-format] { .crop; .mb(15);
  .account { .flex; .flex-ai; flex-direction: row-reverse; .flex-jc(flex-end);
    [avatar] { .wh(32); .min-w(32); .bgc(#ddd); .br(50%); .ib; .mr(5); }
    .info { .ib; .mr(5);
      .name { .block; .bold; .fs(14); word-break: normal;}
      .time-format { .fs(11); .c(#6b6b6b); .bgc; }
    }
    &.leader { .rel;
      [avatar] { .-a(#df3c3c); }
      &:after { .abs; .cnt; .lb(18, 0); .wh(16); .contain('@{icon}/ico_chat_leader.svg'); }
    }
  }
  .message { .ib; .fl; .h(100%); .max-w(290); .min-w(30); .ml(36); .mt(10); .p(8); .mb(10); .fs(12); .br(8); .bgc(#ebebf0); .c(black); border-top-left-radius: 0;}
  &.my-msg {
    .account { flex-direction: row;
      [avatar] { .mr(0); }
      .info { .tr; }
      &.leader {
        &:after { .rb(18, 0); }
      }
    }
    .message { .fr; .br(8); border-top-right-radius: 0; .mr(36); .ml(0); .bgc(black); .c(white); }
  }
}
</style>
