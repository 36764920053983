<template>
  <Modal coach-reverification-modal @close="closeHandler">
    <template v-slot:title>
      <div class="title-img">
        <svg-thunder-yellow />
      </div>
      <h2 class="title-text">
        {{ $t('coaching.signOut.already.title') }}
      </h2>
    </template>
    <template>
      <p class="content-text">{{ $t('coaching.signOut.already.subtitle') }}</p>
      <div class="btn-wrapper">
        <ColorButton class="btn-cancel" @click="closeHandler" :type="'dark-line'">
          {{ $t('coaching.signOut.already.button.cancel') }}
        </ColorButton>
        <ColorButton class="btn-confirm" @click="linkHandler" :type="'dark'" color-only>
          {{ $t('coaching.signOut.already.button.ok') }}
        </ColorButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgThunderYellow from '@shared/graphics/svg-thunder-yellow.vue';

export default {
  name: 'coach-reverification-modal',
  components: { ColorButton, Modal, SvgThunderYellow },
  methods: {
    async linkHandler() {
      await this.$services.auth.oAuthLogin(false);
      this.$emit('resolve', true);
    },
    closeHandler() {
      this.$emit('close');
      window.location.reload();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-reverification-modal] { .flex; .c(#191919); text-align: center; .pb(20);
  .title-img {.pt(15); .pb(18);}
  .title-text {.fs(20); .bold; }
  .content-text {.fs(14, 21); font-weight: 500; .mt(20); }
  .btn-wrapper { .flex-list; .space-around; .pt(40); .pb(66);
    [color-button] { .h(48); .br(12); .fs(16); .max-w(170); }
    .btn-cancel { border: 1px solid #d5d5de; }
  }

  @media (@tl-down) {
    .btn-wrapper {
      [color-button] { .max-w(150); }
    }
  }
}
</style>
